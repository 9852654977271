import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import Icon from 'components/icon/Icon'

const SelfCheckFooter = ({
  completed,
  domain = 'succeed',
  blockTitle,
  questionAnswered = false
}) => {
  const { sendEvent } = useAnalytics()

  const renderButton = () => {
    if (questionAnswered)
      return (
        <Button
          endIcon={<Icon>arrow_forward</Icon>}
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: blockTitle,
              click_element: 'Link',
              element_title: 'Take the self check',
              path: window.location.pathname
            })
          }
          sx={{
            fontWeight: 700
          }}
          to={`/self-checks/${domain}`}
          variant="tonal"
        >
          <FormattedMessage defaultMessage="Take the self check" id="eMbQrV" />
        </Button>
      )
    if (completed)
      return (
        <Button
          endIcon={<Icon>arrow_forward</Icon>}
          LinkComponent={Link}
          sx={{
            fontWeight: 700
          }}
          to={`/self-checks/results/${domain}`}
          variant="text"
        >
          <FormattedMessage
            defaultMessage="See Your Full Results"
            id="0/tx8W"
          />
        </Button>
      )
    return null
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        mt: 3
      }}
    >
      {renderButton()}
    </Stack>
  )
}

SelfCheckFooter.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  domain: PropTypes.string,
  questionAnswered: PropTypes.bool
}

export default SelfCheckFooter
