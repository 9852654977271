import { equals } from 'ramda'

export const getSelfCheckQuestionFromInterests = (
  userInterests = [],
  rcqData = []
) => {
  const questions = []
  const interestQuestions = []
  rcqData.forEach(domain => {
    const userInterestIds = userInterests.map(q => q.id)
    domain.questions
      .filter(q => {
        return (
          userInterestIds.includes(q.groupId) &&
          q.hide_from_onboarding === false
        )
      })
      .forEach(q => interestQuestions.push(q))
  })

  if (interestQuestions.length) {
    interestQuestions.forEach(q => {
      questions.push({
        answers: q.answers,
        id: q.id,
        question: q.prompt,
        subdomain: q.groupId
      })
    })
  } else {
    rcqData.forEach(domain => {
      domain.questions.forEach(q => {
        if (!q.hide_from_onboarding) {
          questions.push({
            answers: q.answers,
            id: q.id,
            question: q.prompt,
            subdomain: q.groupId
          })
        }
      })
    })
  }

  const questionIndex = Math.floor(Math.random() * questions.length)
  let q
  if (equals(questionIndex, -1)) {
    const firstQuestion = rcqData[0].questions[0]
    q = {
      answers: firstQuestion.answers,
      id: firstQuestion.id,
      question: firstQuestion.prompt,
      subdomain: firstQuestion.groupId
    }
  } else {
    q = questions[questionIndex]
  }
  return q
}
