import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { keyframes } from '@emotion/react'
import { dec, inc, length, lt, prop } from 'ramda'
import { Typography } from '@mui/material'
import Highlight from './Highlight'

const animationInitialPause = 2000
const animationLength = 2000
const words = ['well-being', 'grades', 'friends', 'health']

const Heading = () => {
  const [currentWord, setCurrentWord] = useState(0)

  const rotateWord = () => {
    setTimeout(() => {
      const nextCurrentWord = currentWord =>
        lt(currentWord, dec(length(words))) ? inc(currentWord) : 0
      setCurrentWord(nextCurrentWord)
    }, 500)
  }

  //Sequential word rotation animations
  useEffect(() => {
    setTimeout(() => rotateWord(), animationInitialPause)
    setTimeout(() => rotateWord(), animationInitialPause + animationLength)
    setTimeout(() => rotateWord(), animationInitialPause + animationLength * 2)
  }, [])

  const fadeOutInAnimation = keyframes({
    '0%': {
      opacity: 1,
      width: '100%'
    },
    '25%': {
      opacity: 0,
      width: '0%'
    },
    '75%': {
      opacity: 0,
      width: '0%'
    },
    // eslint-disable-next-line sort-keys
    '100%': {
      opacity: 1,
      width: '100%'
    }
  })

  const getHeadlineCopy = () => (
    <FormattedMessage
      defaultMessage="Take care of{br}your <h>{word}</h>."
      id="t8GHpx"
      values={{
        br: <br />,
        h: chunks => <Highlight variant="h1">{chunks}</Highlight>,
        word: prop(currentWord, words)
      }}
    />
  )

  return (
    <Typography
      className="rotate-word-animation"
      sx={{
        span: {
          ':after': {
            animation: `${fadeOutInAnimation} 1.5s ease`,
            animationIterationCount: 4
          },
          animation: `${fadeOutInAnimation} 1.5s ease`,
          animationIterationCount: 4
        }
      }}
      variant="h1"
    >
      {getHeadlineCopy()}
    </Typography>
  )
}

export default Heading
