import { useState } from 'react'
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { equals, flip, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const LocationSelect = ({ location = null, setLocation }) => {
  const { locations } = useSelector(prop('school'))

  const [inputValue, setInputValue] = useState('')

  const mappedLocations = locations.map(({ id, name }) => ({
    id,
    label: name
  }))

  return (
    <Stack spacing={1}>
      <Autocomplete
        clearIcon={<Icon fill>clear</Icon>}
        getOptionLabel={prop('label')}
        inputValue={inputValue}
        isOptionEqualToValue={(opt, val) =>
          equals(prop('id', opt), prop('id', val))
        }
        onChange={(__, val) => setLocation(val)}
        onInputChange={flip(setInputValue)}
        options={mappedLocations}
        popupIcon={<Icon fill>keyboard_arrow_down</Icon>}
        renderInput={params => (
          <TextField {...params} placeholder="Where are you located?" />
        )}
        value={location}
      />
      <Tooltip
        title={
          <Typography variant="caption">
            <FormattedMessage
              defaultMessage="YOU is a confidential platform. Your location is used only to personalize the resources and tools we share based on your geographic region. We will never sell or distribute your data to anyone."
              id="b63B63"
            />
          </Typography>
        }
      >
        <Button
          startIcon={<Icon>info</Icon>}
          sx={{ p: 0, textTransform: 'initial' }}
          variant="text"
        >
          <FormattedMessage
            defaultMessage="Why do you need my location?"
            id="v8A5sW"
          />
        </Button>
      </Tooltip>
    </Stack>
  )
}

LocationSelect.propTypes = {
  location: PropTypes.shape({}),
  setLocation: PropTypes.func.isRequired
}

export default LocationSelect
