export const MuiPickersDay = palette => ({
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: `${palette.button.primary.textDisabled} !important`
      },
      '&.Mui-selected': {
        borderColor: palette.surface.onSurface
      },
      color: palette.surface.onSurface
    }
  }
})
