/* eslint-disable sort-keys */
/* eslint-disable quotes */
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  all,
  and,
  any,
  append,
  compose,
  equals,
  gt,
  isEmpty,
  isNil,
  length,
  lte,
  not,
  or,
  path,
  prop,
  uniq
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useAnalytics, useWindowResize } from 'hooks'
import {
  setPreviousSearches,
  setSearchTerm,
  setTempSearchTerm
} from 'pages/search/searchSlice'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Icon from 'components/icon/Icon'

const SearchInput = () => {
  const dispatch = useDispatch()
  const { sendEvent } = useAnalytics()
  const { isKeyboardShown, x } = useWindowResize()
  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  const previousSearches = useSelector(path(['search', 'previousSearches']))
  const tempSearchTerm = useSelector(path(['search', 'tempSearchTerm']))
  const isMobile = lte(x, 1023)

  const inputRef = useRef(null)
  const rootEl = document.getElementById('root')

  const [isFocused, setIsFocused] = useState(false)

  const searchHistory = useMemo(() => {
    return uniq(previousSearches)
  }, [previousSearches])
  const hasSearchHistory = gt(length(searchHistory), 0)
  const hasSearchTerm = not(isEmpty(tempSearchTerm))
  const hasStyleChanges = and(isFocused, or(hasSearchTerm, hasSearchHistory))

  const html = document.getElementsByTagName('html')[0]

  const handleUpdateSearch = useCallback(() => {
    dispatch(setSearchTerm(tempSearchTerm))
    dispatch(setPreviousSearches(append(tempSearchTerm, previousSearches)))
    sendEvent('search_query', {
      searchterm: tempSearchTerm
    })
    setIsFocused(false)
    if (isMobile) {
      document.body.classList.remove('no-scroll')
      html.classList.remove('no-scroll')
      rootEl.classList.remove('no-scroll')
    }
    if (pathname.includes('explore'))
      navigate('/search', { state: tempSearchTerm })
  }, [
    dispatch,
    html.classList,
    isMobile,
    navigate,
    pathname,
    previousSearches,
    rootEl.classList,
    sendEvent,
    tempSearchTerm
  ])

  // to update state based on explore search
  useEffect(() => {
    if (not(isNil(state))) {
      dispatch(setTempSearchTerm(state))
      dispatch(setSearchTerm(state))
    }
  }, [state, dispatch])

  useEffect(() => {
    if (not(isKeyboardShown)) {
      document.body.classList.remove('no-scroll')
      html.classList.remove('no-scroll')
      rootEl.classList.remove('no-scroll')
      setIsFocused(false)
    }
  }, [html.classList, isKeyboardShown, rootEl.classList])

  const handleChange = val => dispatch(setTempSearchTerm(val))

  const handleEnter = e => {
    const key = prop('key', e)
    if (not(equals(key, 'Enter'))) return
    handleUpdateSearch()
    e.target.blur()
  }

  const handleClear = () => {
    dispatch(setTempSearchTerm(''))
    if (isMobile) {
      document.body.classList.remove('no-scroll')
      html.classList.remove('no-scroll')
      rootEl.classList.remove('no-scroll')
    }
    setIsFocused(false)
  }

  const handleOpen = () => {
    if (isMobile) {
      // Add no scroll on mobile only
      document.body.classList.add('no-scroll')
      html.classList.add('no-scroll')
      rootEl.classList.add('no-scroll')
    }
    setIsFocused(true)
  }

  const useOutsideClick = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsFocused(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideClick(inputRef)

  return (
    <Stack
      ref={inputRef}
      sx={{
        alignItems: 'center',
        maxWidth: 544,
        position: 'relative',
        px: { mobile: isFocused ? 0 : 4, tablet: 0 },
        transform: {
          mobile: isFocused ? 'translateY(-143px)' : 'none',
          tablet: 'none'
        },
        transition: 'all .33s ease-in-out',
        width: 1,
        zIndex: isFocused ? 1200 : 1
      }}
    >
      <Stack
        sx={{
          width: 1
        }}
      >
        <Box sx={{ position: 'relative', width: 1 }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              )
            }}
            label="Search bar"
            onChange={compose(handleChange, path(['target', 'value']))}
            onFocus={handleOpen}
            onKeyDown={handleEnter}
            placeholder="What's on your mind?"
            sx={{
              '& > .MuiInputLabel-root': {
                textIndent: '-9999px'
              },
              '& > .MuiOutlinedInput-root': {
                '& > .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'none !important'
                },
                borderBottomLeftRadius: {
                  mobile: isFocused ? 0 : 16,
                  tablet: hasStyleChanges ? 0 : 16
                },
                borderBottomRightRadius: {
                  mobile: isFocused ? 0 : 16,
                  tablet: hasStyleChanges ? 0 : 16
                },
                borderTopLeftRadius: {
                  mobile: isFocused ? 0 : 16,
                  tablet: 16
                },
                borderTopRightRadius: {
                  mobile: isFocused ? 0 : 16,
                  tablet: 16
                },
                transition: 'all .1s ease-in-out'
              }
            }}
            value={tempSearchTerm}
          />
          <List
            component={Stack}
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'surface.container',
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              borderTop: {
                mobile: isFocused ? '1px solid' : 'none',
                tablet: hasStyleChanges ? '1px solid' : 'none'
              },
              borderTopColor: 'surface.highest',
              display: isFocused ? 'flex' : 'none',
              height: {
                mobile: isKeyboardShown
                  ? 'calc(100vh - 165px)'
                  : 'calc(100vh - 177px)',
                tablet: 'auto'
              },
              justifyContent: 'flex-start',
              maxHeight: { mobile: 'unset', tablet: 240 },
              overflowY: 'auto',
              p: 0,
              pb: hasSearchHistory ? 3 : 0,
              position: 'absolute',
              transition: 'all .1s ease-in-out',
              width: 1,
              zIndex: 2
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                bgcolor: 'surface.high',
                display: hasSearchTerm ? 'flex' : 'none',
                justifyContent: 'space-between',
                maxHeight: 'unset',
                p: 2,
                pb: '17px',
                width: 1
              }}
            >
              <Typography>Search for &quot;{tempSearchTerm}&quot;</Typography>
              <Typography variant="subtitle2">
                Click or Hit &apos;Enter&apos;
              </Typography>
            </Stack>
            {searchHistory.map(term => (
              <Stack
                aria-label={`previous search, ${term}`}
                component={ListItemButton}
                direction="row"
                key={term}
                onClick={() => {
                  dispatch(setTempSearchTerm(term))
                  dispatch(setSearchTerm(term))
                  sendEvent('search_query', {
                    searchterm: term
                  })
                }}
                spacing={2}
                sx={{
                  display: hasSearchHistory ? 'flex' : 'none',
                  height: hasSearchHistory ? 'auto' : 0,
                  justifyContent: 'flex-start',
                  maxHeight: 72,
                  px: 2,
                  py: 3,
                  transition: 'all .1s ease-in-out',
                  width: 1
                }}
                to={pathname.includes('explore') ? '/search' : ''}
                variant=""
              >
                <Icon>history</Icon>
                <Typography>{term}</Typography>
              </Stack>
            ))}
          </List>
        </Box>
        {and(isFocused, gt(length(tempSearchTerm), 0)) && (
          <IconButton
            aria-label="search"
            disabled={isEmpty(tempSearchTerm)}
            onClick={handleUpdateSearch}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              transition: 'all .1s ease-in-out'
            }}
            variant="text"
          >
            <Icon>arrow_forward</Icon>
          </IconButton>
        )}
        {any(equals(true))([
          and(isFocused, isEmpty(tempSearchTerm)),
          and(not(isFocused), not(isEmpty(tempSearchTerm))),
          all(equals(true))([isFocused, isMobile, isEmpty(tempSearchTerm)])
        ]) && (
          <IconButton
            aria-label="clear"
            onClick={handleClear}
            sx={{
              position: 'absolute',
              right: { mobile: isFocused ? 8 : 36, tablet: 8 },
              top: 8,
              transition: 'all .2s ease-in-out'
            }}
            variant="text"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default SearchInput
