/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  prop,
  assoc,
  path,
  gt,
  compose,
  append,
  length,
  inc,
  not,
  equals
} from 'ramda'
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import Icon from 'components/icon/Icon'

const StepsForm = ({ goal, setGoal }) => {
  const steps = prop('steps', goal)
  const intl = useIntl()

  return (
    <Stack spacing={2} sx={{ maxWidth: 985, width: 1 }}>
      {steps.map((step, index) => (
        <Stack key={index} spacing={1}>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Step {step}"
              id="EJ8mg/"
              values={{ step: inc(index) }}
            />
          </Typography>
          <TextField
            InputProps={{
              endAdornment: gt(length(steps), 1) && (
                <IconButton
                  onClick={() =>
                    setGoal(
                      assoc(
                        'steps',
                        steps.filter((_, idx) => not(equals(idx, index))),
                        goal
                      )
                    )
                  }
                >
                  <Icon fill>delete</Icon>
                </IconButton>
              )
            }}
            multiline
            onChange={e => {
              const updated = assoc('name', path(['target', 'value'], e), step)
              steps[index] = updated
              setGoal(assoc('steps', [...steps], goal))
            }}
            placeholder={intl.formatMessage({
              defaultMessage: 'Add your step',
              id: 'G+Nl9Q'
            })}
            sx={{
              '& > div > textarea': {
                borderRadius: 0
              }
            }}
            value={prop('name', step)}
          />
        </Stack>
      ))}
      <Button
        onClick={() =>
          // grab steps from goal obj
          // append new empty step to arr
          // update steps with new concatenated arr
          compose(
            setGoal,
            assoc('steps'),
            append({
              completed: false,
              id: null,
              name: ''
            }),
            prop('steps')
          )(goal)
        }
        variant="outlined"
      >
        <FormattedMessage defaultMessage="Add your own step" id="Sphc64" />
      </Button>
    </Stack>
  )
}
StepsForm.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired
}

export default StepsForm
