import { Box, Button, Stack, Typography } from '@mui/material'
import {
  useGetCardSentimentQuery,
  useSetCardSentimentMutation
} from 'api/cardsApi'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { and, any, equals, isNil, not, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const emojiButtonStyle = {
  fontSize: 16.667,
  letterSpacing: '0.25px',
  lineHeight: '23.33px',
  py: 1
}

const UserFeedback = ({
  campus_resource = false,
  domain_id,
  id,
  title,
  type
}) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const { id: userId } = useSelector(prop('user'))

  const [hasInteracted, setHasInteracted] = useState(false)

  const { data } = useGetCardSentimentQuery(id, {
    refetchOnMountOrArgChange: true
  })

  const [setSentiment] = useSetCardSentimentMutation()

  useEffect(() => {
    if (not(isNil(prop('sentiment', data)))) {
      setHasInteracted(true)
    }

    return () => {
      // Reset form since going from one article to another unmounts but doesn't
      // reset the state of interaction
      setHasInteracted(false)
    }
  }, [data])

  const handleClick = rating => {
    setHasInteracted(true)
    sendEvent('User_feedback_article', {
      campus_resource,
      card_domain: domain_id,
      card_name: title,
      card_type: type,
      feedback_rating: rating,
      path: pathname
    })
    setSentiment({ card_id: id, sentiment: rating, user_id: userId })
  }

  // Before this massive logic BS was here this component was only 149 lines of code
  const renderQuestion = () => {
    if (
      any(equals(true))([
        equals(type, 4),
        and(not(campus_resource), equals(type, 2))
      ])
    ) {
      return (
        <FormattedMessage
          defaultMessage="Did this increase your knowledge on the given topic?"
          id="h5wtbc"
        />
      )
    }
    if (equals(type, 13)) {
      return (
        <FormattedMessage
          defaultMessage="Did you learn something new you plan to use?"
          id="z7CBIg"
        />
      )
    }
    if (and(campus_resource, equals(type, 2))) {
      return (
        <FormattedMessage
          defaultMessage="Do you plan to use this resource?"
          id="YCvz05"
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage="Did this post increase your knowledge on the given topic?"
        id="xW09Tq"
      />
    )
  }

  return (
    <Stack sx={{ maxWidth: 500, my: 12 }}>
      {hasInteracted ? (
        <Stack alignItems="center" spacing={1}>
          <Typography variant="body1">
            <FormattedMessage
              defaultMessage="Thank you for sharing your feedback!"
              id="aHuBDB"
            />
          </Typography>
          <Box textAlign="center">
            <Typography display="inline" variant="body1">
              <FormattedMessage
                defaultMessage="Our team strives to always improve. If you have more feedback, you can share it with"
                id="T4TB08"
              />
            </Typography>
            <Typography
              component={Link}
              display="inline"
              sx={{
                ml: 0.5,
                textDecoration: 'underline'
              }}
              to="/feedback"
              variant="body1"
            >
              <FormattedMessage defaultMessage="this form." id="2t0jus" />
            </Typography>
          </Box>
        </Stack>
      ) : (
        <Stack alignItems="center" spacing={3}>
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h4">
              <FormattedMessage defaultMessage="Let us know..." id="oqNR6o" />
            </Typography>
            <Typography textAlign="center" variant="body1">
              {renderQuestion()}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Button
              aria-label={intl.formatMessage({
                defaultMessage: 'negative',
                id: 'h8bqAX'
              })}
              onClick={() => handleClick('negative')}
              sx={{ ...emojiButtonStyle }}
              variant="text"
            >
              <Icon fill>thumb_down</Icon>
            </Button>
            <Button
              aria-label={intl.formatMessage({
                defaultMessage: 'positive',
                id: 'w3uBGr'
              })}
              onClick={() => handleClick('positive')}
              sx={{ ...emojiButtonStyle }}
              variant="text"
            >
              <Icon fill>thumb_up</Icon>
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

UserFeedback.propTypes = {
  campus_resource: PropTypes.bool,
  domain_id: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired
}

export default UserFeedback
