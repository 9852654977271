import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { append, equals, findIndex, gt, prop, without } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { setActsOfKindness } from './destressSlice'
import { useDispatch, useSelector } from 'react-redux'

const cards = [
  'Send a quick appreciation text to someone',
  'Send money for someone’s next coffee',
  'Set aside items to donate to charity',
  'Share a song that reminds you of a friend or loved one',
  'Write a review for your favorite local business',
  'Post an encouraging message on social media'
]

const ActsOfKindness = () => {
  const dispatch = useDispatch()
  const { actsOfKindness } = useSelector(prop('destress'))

  const handleClick = val => {
    const found = findIndex(equals(val), actsOfKindness)
    if (gt(found, -1)) {
      return dispatch(setActsOfKindness(without([val], actsOfKindness)))
    }
    dispatch(setActsOfKindness(append(val, actsOfKindness)))
  }

  const isActive = str => actsOfKindness.some(equals(str))

  return (
    // eslint-disable-next-line sort-keys
    <Container maxWidth="monitor">
      <Stack alignItems="center" spacing={3}>
        <Slide direction="left" in>
          <Stack spacing={3} sx={{ maxWidth: 808, textAlign: 'center' }}>
            <Typography component="h1" variant="h2">
              <FormattedMessage
                defaultMessage="Random acts of kindness have the power to decrease stress and increase our feelings of connection."
                id="kenBsN"
              />
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage="Give it a whirl by selecting the acts of kindness you would like to try. Practicing kindness has as much of an impact as 15 minutes of meditation."
                id="wZ40cm"
              />
            </Typography>
          </Stack>
        </Slide>
        <Slide direction="left" in timeout={500}>
          <Box
            sx={{
              display: 'grid',
              gap: 3,
              // eslint-disable-next-line sort-keys
              gridTemplateColumns: { mobile: '1fr', laptop: 'repeat(3, 1fr)' },
              maxWidth: 1068,
              width: 1
            }}
          >
            {cards.map(str => (
              <Card
                aria-selected={isActive(str)}
                key={str}
                selected={isActive(str)}
                variant="interactive"
              >
                <CardActionArea
                  aria-label={`Act of Kindness: ${str}, ${
                    isActive(str) ? 'selected' : 'unselected'
                  }`}
                  onClick={() => handleClick(str)}
                  sx={{
                    height: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      px: 2,
                      py: 4
                    }}
                  >
                    <Typography variant="h5">{str}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        </Slide>
      </Stack>
    </Container>
  )
}

export default ActsOfKindness
