/* eslint-disable sort-keys */
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Stack, Typography } from '@mui/material'
import { prop } from 'ramda'
import { useAnalytics } from 'hooks'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Heading from './Heading'
import AccessibleModal from 'components/modals/AccessibleModal'
import LogInButton from 'components/buttons/LogInButton'
import Icon from 'components/icon/Icon'

const Hero = () => {
  const { backgroundImage, letters, name } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const [open, setOpen] = useState(false)

  const getParagraph = () =>
    `Find tips and tools for everything from your mental and physical health, to friendships and finding balance. It’s all here at YOU at ${letters}.`

  const baseCopy = () => (
    <Stack spacing={1}>
      <Typography>
        <FormattedMessage defaultMessage="Confidential" id="o0hn4A" />
      </Typography>
      <FormattedMessage
        defaultMessage="Anything you share, search, or read is just for you. And we’ll never sell your data."
        id="/h7Gt6"
      />
      <Typography>
        <FormattedMessage defaultMessage="Free" id="tf1lIh" />
      </Typography>
      <FormattedMessage
        defaultMessage="No hidden costs, ads, or in-app purchases—and there never will be."
        id="NoTuP0"
      />
    </Stack>
  )

  const onClose = () => {
    setOpen(false)
  }

  const reallyHandler = () => {
    setOpen(true)
  }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'hero'
    })
  }

  // Footer below fold??
  return (
    <>
      <AccessibleModal
        onClose={onClose}
        open={open}
        title={
          <FormattedMessage
            defaultMessage="We care about your privacy as much as you do"
            id="OjzsQL"
          />
        }
      >
        {baseCopy()}
      </AccessibleModal>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          m: '0 auto',
          minHeight: {
            mobile: 'calc(100vh - 300px)',
            tablet: 'calc(100vh - 122px)'
          },
          overflow: 'hidden',
          position: 'relative',
          pb: { mobile: 22, laptop: 0 },
          px: { mobile: 0, laptop: 4 },
          pt: { mobile: 0, laptop: 5 },
          transition: 'all 0.3s, transform 0.3s',
          width: 1
        }}
      >
        <Stack
          direction={{ mobile: 'column-reverse', laptop: 'row' }}
          spacing={6}
          sx={{
            alignItems: 'center',
            maxWidth: 1260,
            width: 1
          }}
        >
          <Stack
            spacing={4}
            sx={{
              maxWidth: { mobile: 425, tablet: 530 },
              position: 'relative',
              px: { mobile: 2, tablet: 0 },
              transition: 'all 0.3s, transform 0.3s',
              width: 1,
              zIndex: 1
            }}
          >
            <Stack
              spacing={6}
              sx={{
                alignItems: { mobile: 'center', laptop: 'flex-start' }
              }}
            >
              <Stack
                spacing={3}
                sx={{
                  alignItems: { mobile: 'center', laptop: 'flex-start' }
                }}
              >
                <Heading />
                <Typography
                  component="p"
                  sx={{
                    maxWidth: { mobile: 'unset', tablet: 530, laptop: 380 },
                    textAlign: { mobile: 'center', laptop: 'unset' },
                    transition: 'all 0.3s, transform 0.3s'
                  }}
                  variant="body1"
                >
                  {getParagraph(name)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <LogInButton>
                  <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
                </LogInButton>
                <Button
                  id="get-started"
                  LinkComponent={Link}
                  onClick={() => {
                    sendKeenIOEvent('create a new account', 'Link')
                  }}
                  to="/sign-up"
                >
                  <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: { mobile: 'center', laptop: 'unset' },
                mt: 2.5,
                transition: 'all 0.3s, transform 0.3s'
              }}
            >
              <Icon fill>lock</Icon>
              <Stack
                direction="row"
                spacing={0.5}
                sx={{
                  alignItems: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  <FormattedMessage
                    defaultMessage="100% free & confidential."
                    id="3J5QkV"
                  />
                </Typography>
                <Typography
                  component={Link}
                  onClick={() => {
                    reallyHandler()
                    sendKeenIOEvent('Really?', 'Link')
                  }}
                  sx={{
                    color: 'primary.tonal',
                    px: 0,
                    textDecoration: 'underline'
                  }}
                  variant="text"
                >
                  <FormattedMessage defaultMessage="Really." id="4JhcHA" />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box
            sx={{
              backgroundColor: '#0F555F',
              borderRadius: { mobile: 0, laptop: 6 },
              height: { mobile: 350, tablet: 500, laptop: 604 },
              p: { mobile: '0 0 12px', laptop: 4 },
              maxWidth: { maxWidth: 'unset', laptop: 680 },
              transition: 'all 0.3s, transform 0.3s',
              width: 1
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: { mobile: 0, laptop: 6 },
                height: 1,
                transition: 'all 0.3s, transform 0.3s',
                width: 1
              }}
            />
          </Box>
        </Stack>
        <Box
          sx={{
            backgroundImage: 'url(/vectors/veggies.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            bottom: { mobile: -8, tablet: -24 },
            height: 205,
            left: { mobile: 0, tablet: -35 },
            position: 'absolute',
            width: 296
          }}
        />
        <Box
          sx={{
            backgroundImage: 'url(/vectors/books.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            bottom: 0,
            display: { mobile: 'none', tablet: 'block' },
            height: 262,
            position: 'absolute',
            right: 0,
            width: 236,
            zIndex: 2
          }}
        />
      </Stack>
    </>
  )
}

export default Hero
