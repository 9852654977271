/* eslint-disable sort-keys */
import { useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  and,
  not,
  equals,
  prop,
  isNil,
  map,
  reject,
  path,
  compose,
  nthArg,
  isEmpty,
  find
} from 'ramda'
import { Box, Divider, Pagination, Stack, Typography } from '@mui/material'
import { useGetCardsQuery } from 'api/cardsApi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useBuildFilters from 'components/filter/useBuildFilters'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import CalloutBox from 'components/callout-box/CalloutBox'
import FilterMenu from 'components/filter/FilterMenu'
import { useGetUserDomainsStudentContentQuery } from 'api/userApi'

const StudentContent = () => {
  const navigate = useNavigate()
  const { id, is_secondary_user } = useSelector(prop('user'))
  const { domain } = useSelector(prop('school'))
  const { contentType, topic } = useSelector(prop('filter'))
  const { contentBase } = useBuildFilters()
  const scrollRef = useRef(null)

  const [page, setPage] = useState(1)

  const contentCheck = useMemo(() => {
    const arr = contentBase.filter(({ name }) =>
      contentType.some(type => equals(type, name))
    )
    const paramArr = map(prop('value'), arr)
    return paramArr
  }, [contentBase, contentType])

  const { data: studentDomains = [], isFetching = true } =
    useGetUserDomainsStudentContentQuery()

  const domainId = useMemo(() => {
    if (isFetching) return []
    const succeed = find(({ title }) => equals('Succeed', title))(
      studentDomains
    )
    const thrive = find(({ title }) => equals('Thrive', title))(studentDomains)
    const matter = find(({ title }) => equals('Matter', title))(studentDomains)

    const newArr = topic.map(opt => {
      if (equals('Academics & Success', opt)) return prop('id', succeed)
      if (equals('Mental & Physical Health', opt)) return prop('id', thrive)
      if (equals('Purpose & Connections', opt)) return prop('id', matter)
      return 0
    })
    return newArr
  }, [isFetching, studentDomains, topic])

  const cardParams = useMemo(
    () => ({
      'filter[campus_resource]': contentCheck.includes(0) ? 1 : 0,
      'filter[domains]': domainId,
      'filter[types]': reject(equals(0), contentCheck),
      org_domain: domain,
      perPage: 12,
      page,
      toggle_primary: 1
    }),
    [contentCheck, domainId, domain, page]
  )

  const { data = {} } = useGetCardsQuery(cardParams, {
    skip: isNil(domain)
  })

  const cards = useMemo(() => {
    if (isEmpty(data)) return []
    return prop('data', data)
  }, [data])

  // Bail is user isn't faculty (secondary user)
  if (and(not(isNil(id)), not(is_secondary_user))) {
    navigate('/404')
    return
  }

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack spacing={4.5}>
      <Box
        sx={{
          backgroundImage: 'url(/images/student-content.jpg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 383,
          width: 1
        }}
      />
      <Stack
        spacing={4}
        sx={{
          alignItems: 'center',
          pb: { mobile: 4, laptop: 8 },
          px: 2
        }}
      >
        <Typography sx={{ alignSelf: 'flex-start' }} variant="h1">
          For Students
        </Typography>
        <CalloutBox
          topSectionCopy={
            <FormattedMessage
              defaultMessage="Explore the YOU resources available to students to help you in your role supporting them. Browse, share, and repeat."
              id="qzlaNy"
            />
          }
        />
        <Divider />

        <Stack ref={scrollRef} spacing={4}>
          <FilterMenu hasContentFilter hasTopicFilter />
          <CardGridWrapper>
            {cards.map((card, i) => (
              <CardHandler card={card} key={i} />
            ))}
          </CardGridWrapper>
        </Stack>

        <Pagination
          count={path(['meta', 'lastPage'], data)}
          onChange={compose(handleNav, setPage, nthArg(1))}
          page={page}
        />
      </Stack>
    </Stack>
  )
}

export default StudentContent
