import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { not, prop } from 'ramda'
import { Chip } from '@mui/material'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const SocialBadge = ({ socialCount = null }) => {
  const { is_secondary_user } = useSelector(prop('user'))

  const renderLabel = () => {
    if (not(is_secondary_user))
      return (
        <FormattedMessage
          defaultMessage="{socialCount} Students"
          id="BYhciy"
          values={{ socialCount }}
        />
      )
    return (
      <FormattedMessage
        defaultMessage="{socialCount} People"
        id="nKELC4"
        values={{ socialCount }}
      />
    )
  }

  return (
    <Chip
      icon={<Icon fill>group</Icon>}
      label={renderLabel()}
      sx={{
        '& > .MuiChip-icon': {
          ml: 0
        },
        px: 1,
        py: 0.5
      }}
    />
  )
}

SocialBadge.propTypes = {
  socialCount: PropTypes.number
}

export default SocialBadge
