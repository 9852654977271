export const MuiLinearProgress = palette => ({
  styleOverrides: {
    bar: {
      backgroundColor: palette.primary.container
    },
    colorPrimary: {
      backgroundColor: palette.surface.high
    },
    root: {
      borderRadius: 25,
      height: 8
    }
  },
  variants: [
    {
      props: { value: 100 },
      style: {
        '& > .MuiLinearProgress-bar': {
          backgroundColor: palette.primary.container
        }
      }
    },
    {
      props: { isSelected: true },
      style: {
        '& > .MuiLinearProgress-bar': {
          backgroundColor: palette.primary.tonal
        }
      }
    }
  ]
})
