import { Card, CardContent, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { isNil, not } from 'ramda'

const CalloutBox = ({ topSectionCopy = null, bottomSectionCopy = null }) => (
  <Card component="aside" variant="fullWidth">
    <CardContent
      sx={{
        ':last-child': {
          pb: 3
        },
        p: 3
      }}
    >
      <Typography data-testid="top-copy" variant="body1">
        {topSectionCopy}
        {not(isNil(bottomSectionCopy)) && bottomSectionCopy}
      </Typography>
    </CardContent>
  </Card>
)

export default CalloutBox

CalloutBox.propTypes = {
  bottomSectionCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  topSectionCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}
