/* eslint-disable sort-keys */
import { Box, Stack } from '@mui/material'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

const ResourceMask = () => {
  const { accent_color, flagImage, primary_color } = useSelector(prop('school'))

  return (
    <>
      <Stack
        sx={{
          alignItems: 'center',
          backgroundColor: primary_color,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '50%',
          height: 40,
          justifyContent: 'center',
          maxWidth: 'initial',
          position: 'absolute',
          right: 8,
          top: 8,
          width: 40
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${flagImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            bgcolor: 'transparent',
            height: 32,
            maxWidth: 'initial',
            position: 'absolute',
            width: 32
          }}
        />
      </Stack>
      <Box
        sx={{
          backgroundColor: accent_color,
          bottom: 6,
          height: 67,
          mask: 'url(/vectors/wave-3.svg) no-repeat 100% 100%',
          maskSize: 'cover',
          position: 'absolute',
          width: 1
        }}
      />
      <Box
        sx={{
          backgroundColor: primary_color,
          bottom: -1,
          height: 67,
          mask: 'url(/vectors/wave-3.svg) no-repeat 100% 100%',
          maskSize: 'cover',
          position: 'absolute',
          width: 1
        }}
      />
    </>
  )
}

export default ResourceMask
