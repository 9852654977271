import { useEffect, useState } from 'react'
import { Button, CircularProgress, Stack } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  dec,
  inc,
  equals,
  length,
  cond,
  always,
  lt,
  not,
  isEmpty,
  isNil,
  prop,
  propEq,
  defaultTo,
  or,
  and,
  findIndex,
  find,
  map
} from 'ramda'
import { useAnalytics } from 'hooks'
import useGetSteps from './useGetSteps'
import { setRealityCheckQuestion } from './onboardingSlice'
import {
  useUpdateOnboardedUserMutation,
  useUpdateUserMutation,
  useUpdateInterestsMutation
} from 'api/userApi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const StepNavigation = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()
  const { step: queryStep } = useParams()
  const { is_secondary_user } = useSelector(prop('user'))
  const {
    selectedInterests,
    completedSelfCheck,
    createdGoals,
    emailOptIn,
    realityCheckAnswers,
    realityCheckQuestion,
    location
  } = useSelector(prop('onboarding'))

  const dispatch = useDispatch()

  const steps = useGetSteps()
  const currStep = inc(findIndex(propEq(queryStep, 'url'), steps))

  const { enable_preset_goals } = useSelector(prop('school'))

  const [isLoading, setIsLoading] = useState(false)

  const isNextDisabled = cond([
    [equals('interests'), always(lt(length(selectedInterests), 1))],
    [equals('self-check'), always(not(completedSelfCheck))],
    [equals('goals'), always(lt(length(createdGoals), 1))],
    [equals('location'), always(or(isEmpty(location), isNil(location)))]
  ])

  const [updateInterests, { data: userInterests }] =
    useUpdateInterestsMutation()
  const [updateUserData, { data: updatedUser }] = useUpdateUserMutation()
  const [updateUser, { data: updatedUserData }] =
    useUpdateOnboardedUserMutation()

  const handleStepChange = (skip = false) => {
    // interests and the user is either a secondary type
    // or the enable goals is false from the school object
    if (equals(queryStep, 'interests')) {
      updateInterests({
        subdomains: map(prop('id'), selectedInterests)
      })
    }
    if (
      and(
        equals(queryStep, 'interests'),
        or(is_secondary_user, not(enable_preset_goals))
      )
    ) {
      handleKeenIO(skip)
      navigate('/onboarding/self-check')
      return
    }
    // If last step (all but wellpower) then complete onboarding
    if (and(equals(queryStep, 'email'), equals(currStep, length(steps)))) {
      handleKeenIO(skip)
      const body = new FormData()
      body.append('email_opt_in', emailOptIn ? 1 : 0)
      updateUserData(body)
      return
    }

    // Steps logic not used above executes down here
    // updating the next step and pushing
    // the new url
    const foundStep = prop(currStep, steps)
    handleKeenIO(skip)
    navigate(`/onboarding/${prop('url', foundStep)}`)
  }

  useEffect(() => {
    dispatch(
      setRealityCheckQuestion({
        answers: []
      })
    )
  }, [dispatch, selectedInterests])

  const handleBack = () => {
    const found = prop(dec(dec(currStep)), steps)
    navigate(`/onboarding/${prop('url', found)}`)
  }

  useEffect(() => {
    // After updating home location on success complete onboarding
    if (prop('success', updatedUser)) {
      setIsLoading(true)
      updateUser(new FormData())
    }
  }, [updateUser, updatedUser])

  // Runs at last step
  useEffect(() => {
    if (prop('success', updatedUserData)) {
      navigate('/home?justOnboarded=true')
    }
  }, [navigate, updatedUserData])

  const handleKeenIO = (skip = false) => {
    const answer = defaultTo(null, realityCheckAnswers[0])

    const keenObj = cond([
      [
        equals('interests'),
        always({
          answers: selectedInterests.map(({ title }) => title),
          question_name: 'INTERESTS_STEP'
        })
      ],
      [
        equals('self-check'),
        always({
          answers: {
            answer: realityCheckQuestion.answers[dec(answer?.score)],
            question: realityCheckQuestion.question
          },
          question_name: 'SELF_CHECK_STEP'
        })
      ],
      [
        equals('goals'),
        always({
          answers: isEmpty(createdGoals)
            ? []
            : map(prop('title'), createdGoals),
          question_name: 'GOALS_STEP'
        })
      ],
      [
        equals('email'),
        always({
          answers: emailOptIn ? 1 : 0,
          question_name: 'EMAIL_OPT_IN_STEP'
        })
      ]
    ])

    const currentStep = find(propEq('url', queryStep), steps)

    sendEvent('onboarding', {
      ...keenObj(queryStep),
      question: prop('id', currentStep),
      skip
    })
  }

  return (
    <Stack
      sx={{
        bgcolor: 'surface.container',
        bottom: 0,
        boxShadow: 3,
        left: 0,
        p: 2,
        position: 'fixed',
        right: 0,
        zIndex: 1
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          width: 1
        }}
      >
        <Button
          aria-label={intl.formatMessage({
            defaultMessage: 'Back Button',
            id: 'FzTVd+'
          })}
          onClick={() => handleBack()}
          startIcon={<Icon>arrow_back</Icon>}
          variant="text"
        >
          <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
        </Button>

        <Stack direction="row" spacing={1}>
          {not(equals(queryStep, 'email')) && (
            <Button
              aria-label={intl.formatMessage({
                defaultMessage: 'Skip Button',
                id: 'SB/IdC'
              })}
              onClick={() => handleStepChange(true)}
              sx={{
                fontWeight: 700
              }}
              variant="text"
            >
              <FormattedMessage defaultMessage="Skip" id="/4tOwT" />
            </Button>
          )}

          <Button
            aria-label={intl.formatMessage({
              defaultMessage: 'Next Button',
              id: 'VWBImm'
            })}
            disabled={or(isNextDisabled(queryStep), isLoading)}
            onClick={() => handleStepChange()}
          >
            {equals(currStep, length(steps)) ? (
              <FormattedMessage
                defaultMessage="Complete Onboarding"
                id="erEjpH"
              />
            ) : isLoading ? (
              <CircularProgress />
            ) : (
              <FormattedMessage defaultMessage="Next" id="9+Ddtu" />
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default StepNavigation
