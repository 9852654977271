import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import AccessibleModal from 'components/modals/AccessibleModal'
import { Box, Button, Stack, Typography } from '@mui/material'

const ConfirmationModal = ({ open, onClose }) => (
  <AccessibleModal
    onClose={onClose}
    open={open}
    title={
      <Stack alignItems="center" spacing={2}>
        <Box
          sx={{
            backgroundImage: 'url(/vectors/you-logo.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 24,
            width: 24
          }}
        />
        <Typography
          sx={{
            textTransform: 'none'
          }}
          textAlign="center"
          variant="h3"
        >
          <FormattedMessage
            defaultMessage="Way to put you first!"
            id="TIrzXw"
          />
        </Typography>
      </Stack>
    }
  >
    <Stack alignItems="center" spacing={2}>
      <Typography variant="body1">Keep your momentum and dive in!</Typography>
      <Stack alignItems="center" spacing={4}>
        <Box
          component="img"
          height={163}
          mx="auto"
          src="/vectors/onboard-modal.svg"
          width={220}
        />

        <Button onClick={onClose}>
          <FormattedMessage defaultMessage="Let's Go" id="yYWjTC" />
        </Button>
      </Stack>
    </Stack>
  </AccessibleModal>
)

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ConfirmationModal
