import PropTypes from 'prop-types'
import {
  equals,
  reverse,
  sortBy,
  prop,
  not,
  isNil,
  assoc,
  isEmpty
} from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const Focus = ({ goal, setGoal }) => {
  const { userDomains } = useSelector(prop('user'))

  if (isEmpty(userDomains)) return null

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        // eslint-disable-next-line sort-keys
        gridTemplateColumns: { mobile: '1fr', tablet: 'repeat(3, 1fr)' }
      }}
    >
      {reverse(sortBy(prop('sort'))(userDomains)).map(
        ({ id, frontend_name, subdomains, subtitle }) => (
          <Box key={id}>
            <Stack direction="row" mb={1} spacing={0.5}>
              <Typography tabIndex={0} variant="h5">
                {frontend_name} /
              </Typography>
              <Typography textTransform="capitalize" variant="body1">
                {subtitle}
              </Typography>
            </Stack>

            <Stack
              spacing={2}
              sx={{
                '& > button': {
                  width: 1
                },
                alignItems: 'center'
              }}
            >
              {reverse(
                sortBy(prop('sort'))(
                  subdomains.filter(({ onboarding_title }) =>
                    not(isNil(onboarding_title))
                  )
                )
              )
                .map(({ id, onboarding_title }) => (
                  <Button
                    aria-label={
                      equals(prop('subdomain_id', goal), id)
                        ? `Selected: ${frontend_name} ${onboarding_title}`
                        : `Unselected: ${frontend_name} ${onboarding_title}`
                    }
                    key={id}
                    onClick={() => setGoal(assoc('subdomain_id', id, goal))}
                    selected={equals(prop('subdomain_id', goal), id)}
                    variant="select"
                  >
                    {onboarding_title}
                  </Button>
                ))
                .sort((a, b) => a.sort - b.sort)}
            </Stack>
          </Box>
        )
      )}
    </Box>
  )
}

Focus.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired
}

export default Focus
