import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { any, compose, equals, isEmpty, path } from 'ramda'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import useBuildFilters from './useBuildFilters'
import Icon from 'components/icon/Icon'

const TopicFilter = ({ active, handleFilter, isDisabled }) => {
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const { topicOptions } = useBuildFilters()

  const eventName = useMemo(() => {
    if (
      any(equals(true))([
        pathname.includes('succeed'),
        pathname.includes('thrive'),
        pathname.includes('matter')
      ])
    )
      return 'you_subdomain_filter'
    return 'filter_engagement'
  }, [pathname])

  const handleAnalytics = val => {
    sendEvent(eventName, {
      filter_selection: val,
      page_title: pathname
    })
    handleFilter(val)
  }

  return (
    <FormControl
      disabled={isDisabled}
      fullWidth
      sx={{ maxWidth: 250, width: 1 }}
    >
      <InputLabel id="topic">Topic Filter</InputLabel>
      <Select
        aria-label={isEmpty(active) ? 'opens list' : `Selected: ${active}`}
        label="Topic Filter"
        labelId="topic"
        multiple
        onChange={compose(handleAnalytics, path(['target', 'value']))}
        renderValue={opt => opt.join(', ')}
        value={active}
      >
        {topicOptions.map(opt => (
          <MenuItem key={opt} value={opt}>
            <ListItemText primary={opt} />
            {active.includes(opt) ? (
              <Icon>check_box</Icon>
            ) : (
              <Icon>check_box_outline_blank</Icon>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

TopicFilter.propTypes = {
  active: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFilter: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

export default TopicFilter
