import { FormattedMessage } from 'react-intl'
import { useGetCompletedGoalsQuery } from 'api/goalsApi'
import { prop, length, gt, isEmpty } from 'ramda'
import { Box, Stack, Typography } from '@mui/material'

import CompletedGoalCard from 'components/cards/CompletedGoalCard'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import Icon from 'components/icon/Icon'

const Completed = () => {
  const { data: goals = [] } = useGetCompletedGoalsQuery()

  if (isEmpty(goals)) return

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center'
        }}
      >
        <Icon fill>check_circle</Icon>
        <Typography variant="h6">
          <FormattedMessage
            defaultMessage="{number} Completed {goal}"
            id="Ab9YFg"
            values={{
              goal: gt(length(goals), 1) ? 'Goals' : 'Goal',
              number: length(goals)
            }}
          />
        </Typography>
      </Stack>
      <Box
        sx={{
          my: 3
        }}
      >
        <CardGridWrapper>
          {goals.map(goal => (
            <CompletedGoalCard card={goal} key={prop('id', goal)} />
          ))}
        </CardGridWrapper>
      </Box>
    </>
  )
}

Completed.propTypes = {}

export default Completed
