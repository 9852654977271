import { api as baseQuery } from './baseQuery'

export const profileApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    getOriginalProfileQuestions: query({
      providesTags: ['profile questions'],
      query: () => '/profile-questions'
    }),
    getProfileQuestions: query({
      providesTags: ['profile questions'],
      query: () => '/v2/profile'
    }),
    updateInterestedSubdomains: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/interests'
      })
    }),
    updateProfile: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile'
      })
    }),
    updateProfilePhoto: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/photo'
      })
    }),
    updateProfileTerms: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/tou-pp-version'
      })
    })
  }),
  reducerPath: 'profileApi'
})

export const {
  useGetOriginalProfileQuestionsQuery,
  useGetProfileQuestionsQuery,
  useUpdateInterestedSubdomainsMutation,
  useUpdateProfileMutation,
  useUpdateProfilePhotoMutation,
  useUpdateProfileTermsMutation
} = profileApi
