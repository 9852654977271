import PropTypes from 'prop-types'
import { compose, equals, path, prop } from 'ramda'
import { Stack, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const PreferredName = ({ question, userAnswers, onUpdateAnswer }) => {
  const { id } = useSelector(prop('user'))

  const handleChange = val => {
    onUpdateAnswer({
      answers: [val],
      profile_question_id: prop('id', question)
    })
  }

  return (
    <Stack
      spacing={1}
      sx={{
        width: 1
      }}
    >
      <Stack alignItems="baseline" direction="row" spacing={1.5}>
        <Typography variant="h4">{prop('question', question)}</Typography>
        {equals('preferred_name', prop('name', question)) && (
          <Typography variant="subtitle2">User ID: {id}</Typography>
        )}
      </Stack>
      <TextField
        defaultValue={prop(0, userAnswers)}
        fullWidth
        inputProps={{
          maxLength: 50
        }}
        label="Preferred Name"
        name={prop('name', question)}
        onChange={compose(handleChange, path(['target', 'value']))}
        sx={{
          '& > .MuiInputLabel-root': {
            textIndent: '-9999px'
          }
        }}
      />
    </Stack>
  )
}

PreferredName.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({}).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired
}

export default PreferredName
