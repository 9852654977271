import { useState } from 'react'
import { Button, Collapse, Skeleton, Stack } from '@mui/material'
import {
  not,
  defaultTo,
  equals,
  gt,
  length,
  reduce,
  prop,
  isNil,
  or,
  isEmpty
} from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

import MyPrioritiesCarousel from './MyPrioritiesCarousel'
import EmptyPrioritiesHero from './EmptyPrioritiesHero'
import SubdomainSelector from 'components/subdomain-selector/SubdomainSelector'
import Icon from 'components/icon/Icon'

const MyPriorities = () => {
  const { sendEvent } = useAnalytics()
  const { formatMessage } = useIntl()
  const { interested_subdomains, userDomains } = useSelector(prop('user'))

  // STATE
  const [showMenu, setShowMenu] = useState(false)

  // LOADING HANDLING
  if (or(isNil(userDomains), isEmpty(userDomains)))
    return (
      <Stack alignItems="center" spacing={2} width="100%">
        <Skeleton variant="text" width={220} />
        <Skeleton height={340} variant="rectangular" width="100%" />
        <Skeleton height={48} variant="rectangular" width={190} />
      </Stack>
    )

  const subdomains = reduce(
    (subdomains, domain) => subdomains.concat(domain.subdomains),
    [],
    userDomains
  )

  const interested = subdomains.filter(({ id }) =>
    interested_subdomains.some(sub => equals(id, prop('id', sub)))
  )

  const priorities = defaultTo([], interested)

  // VARIABLES & FUNCTIONS
  const toggleMenu = () => {
    setShowMenu(not(showMenu))
    if (not(showMenu)) {
      sendEvent('block_interactions', {
        block_title: 'My Priorities',
        click_element: 'button',
        element_title: 'Edit Priorities',
        path: window.location.pathname
      })
      // timeout due to race conditions
      setTimeout(() => window.scrollTo(0, 560), 500)
    }
  }

  return (
    <Stack>
      {gt(length(priorities), 0) ? (
        <MyPrioritiesCarousel priorities={priorities} toggleMenu={toggleMenu} />
      ) : (
        <EmptyPrioritiesHero />
      )}
      <Stack alignItems="center" justifyContent="center">
        {gt(length(priorities), 0) ? (
          <Button
            aria-label={
              showMenu
                ? formatMessage({
                    defaultMessage: 'Expanded, Hide priorities',
                    id: '3k3HRB'
                  })
                : formatMessage({
                    defaultMessage: 'Collapsed, Edit priorities',
                    id: 'OCBfoK'
                  })
            }
            endIcon={
              showMenu ? (
                <Icon>keyboard_arrow_up</Icon>
              ) : (
                <Icon>keyboard_arrow_down</Icon>
              )
            }
            onClick={() => toggleMenu()}
            sx={{ mx: 'auto' }}
            variant="text"
          >
            {showMenu ? (
              <FormattedMessage defaultMessage="Hide priorities" id="UBwE55" />
            ) : (
              <FormattedMessage defaultMessage="Edit priorities" id="sLsS9c" />
            )}
          </Button>
        ) : (
          <Button onClick={() => toggleMenu()}>
            {showMenu ? (
              <FormattedMessage defaultMessage="Close" id="rbrahO" />
            ) : (
              <FormattedMessage
                defaultMessage="Set my priorities"
                id="kC1HIz"
              />
            )}
          </Button>
        )}
      </Stack>
      <Collapse in={showMenu}>
        <SubdomainSelector />
      </Collapse>
    </Stack>
  )
}

export default MyPriorities
