import { Box, Container, Slide, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const KeepRolling = () => (
  <Container>
    <Stack alignItems="center" spacing={3}>
      <Slide direction="down" in timeout={500}>
        <Typography component="h1" variant="h2">
          <FormattedMessage defaultMessage="Keep Rolling" id="k9Wpd/" />
        </Typography>
      </Slide>
      <Slide direction="up" in timeout={500}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ maxWidth: 582, textAlign: 'center' }}
        >
          <Box
            component="img"
            src="/vectors/interactive-content/ball.svg"
            sx={{ maxWidth: 124 }}
          />
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="Feelings of stress can come and go. Let’s take a look at some recommended resources to save for later. Your future self will thank you."
              id="XT0GW2"
            />
          </Typography>
        </Stack>
      </Slide>
    </Stack>
  </Container>
)

export default KeepRolling
