export const MuiBadge = palette => ({
  styleOverrides: {
    colorPrimary: {
      backgroundColor: palette.secondary.swatch
    },
    dot: {
      backgroundColor: palette.secondary.swatch
    }
  }
})
