import PropTypes from 'prop-types'
import { equals, prop } from 'ramda'
import { TYPE_CAMPUS_GOAL, TYPE_POLL } from 'utils/card-functions'
import { Card } from '@mui/material'

import CardAnimationWrapper from './CardAnimationWrapper'
import PollOfMonth from 'components/poll-of-month/PollOfMonth'
import ContentCard from './ContentCard'
import ExploreGoalCard from './ExploreGoalCard'
import SmallCard from './SmallCard'

const CardHandler = ({ card, small = false, ...rest }) => {
  const cardType = prop('type', card)

  const typeHandler = () => {
    if (equals(cardType, TYPE_POLL))
      return (
        <Card>
          <PollOfMonth pollingQuestion={prop('poll_question', card)} />
        </Card>
      )
    if (equals(cardType, TYPE_CAMPUS_GOAL))
      return <ExploreGoalCard card={card} {...rest} />
    if (small) return <SmallCard card={card} {...rest} />
    return <ContentCard card={card} {...rest} />
  }

  return <CardAnimationWrapper>{typeHandler()}</CardAnimationWrapper>
}

CardHandler.propTypes = {
  card: PropTypes.shape({}),
  small: PropTypes.bool
}

export default CardHandler
