import PropTypes from 'prop-types'
import AccessibleModal from 'components/modals/AccessibleModal'
import { FormattedMessage } from 'react-intl'
import { Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const SignUpModal = ({ isOpen, setIsOpen }) => {
  return (
    <AccessibleModal
      onClose={() => setIsOpen(false)}
      open={isOpen}
      title={<FormattedMessage defaultMessage="DE-STRESS 101" id="qOWEwl" />}
    >
      <Stack sx={{ maxWidth: 400, textAlign: 'center' }}>
        <Typography variant="h5">
          <FormattedMessage
            defaultMessage="Sign up or log in to access De-Stress 101"
            id="iTKwu1"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="Find helpful ways to reduce stress and be your best."
            id="XI9Nye"
          />
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
        <Button
          LinkComponent={Link}
          onClick={() => localStorage.setItem('redirect', '/destress/poll')}
          to="/sign-up"
        >
          <FormattedMessage defaultMessage="sign up" id="yv/kr9" />
        </Button>
        <Button
          LinkComponent={Link}
          onClick={() => localStorage.setItem('redirect', '/destress/poll')}
          to="/sign-in"
          variant="outlined"
        >
          <FormattedMessage defaultMessage="log in" id="rYQKZm" />
        </Button>
      </Stack>
    </AccessibleModal>
  )
}

SignUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default SignUpModal
