import PropTypes from 'prop-types'
import { useGetCardQuery } from 'api/cardsApi'
import {
  Card,
  CardActionArea,
  CardMedia,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import {
  append,
  defaultTo,
  equals,
  findIndex,
  gte,
  or,
  path,
  prop,
  without
} from 'ramda'
import { truncateLines } from 'utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setResources } from './destressSlice'
import { useAnalytics } from 'hooks'
import { useLocation } from 'react-router'

const ResourceCard = ({ id }) => {
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { resources } = useSelector(prop('destress'))

  const { data, isFetching, isError } = useGetCardQuery(id)

  const title = prop('title', data)
  const foundIdx = findIndex(equals(id), resources)
  const isSelected = or(gte(foundIdx, 0), prop('is_favorite', data))

  if (isError) return null

  if (isFetching)
    return (
      <Skeleton
        height={88}
        sx={{ borderRadius: 2 }}
        variant="rectangular"
        width="100%"
      />
    )

  const handleAnalytics = arr => {
    sendEvent('Interactive Content', {
      module_name: 'Destress',
      path: pathname,
      selection: arr,
      step_name: 'Resources',
      trigger: 'Click'
    })
  }

  const handleClick = () => {
    if (isSelected) {
      const removed = without([id], resources)
      handleAnalytics(removed)
      return dispatch(setResources(removed))
    }
    const added = append(id, resources)
    handleAnalytics(added)
    dispatch(setResources(added))
  }

  return (
    <Card
      sx={{
        bgcolor: isSelected ? 'primary.tonalContainer' : 'surface.container',
        borderRadius: 2,
        boxShadow: 0
      }}
    >
      <CardActionArea
        aria-label={`${prop('title', data)} ${
          isSelected ? 'selected' : 'unselected'
        }`}
        onClick={handleClick}
        sx={{
          display: 'flex',
          height: 1,
          p: 1
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{
            width: 1
          }}
        >
          <CardMedia
            alt=""
            component="img"
            image={defaultTo(
              path(['photo', 'large_url'], data),
              prop('video_thumbnail_url', data)
            )}
            sx={{ borderRadius: 1.5, height: 78, width: 78 }}
          />
          <Typography
            sx={{
              ...truncateLines(2)
            }}
            variant="h5"
          >
            {title}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

ResourceCard.propTypes = {
  id: PropTypes.number.isRequired,
  resources: PropTypes.arrayOf(PropTypes.string).isRequired,
  setResources: PropTypes.func.isRequired
}

export default ResourceCard
