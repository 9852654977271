import {
  Alert,
  AlertTitle,
  Button,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { useUpdateProfileMutation } from 'api/profileApi'
import { Link, useLocation } from 'react-router-dom'
import { all, equals, isNil, not, or, path, prop } from 'ramda'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'
import { setShowProfile } from './alertSlice'

const ProfileAlert = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { has_seen_profile_update: hasSeenProfileAnnouncement, id } =
    useSelector(prop('user'))
  const showProfile = useSelector(path(['alert', 'showProfile']))

  const [update] = useUpdateProfileMutation()

  // PROFILE
  // If has not seen profile alert
  // Not already showing
  // User is logged in
  // if the user hasn't seen profile update (false && null apparently)
  // on home or explore page
  useEffect(() => {
    if (
      all(equals(true))([
        not(isNil(id)),
        or(not(hasSeenProfileAnnouncement), isNil(hasSeenProfileAnnouncement)),
        or(equals(pathname, '/home'), equals(pathname, '/explore'))
      ])
    ) {
      dispatch(setShowProfile(true))
    }
  }, [dispatch, hasSeenProfileAnnouncement, pathname, id])

  const handleClick = () => {
    update({ has_seen_profile_update: true })
    dispatch(
      updateUserData({
        name: 'has_seen_profile_update',
        value: true
      })
    )
    dispatch(setShowProfile(false))
  }

  return (
    <Slide direction="left" in={showProfile}>
      <Alert
        component="div"
        icon={false}
        role="alert"
        severity="info"
        sx={{
          boxShadow: 6
        }}
        variant="outlined"
      >
        <AlertTitle>
          <FormattedMessage defaultMessage="Update your Profile!" id="GVOTUB" />
        </AlertTitle>
        <Typography sx={{ display: 'inline' }} variant="body2">
          <FormattedMessage
            defaultMessage="Help us help you! Take 2 minutes to fill out your profile for a customized experience as unique as you."
            id="Fb3uha"
          />
        </Typography>
        <Stack direction="row" mt={2} spacing={2}>
          <Button
            LinkComponent={Link}
            onClick={handleClick}
            to="/profile?active=about"
          >
            <FormattedMessage defaultMessage="Visit Profile" id="AZj8qj" />
          </Button>
          <Button onClick={handleClick} variant="outlined">
            <FormattedMessage defaultMessage="Close" id="rbrahO" />
          </Button>
        </Stack>
      </Alert>
    </Slide>
  )
}

export default ProfileAlert
