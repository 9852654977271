export const MuiPickersArrowSwitcher = palette => ({
  styleOverrides: {
    button: {
      '&.Mui-disabled': {
        color: `${palette.button.primary.textDisabled} !important`
      },
      color: palette.surface.onSurface
    }
  }
})
