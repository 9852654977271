/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import {
  prop,
  isNil,
  path,
  gt,
  defaultTo,
  not,
  length,
  toLower,
  or,
  equals
} from 'ramda'
import { useGetSubdomainQuery } from 'api/schoolApi'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import PollOfMonth from 'components/poll-of-month/PollOfMonth'
import Banner from 'components/hero/Banner'
import UserFeedback from 'components/user-feedback/UserFeedback'
import FeatureFlagWrapper from 'components/feature-flag/FeatureFlagWrapper'
import SubdomainCalloutBox from './SubdomainCalloutBox'
import LearnNewSkillsBlock from './LearnNewSkillsBlock'
import ExploreResourcesBlock from './ExploreResourcesBlock'
import ConnectedSubdomains from './ConnectedSubdomains'
import PrioritizedIcon from './PrioritizedIcon'
import EntryCard from 'pages/destress/components/EntryCard'

const Subdomain = () => {
  const { pathname } = useLocation()
  const { subdomainName } = useParams()
  const { is_secondary_user, userDomains } = useSelector(prop('user'))

  const subdomainId = useMemo(() => {
    if (isNil(userDomains)) return null
    return prop(
      'id',
      subdomainsFromUserDomains(userDomains).find(({ frontend_url_slug }) =>
        equals(frontend_url_slug, subdomainName)
      )
    )
  }, [userDomains, subdomainName])

  const { data, isFetching } = useGetSubdomainQuery(subdomainId, {
    skip: isNil(subdomainId)
  })

  const subdomainData = prop('subdomain', data)

  const title = useMemo(() => {
    if (isNil(userDomains)) return null
    const sectionId = prop('section_id', subdomainData)
    const found = userDomains.find(({ id }) => equals(id, sectionId))
    return prop('subtitle', found)
  }, [userDomains, subdomainData])

  if (or(isFetching, isNil(subdomainId))) return

  const hasPolling = path(['poll_question', 'allow_as_poll_on_subdomain'], data)
  const hasSkillCards = gt(defaultTo(0, length(prop('skill_cards', data))), 0)

  return (
    <Box>
      <Banner imageUrl={prop('frontend_display_image', subdomainData)} />
      <Stack
        sx={{
          my: { mobile: 3, tablet: 6 },
          px: 3
        }}
      >
        <Stack spacing={{ mobile: 3, tablet: 4 }}>
          <Stack>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <PrioritizedIcon
                title={path(['subdomain', 'onboarding_title'], data)}
              />
              <Typography variant="overline">{title}</Typography>
            </Stack>
            <Typography variant="h1">
              {prop('frontend_name', subdomainData)}
            </Typography>
          </Stack>

          <Box
            sx={{
              display: 'grid',
              gap: { mobile: 2, tablet: 3 },
              gridTemplateAreas: {
                mobile: `
              'callout'
              'destress'
              'poll'
              'skills'
              `,
                tablet: `
              'callout callout'
              'destress destress'
              'skills poll'
              `
              }
            }}
          >
            {not(isNil(prop('context_copy', subdomainData))) && (
              <Box sx={{ gridArea: 'callout' }}>
                <SubdomainCalloutBox
                  crisisSubdomain={prop('negative_content', subdomainData)}
                  topSectionCopy={prop('context_copy', subdomainData)}
                />
              </Box>
            )}

            <FeatureFlagWrapper
              requirements={[
                not(is_secondary_user),
                pathname.includes('/thrive/stress')
              ]}
            >
              <EntryCard />
            </FeatureFlagWrapper>

            {hasPolling && (
              <Box
                sx={{
                  gridArea: 'poll',
                  m: '0 auto',
                  maxWidth: 375
                }}
              >
                <PollOfMonth pollingQuestion={prop('poll_question', data)} />
              </Box>
            )}
            {hasSkillCards && (
              <Box sx={{ gridArea: 'skills' }}>
                <LearnNewSkillsBlock
                  containsPolling={hasPolling}
                  skillCards={prop('skill_cards', data)}
                />
              </Box>
            )}
          </Box>
        </Stack>

        <ExploreResourcesBlock subdomain={data} />

        <Divider sx={{ mt: 6 }} />

        <UserFeedback
          hasNeutral
          negativeTitle={
            <FormattedMessage
              defaultMessage="Looks like we missed the mark."
              id="kKAHaA"
            />
          }
          positiveTitle={
            <FormattedMessage
              defaultMessage="Awesome! Keep exploring for more."
              id="xT2bTP"
            />
          }
          subtitle={
            <FormattedMessage
              defaultMessage="Have you learned something new in {domain}?"
              id="TiZl5R"
              values={{
                domain: toLower(prop('onboarding_title', subdomainData))
              }}
            />
          }
        />
      </Stack>
      <ConnectedSubdomains
        subdomainData={prop('relatedDomains', subdomainData)}
      />
    </Box>
  )
}

export default Subdomain
