'use client'
import { always, assoc, evolve } from 'ramda'
import { useEffect, useState } from 'react'

const useWindowResize = () => {
  const [size, setSize] = useState({ isKeyboardShown: false, x: 0, y: 0 })

  useEffect(() => {
    const updateSize = () => {
      setSize(
        evolve({
          x: always(window.innerWidth),
          y: always(window.innerHeight)
        })
      )
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    const mobileKeyboardCheck = e => {
      const viewportRatio = 0.75
      if (
        (e.target.height * e.target.scale) / window.screen.height <
        viewportRatio
      ) {
        return setSize(assoc('isKeyboardShown', true, size))
      }
      return setSize(assoc('isKeyboardShown', false, size))
    }
    window.visualViewport.addEventListener('resize', mobileKeyboardCheck)
    return () =>
      window.visualViewport.removeEventListener('resize', mobileKeyboardCheck)
  }, [size])

  return size
}

export default useWindowResize
