import PropTypes from 'prop-types'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { append, equals, isNil, map, prop } from 'ramda'
import { Button, Stack, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'

const ButtonSelect = ({ question, userAnswers, onUpdateAnswer }) => {
  const isMulti = equals(prop('type', question), 'multi')
  const { sendEvent } = useAnalytics()

  // Map through question answers
  // filter to find the selected ones
  const mappedAnswers = arr =>
    prop('answers', question).filter(({ id }) =>
      arr.some(val => equals(id, val))
    )

  // Grab the name of every selection
  // send analytics event
  const handleAnalytics = arr => {
    const names = map(prop('answer'), mappedAnswers(arr))
    sendEvent('profile_question', {
      answer: names,
      question: prop('analytics_property_name', question)
    })
  }

  const handleClick = id => {
    const existingItem = userAnswers.find(equals(id))

    if (isNil(existingItem)) {
      const updatedAnswer = {
        answers: append(id, userAnswers),
        profile_question_id: prop('id', question)
      }
      handleAnalytics(append(id, userAnswers))
      return onUpdateAnswer(updatedAnswer)
    }
    const tempAnswers = [...userAnswers]
    const index = tempAnswers.indexOf(existingItem)
    if (index > -1) {
      // only splice array when item is found
      tempAnswers.splice(index, 1) // 2nd parameter means remove one item only
    }
    const updatedAnswer = {
      answers: tempAnswers,
      profile_question_id: prop('id', question)
    }
    handleAnalytics(tempAnswers)
    onUpdateAnswer(updatedAnswer)
  }

  return (
    <Stack spacing={3} sx={{ width: 1 }}>
      <Stack spacing={1}>
        <Typography variant="h4">{prop('question', question)}</Typography>
        {isMulti && (
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Select all that apply"
              id="gSenzY"
            />
          </Typography>
        )}
      </Stack>
      <Stack
        direction="row"
        sx={{
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        {prop('answers', question).map(({ answer, id }) => (
          <Button
            aria-label={`${userAnswers.some(equals(id)) ? 'selected, ' : 'un-selected, '}, ${answer}`}
            key={id}
            onClick={() => handleClick(id)}
            selected={userAnswers.some(equals(id))}
            variant="select"
          >
            {answer}
          </Button>
        ))}
      </Stack>
    </Stack>
  )
}

ButtonSelect.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    name: PropTypes.string,
    question: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.number).isRequired
}

export default memo(ButtonSelect)
