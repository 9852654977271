import { Alert } from '@mui/material'
import PropTypes from 'prop-types'
import { not } from 'ramda'

const Toast = ({ isShown = false, message = '', type = 'success' }) => {
  if (not(isShown)) return

  return (
    <Alert color={type} severity={type} variant="outlined">
      {message}
    </Alert>
  )
}

Toast.propTypes = {
  isShown: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  type: PropTypes.oneOf(['info', 'error', 'success', 'warning'])
}

export default Toast
