import { assoc, prop, isNil, not, equals } from 'ramda'
import { sanitizeUrl } from 'utils/helpers'

export const TYPE_ASSESSMENT = 1
export const TYPE_RESOURCE = 2
export const TYPE_FACT_TIP = 3
export const TYPE_LIFE_LESSON = 4
export const TYPE_TOOL = 5
export const TYPE_EVENT = 6
export const TYPE_STORY = 7
export const TYPE_CAMPUS_GOAL = 8
export const TYPE_NOD_CHALLENGE = 9
export const TYPE_GEO_RESOURCE = 11
export const TYPE_TIP = 12
export const TYPE_SKILL = 13
export const TYPE_POLL = 99

export const internalUrl = card => {
  const type = prop('type', card)
  const title = prop('title', card)
  const url = not(isNil(title)) ? sanitizeUrl(title) : title
  //Because sometimes cards don't have all the data in them, like recommendedCards
  const copy = isNil(prop('section_id', card))
    ? assoc('section_id', 2, card)
    : card

  if (equals(type, TYPE_CAMPUS_GOAL)) return `/goals/new/${prop('id', copy)}`
  return `/article/${prop('id', copy)}/${url}`
}
