import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Stack } from '@mui/material'
import { useSignInMutation } from 'api/authApi'
import { path, toLower, defaultTo, prop, or, isNil } from 'ramda'
import { useAnalytics, useLocalStorage, useSession } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetState } from 'store/authenticationSlice'

import EmailAndPassword from 'components/authentication/EmailAndPassword'
import Toast from 'components/toast/Toast'

const BasicSignIn = () => {
  const dispatch = useDispatch()
  const { domain } = useSelector(prop('school'))
  const { email, password } = useSelector(prop('authentication'))
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()
  const { save } = useLocalStorage()

  const [
    signIn,
    { isError, isLoading, isSuccess, data, error = { data: { email: '' } } }
  ] = useSignInMutation()

  const onSubmit = () => {
    const body = {
      auth_type: 'traditional',
      email: toLower(email),
      org_domain: domain,
      password
    }
    signIn(body)
  }

  useEffect(() => {
    const handleAnalytics = token => {
      const promises = [
        sessionEvent('login', { session_id: token }),
        sendEvent('login', {
          auth_type: 'traditional',
          validation_errors: [],
          validation_success: true
        }),
        sendEvent('sign-in', {
          auth_type: 'traditional',
          validation_errors: [],
          validation_success: true
        })
      ]
      return Promise.allSettled(promises)
    }

    if (isSuccess) {
      const sessionId = path(['headers', 'headers'], data).get('X-Session-Id')
      save(sessionId)
      // Tracking
      handleAnalytics(sessionId)
        .then(() => dispatch(resetState()))
        .finally(() => navigate('/home'))
    }
  }, [isSuccess, data, dispatch, navigate, sessionEvent, sendEvent, save])

  return (
    <Stack
      component="form"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      spacing={2}
      sx={{ mt: 3, width: 1 }}
    >
      <Toast
        isShown={isError}
        message={defaultTo(
          path(['data', 'email'], error),
          path(['data', 'errors', 'email'], error)
        )}
        type="error"
      />
      <EmailAndPassword />
      <Button disabled={or(isLoading, isNil(domain))} fullWidth type="submit">
        <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
      </Button>
    </Stack>
  )
}

export default BasicSignIn
