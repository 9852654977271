/* eslint-disable sort-keys */
import BasicSignIn from './components/BasicSignIn'
import SSOSignIn from './components/SSOSignIn'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { equals, isNil, or, prop } from 'ramda'
import { useSelector } from 'react-redux'

const SignInPage = () => {
  const { letters, sso_enabled } = useSelector(prop('school'))

  const renderComponent = () => {
    if (or(isNil(letters), isNil(sso_enabled))) return
    if (equals('1', sso_enabled)) return <SSOSignIn />
    return <BasicSignIn />
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        minHeight: {
          mobile: 'calc(100vh - 122px)',
          tablet: 'calc(100vh - 164px)',
          laptop: 'calc(100vh - 196px)'
        },
        mt: { mobile: 8, tablet: 12.5 },
        mx: 'auto',
        overflow: 'hidden',
        position: 'relative',
        px: 2
      }}
    >
      <Stack alignItems="center" spacing={0.5} sx={{ pt: 1, px: 2 }}>
        <Typography textAlign="center" variant="h1">
          <FormattedMessage
            defaultMessage="Log into your account"
            id="g/KaJY"
          />
        </Typography>
        <Stack alignItems="center" direction="row" spacing={3}>
          <Typography variant="subtitle2">New to YOU at {letters}?</Typography>
          <Button LinkComponent={Link} to="/sign-up" variant="text">
            <FormattedMessage defaultMessage="Create an account" id="0vL5u1" />
          </Button>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        sx={{
          maxWidth: 480,
          px: 2,
          py: 1,
          width: 1
        }}
      >
        {renderComponent()}
      </Stack>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/chess.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bottom: -28,
          height: 219,
          position: 'absolute',
          right: -33,
          width: 244
        }}
      />
    </Stack>
  )
}

export default SignInPage
