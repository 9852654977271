import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { equals, inc, length, not, toLower } from 'ramda'
import { Box, Button, Card, Chip, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import SocialBadge from 'components/social-badge/SocialBadge'
import Icon from 'components/icon/Icon'

const TakeQuizCard = ({
  isCompleted = false,
  domainUrlSlug,
  socialCount = null,
  subdomains,
  title
}) => (
  <Card
    sx={{
      px: 2,
      py: 4
    }}
  >
    <Stack
      spacing={3}
      sx={{
        alignItems: 'center'
      }}
    >
      <Typography variant="h2">{title}</Typography>
      <Chip
        label={<FormattedMessage defaultMessage="2-5 minutes" id="rBWPEU" />}
      />
      <Box
        height={119}
        role="presentation"
        sx={{
          backgroundImage: `url(/vectors/${toLower(title)}${not(isCompleted) ? '-bw' : ''}.svg)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
        width={144}
      />
      <Stack alignItems="center" spacing={1.5}>
        <Typography variant="subtitle1">Taken by</Typography>
        <SocialBadge aria-hidden socialCount={socialCount} />
      </Stack>
      {isCompleted ? (
        <Stack direction="row" justifyContent="center" spacing={1} width={1}>
          <Button
            LinkComponent={Link}
            startIcon={<Icon>restart_alt</Icon>}
            sx={{ textAlign: 'center' }}
            to={`/self-checks/${domainUrlSlug}`}
            variant="text"
          >
            <FormattedMessage defaultMessage="Retake" id="+ictOG" />
          </Button>
          <Button
            LinkComponent={Link}
            to={`/self-checks/results/${domainUrlSlug}`}
          >
            <FormattedMessage defaultMessage="View results" id="WI1nyU" />
          </Button>
        </Stack>
      ) : (
        <Button LinkComponent={Link} to={`/self-checks/${domainUrlSlug}`}>
          <FormattedMessage defaultMessage="take this assessment" id="lU9cvh" />
        </Button>
      )}

      <Stack spacing={1.5}>
        <Typography component="span" textAlign="center" variant="subtitle1">
          Topics Covered
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          {subdomains.map(({ title }, idx) => (
            <Typography component="span" key={title} variant="body2">
              <FormattedMessage
                defaultMessage="{title}"
                id="2YgLVn"
                values={{ title }}
              />
              {equals(inc(idx), length(subdomains)) ? '' : ' ∙ '}
            </Typography>
          ))}
        </Box>
      </Stack>
    </Stack>
  </Card>
)

TakeQuizCard.propTypes = {
  domainUrlSlug: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  socialCount: PropTypes.number,
  subdomains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired
}

export default TakeQuizCard
