import { FormattedMessage } from 'react-intl'
import parse from 'html-react-parser'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { not, path, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { setConsent } from 'store/authenticationSlice'

const Label = () => {
  const { register_disclaimer } = useSelector(prop('school'))

  return (
    <Typography htmlFor="active-consent-input" variant="body2">
      <FormattedMessage
        defaultMessage="I accept the {terms_of_use} and {privacy_policy}, I understand that the information I share will be {data_collection}"
        id="Bh7zYZ"
        values={{
          data_collection: (
            <Typography
              color="primary"
              component={Link}
              sx={{
                textDecoration: 'underline',
                textTransform: 'none'
              }}
              to="/privacy-policy#2-how-we-use-collected-information"
              variant="body2"
            >
              <FormattedMessage
                defaultMessage="used to create and service my account."
                id="bLvgyA"
              />
            </Typography>
          ),
          privacy_policy: (
            <Typography
              color="primary"
              component={Link}
              sx={{
                textDecoration: 'underline'
              }}
              to="/privacy-policy"
              variant="body2"
            >
              <FormattedMessage defaultMessage="privacy policy" id="LIDXd2" />
            </Typography>
          ),
          terms_of_use: (
            <Typography
              color="primary"
              component={Link}
              sx={{
                textDecoration: 'underline'
              }}
              to="/terms-and-conditions"
              variant="body2"
            >
              <FormattedMessage defaultMessage="terms of use" id="bhx5xa" />
            </Typography>
          )
        }}
      />
      {register_disclaimer && (
        <Box gridColumnStart={3} my={2}>
          {parse(register_disclaimer)}
        </Box>
      )}
    </Typography>
  )
}

const ActiveConsentForm = () => {
  const dispatch = useDispatch()
  const consent = useSelector(path(['authentication', 'consent']))

  return (
    <FormControl required>
      <FormControlLabel
        control={
          <Checkbox
            checked={consent}
            color="default"
            onChange={() => dispatch(setConsent(not(consent)))}
            sx={{ color: 'primary.tonal' }}
          />
        }
        label={<Label />}
      />
    </FormControl>
  )
}

export default ActiveConsentForm
