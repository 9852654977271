import { Button, Stack } from '@mui/material'
import { always, any, cond, equals, includes, prop } from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'hooks'

import Icon from 'components/icon/Icon'

const Navigation = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const { activity } = useSelector(prop('destress'))

  if (
    any(equals(true))([
      includes('introduction', pathname),
      equals('/destress/activity', pathname),
      includes('feedback', pathname),
      includes('confirmation', pathname)
    ])
  )
    return null

  const getBackLink = cond([
    [includes('poll'), always('./introduction')],
    [includes('information'), always('./poll')],
    [includes('education'), always('./information')],
    [includes('impact-body'), always('./education')],
    [includes('impact-thoughts'), always('./impact-body')],
    [includes('impact-relationships'), always('./impact-thoughts')],
    [includes('great-work'), always('./impact-relationships')],
    [includes('kindness'), always('./activity')],
    [includes('gratitude'), always('./activity')],
    [includes('breathing'), always('./activity')],
    [includes('keep-rolling'), always(`./activity/${activity}`)],
    [includes('resources'), always('./keep-rolling')],
    [includes('email'), always('./resources')]
  ])

  const getNextLink = cond([
    [includes('poll'), always('./information')],
    [includes('information'), always('./education')],
    [includes('education'), always('./impact-body')],
    [includes('impact-body'), always('./impact-thoughts')],
    [includes('impact-thoughts'), always('./impact-relationships')],
    [includes('impact-relationships'), always('./great-work')],
    [includes('great-work'), always('./activity')],
    [includes('kindness'), always('./keep-rolling')],
    [includes('gratitude'), always('./keep-rolling')],
    [includes('breathing'), always('./keep-rolling')],
    [includes('keep-rolling'), always('./resources')],
    [includes('resources'), always('./email')],
    [includes('email'), always('./feedback')]
  ])

  const getStepName = cond([
    [includes('poll'), always('Poll Question')],
    [includes('information'), always('Destress/Eustress')],
    [includes('education'), always('Stress Education')],
    [includes('impact-body'), always('Impact Body')],
    [includes('impact-thoughts'), always('Impact Thoughts')],
    [includes('impact-relationships'), always('Impact Relationships')],
    [includes('great-work'), always('Affirmation Break: Great Work')],
    [includes('kindness'), always('Kindness')],
    [includes('gratitude'), always('Gratitude')],
    [includes('breathing'), always('Breathing')],
    [includes('keep-rolling'), always('Affirmation Break: Keep Rolling')],
    [includes('resources'), always('Resources')],
    [includes('email'), always('Email Opt In')],
    [includes('feedback'), always('Feedback')],
    [includes('confirmation'), always('Completed')]
  ])

  // Analytics handling for page exit
  const handleNext = () => {
    sendEvent('Interactive Content', {
      module_name: 'Destress',
      path: pathname,
      step_name: getStepName(pathname),
      trigger: 'Exit Page'
    })
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        bgcolor: 'surface.container',
        bottom: 0,
        boxShadow: 3,
        left: 0,
        position: 'fixed',
        py: 2,
        right: 0,
        zIndex: 1
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          px: 2,
          width: 1
        }}
      >
        <Button
          aria-label={formatMessage({
            defaultMessage: 'Back Button',
            id: 'FzTVd+'
          })}
          LinkComponent={Link}
          startIcon={<Icon>arrow_back</Icon>}
          sx={{
            fontWeight: 700
          }}
          to={getBackLink(pathname)}
          variant="text"
        >
          <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
        </Button>
        <Button
          aria-label={formatMessage({
            defaultMessage: 'Next Button',
            id: 'VWBImm'
          })}
          LinkComponent={Link}
          onClick={handleNext}
          to={getNextLink(pathname)}
        >
          <FormattedMessage defaultMessage="Next" id="9+Ddtu" />
        </Button>
      </Stack>
    </Stack>
  )
}

export default Navigation
