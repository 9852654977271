import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { equals, includes, isEmpty, not, or, path, prop } from 'ramda'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { internalUrl, TYPE_SKILL } from 'components/cards/helpers'
import { useSelector } from 'react-redux'
import CardType from './CardType'

const SmallCard = ({ blockTitle = '', card = null, isFeatured = false }) => {
  // Utils
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()
  const { id, name } = useSelector(prop('school'))

  // Variables
  const title = prop('title', card)
  const type = prop('type', card)
  const img = path(['photo', 'thumb_url'], card)
  const videoDescription = prop('video_description', card)
  const campusResource = prop('campus_resource', card)
  const domainId = prop('section_id', card)

  const handleClick = () => {
    if (isFeatured)
      return sendEvent('Featured_Card_Click', {
        'App Type': 'school',
        'Campus Resource': campusResource,
        'Card Id': prop('id', card),
        'Card Name': title,
        'Card Type': type,
        Path: pathname,
        'School Id': id,
        'School Name': name
      })
    if (not(isEmpty(blockTitle)))
      return sendEvent('block_interactions', {
        block_title: blockTitle,
        click_element: 'Link',
        element_title: title,
        path: pathname
      })
  }

  // Depending on Student or Staff/Fac
  const domainColor = () => {
    if (or(equals(1, domainId), equals(14, domainId))) return '#00866C'
    if (or(equals(2, domainId), equals(12, domainId))) return '#002E32'
    if (or(equals(3, domainId), equals(13, domainId))) return '#1D1528'
  }

  return (
    <Card
      sx={{
        backgroundColor: includes('Self Check', blockTitle)
          ? 'surface.high'
          : 'surface.container',
        maxWidth: includes('Self Check', blockTitle) ? 268 : 320,
        minWidth: 295,
        width: {
          mobile: includes('Self Check', blockTitle) ? 268 : 320,
          mointor: includes('Self Check', blockTitle) ? 268 : 320
        }
      }}
    >
      <CardActionArea
        LinkComponent={Link}
        onClick={handleClick}
        sx={{ p: 1 }}
        to={internalUrl(card)}
      >
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: '78px 1fr'
          }}
        >
          <Box
            sx={{
              backgroundColor: equals(TYPE_SKILL, type)
                ? domainColor()
                : 'transparent',
              backgroundImage: `url(${img})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              borderRadius: 3,
              height: 78,
              width: 78
            }}
          />
          <Stack spacing={1}>
            <CardType
              campusResource={campusResource}
              type={type}
              videoDescription={videoDescription}
            />
            <Typography variant="h4">{title}</Typography>
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  )
}

SmallCard.propTypes = {
  blockTitle: PropTypes.string,
  card: PropTypes.shape({}).isRequired,
  isFeatured: PropTypes.bool
}

export default SmallCard
