import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  mode: 'light'
}

const themeSlice = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    setMode: (state, { payload }) => assoc('mode', payload, state)
  }
})

export const { setMode } = themeSlice.actions

export default themeSlice.reducer
