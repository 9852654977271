import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  contentType: [],
  isMenuOpen: false,
  sort: 'Most Relevant',
  tempContentType: [],
  tempTopic: [],
  topic: []
}

const filterSLice = createSlice({
  initialState,
  name: 'filter',
  reducers: {
    resetFilters: () => initialState,
    setContentType: (state, { payload }) =>
      assoc('contentType', payload, state),
    setIsMenuOpen: (state, { payload }) => assoc('isMenuOpen', payload, state),
    setSort: (state, { payload }) => assoc('sort', payload, state),
    setTempContent: (state, { payload }) =>
      assoc('tempContentType', payload, state),
    setTempTopic: (state, { payload }) => assoc('tempTopic', payload, state),
    setTopic: (state, { payload }) => assoc('topic', payload, state)
  }
})

export const {
  resetFilters,
  setContentType,
  setIsMenuOpen,
  setSort,
  setTempContent,
  setTempTopic,
  setTopic
} = filterSLice.actions

export default filterSLice.reducer
