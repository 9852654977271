import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'

const BlockWrapper = ({ title = null, children }) => (
  <Stack alignItems="center" spacing={3}>
    {title && (
      <Typography
        component="h2"
        sx={{
          textAlign: 'center'
        }}
        variant="h1"
      >
        {title}
      </Typography>
    )}
    {children}
  </Stack>
)

BlockWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
  title: PropTypes.node
}

export default BlockWrapper
