import { createSlice } from '@reduxjs/toolkit'
import { assoc, assocPath, prop } from 'ramda'

const initialState = {
  activity: '',
  actsOfKindness: [],
  emailOptIn: false,
  gratitudes: {
    1: '',
    2: '',
    3: '',
  },
  resources: [],
  selectedImpacts: null,
}

const destressSlice = createSlice({
  initialState,
  name: 'destress',
  reducers: {
    setActivity: (state, { payload }) => assoc('activity', payload, state),
    setActsOfKindness: (state, { payload }) =>
      assoc('actsOfKindness', payload, state),
    setEmailOptIn: (state, { payload }) => assoc('emailOptIn', payload, state),
    setGratitude: (state, { payload }) =>
      assocPath(
        ['gratitudes', prop('id', payload)],
        prop('val', payload),
        state
      ),
    setResources: (state, { payload }) => assoc('resources', payload, state),
    setSelectedImpacts: (state, { payload }) =>
      assoc('selectedImpacts', payload, state),
  },
})

export const {
  setActivity,
  setActsOfKindness,
  setEmailOptIn,
  setGratitude,
  setResources,
  setSelectedImpacts,
} = destressSlice.actions

export default destressSlice.reducer
