/* eslint-disable quotes */
import { FormattedMessage } from 'react-intl'
import { not } from 'ramda'
import { Button, Collapse, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useAnalytics } from 'hooks'
import { useUpdateOnboardedUserMutation } from 'api/userApi'
import { useState } from 'react'
import VideoImage from './VideoImage'
import Icon from 'components/icon/Icon'

const transcript = [
  'YOU',
  'THIS IS YOUR TIME',
  'FOR GROWTH',
  'DISCOVERY',
  'AND EXPLORATION',
  'This is your moment',
  'To design your ideal college experience',
  'and life.',
  'YOU is a website designed to help you do just that.',
  'Navigate life',
  'and school',
  'on your terms.',
  'A tool to help YOU...',
  'Succeed',
  'Thrive',
  '& Matter',
  "Whatever you're balancing",
  'YOU has you covered.',
  'Explore your priorities',
  'Reach your goals',
  'Get advice from experts',
  'And support from your school',
  'Reflect with YOU',
  "When you're ready, take action",
  'and keep discovering the best version of YOU.',
  'YOU'
]

const Intro = () => {
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()

  const [updateUser] = useUpdateOnboardedUserMutation()

  const handleSkipAll = () => updateUser(new FormData())

  const [isOpen, setIsOpen] = useState(false)

  const handleKeen = (element_title, click_element) =>
    sendEvent('Onboarding Video', {
      click_element: click_element,
      element_title: element_title
    })

  return (
    <Stack alignItems="center" spacing={3} sx={{ mt: 3 }}>
      <Typography
        sx={{ alignSelf: 'center', maxWidth: 515 }}
        textAlign="center"
        variant="subtitle1"
      >
        <FormattedMessage
          defaultMessage="Well-being is personal. We get it. Check out this video on how YOU can support your unique experience."
          id="gbkCX1"
        />
      </Typography>
      <Stack spacing={2} sx={{ alignSelf: 'center', maxWidth: 700 }}>
        <VideoImage
          ariaLabel="Onboarding video, to play press the space bar, full video transcript can be found below"
          handleKeen={handleKeen}
          videoUrl="/videos/onboarding.mp4?autoplay=0"
        />
        <Button
          aria-label={
            isOpen
              ? 'Video Transcript, expanded, button'
              : 'Video Transcript, collapsed, button'
          }
          endIcon={
            isOpen ? (
              <Icon>keyboard_arrow_up</Icon>
            ) : (
              <Icon>keyboard_arrow_down</Icon>
            )
          }
          fullWidth
          onClick={() => setIsOpen(not(isOpen))}
          variant="text"
        >
          <FormattedMessage
            defaultMessage="View Audio Transcript"
            id="BPWeoX"
          />
        </Button>
        <Collapse in={isOpen}>
          {transcript.map((str, idx) => (
            <Typography key={idx} variant="body1">
              {str}
            </Typography>
          ))}
        </Collapse>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center'
        }}
      >
        <Button
          onClick={() => {
            sendEvent('onboarding', {
              answers: 'intro',
              question: {
                id: 0,
                name: 'intro'
              },
              question_name: 'INTRO_STEP',
              skip: false
            })
            navigate('/onboarding/interests')
          }}
        >
          <FormattedMessage defaultMessage="Get Started" id="9QXm1E" />
        </Button>

        <Button
          LinkComponent={Link}
          onClick={handleSkipAll}
          to="/home"
          variant="text"
        >
          <FormattedMessage defaultMessage="Skip Customization" id="RSh9DE" />
        </Button>
      </Stack>
    </Stack>
  )
}

export default Intro
