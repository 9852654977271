import { FormattedMessage } from 'react-intl'
import { prop, gt, length } from 'ramda'
import { useGetCampusGoalsQuery } from 'api/goalsApi'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useSelector } from 'react-redux'
import GoalCard from './GoalCard'
import CardLoader from 'components/cards/CardLoader'

const Goals = () => {
  const { createdGoals } = useSelector(prop('onboarding'))
  const { data: goals = [], isFetching = true } = useGetCampusGoalsQuery()

  return (
    <Stack alignItems="center" spacing={2} sx={{ mt: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridGap: 24,
          gridTemplateColumns: {
            mobile: 'repeat(1, 1fr)',
            // eslint-disable-next-line sort-keys
            tablet: 'repeat(2, 1fr)',
            // eslint-disable-next-line sort-keys
            monitor: 'repeat(4, 1fr)'
          },
          marginBottom: 5
        }}
      >
        {isFetching ? (
          <>
            <CardLoader />
            <CardLoader />
            <CardLoader />
            <CardLoader />
          </>
        ) : (
          goals.map(goal => <GoalCard goal={goal} key={prop('id', goal)} />)
        )}
      </Box>

      {gt(length(createdGoals), 0) && (
        <Card
          sx={{
            maxWidth: 498
          }}
          variant="fullWidth"
        >
          <CardContent>
            <Typography textAlign="center" variant="subtitle1">
              <FormattedMessage defaultMessage="Way to go!" id="JF/bbb" />
            </Typography>
            <Typography textAlign="center" variant="subtitle1">
              <FormattedMessage
                defaultMessage="If you write down a goal you are 5 times more likely to achieve it."
                id="g+gmgK"
              />
            </Typography>
          </CardContent>
        </Card>
      )}
    </Stack>
  )
}

export default Goals
