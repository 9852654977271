import { useMemo } from 'react'
import steps from './Steps'
import { not, or, path, propEq, reject } from 'ramda'
import { useSelector } from 'react-redux'

const useGetSteps = () => {
  const isSecondaryUser = useSelector(path(['user', 'is_secondary_user']))
  const enableGoals = useSelector(path(['school', 'enable_preset_goals']))

  const stepsToRender = useMemo(() => {
    let copy = [...steps]
    copy = reject(propEq('wellpower', 'name'), copy)
    if (or(isSecondaryUser, not(enableGoals)))
      copy = reject(propEq('goals', 'name'), copy)
    return copy
  }, [isSecondaryUser, enableGoals])

  return stepsToRender
}

export default useGetSteps
