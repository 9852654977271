import {
  Button,
  Container,
  Grow,
  LinearProgress,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import {
  useGetRealityScoresQuery,
  useGetSelfCheckQuizDataQuery,
  useUpdateScoreMutation
} from 'api/statsApi'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  add,
  divide,
  equals,
  find,
  inc,
  isNil,
  multiply,
  not,
  or,
  prop,
  reduce
} from 'ramda'
import { Fragment, useMemo, useState } from 'react'
import { useAnalytics } from 'hooks'
import { useLocation } from 'react-router-dom'

const Poll = () => {
  const { sendEvent } = useAnalytics()
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()

  const [selected, setSelected] = useState(null)
  const isSelected = equals(selected)

  // Fetching data
  const { data: scores, isFetching: isFetchingScores } =
    useGetRealityScoresQuery(324)
  const { data: quiz, isFetching: isFetchingQuizData } =
    useGetSelfCheckQuizDataQuery()
  const [updateScore] = useUpdateScoreMutation()

  // get sum of total answered for percentage
  const totalAnswered = useMemo(() => {
    if (isFetchingScores) return 0
    return reduce(add, 1, Object.values(scores))
  }, [scores, isFetchingScores])

  // getting array for poll
  const poll = useMemo(() => {
    if (isFetchingQuizData) return []
    const thrive = find(({ title }) => title.includes('Thrive'), quiz)
    return prop(
      'answers',
      find(
        ({ id }) => or(equals(324, id), equals(325, id)),
        prop('questions', thrive)
      )
    )
  }, [quiz, isFetchingQuizData])

  // get percentage of the progress bar
  // if selected increse the answer score by 1
  // calculate and return percentage of total
  const getPercentage = score => {
    const answer = isSelected(score)
      ? inc(prop(score, scores))
      : prop(score, scores)
    return Math.ceil(multiply(divide(answer, totalAnswered), 100))
  }

  const handleClick = async (score, answer) => {
    if (not(isNil(selected))) return
    try {
      setSelected(score)
      const body = new FormData()
      body.append('value', score)
      await updateScore({ data: body, id: 324 })
      sendEvent('Interactive Content', {
        module_name: 'Destress',
        // page_interaction: not(isNil(selected)),
        path: pathname,
        poll_answer: answer,
        step_name: 'Poll Question',
        trigger: 'Click'
      })
    } catch (err) {
      console.error('Error updating destress poll', err)
    }
  }

  return (
    // eslint-disable-next-line sort-keys
    <Container sx={{ mt: { mobile: 2.5, laptop: 6 } }}>
      <Stack alignItems="center" spacing={3}>
        <Stack alignItems="center" spacing={3}>
          <Typography
            component="h1"
            sx={{ maxWidth: 730, textAlign: 'center' }}
            variant="h2"
          >
            <FormattedMessage
              defaultMessage="In the past month, how would you categorize your stress level?"
              id="OPeAnM"
            />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="Select an answer below to see what others said."
              id="hq+I8z"
            />
          </Typography>
        </Stack>
        <Stack spacing={2}>
          {or(isFetchingQuizData, isFetchingScores) && (
            <>
              <Skeleton
                height={48}
                sx={{ borderRadius: 2 }}
                variant="rectangular"
                width="100%"
              />
              <Skeleton
                height={48}
                sx={{ borderRadius: 2 }}
                variant="rectangular"
                width="100%"
              />
              <Skeleton
                height={48}
                sx={{ borderRadius: 2 }}
                variant="rectangular"
                width="100%"
              />
              <Skeleton
                height={48}
                sx={{ borderRadius: 2 }}
                variant="rectangular"
                width="100%"
              />
              <Skeleton
                height={48}
                sx={{ borderRadius: 2 }}
                variant="rectangular"
                width="100%"
              />
            </>
          )}
          {poll.map(({ answer, id, score }) => (
            <Fragment key={id}>
              <Stack
                aria-label={formatMessage(
                  {
                    defaultMessage: '{answer}, {selected}{percent}',
                    id: 'fY9Tvh'
                  },
                  {
                    answer,
                    percent: not(isNil(selected))
                      ? `${getPercentage(score)}% of students answered ${
                          isSelected(score)
                            ? 'similar to you'
                            : 'differently than you'
                        }`
                      : '',
                    selected: not(isNil(selected))
                      ? ''
                      : 'unselected, interact to see what others answered'
                  }
                )}
                component={Button}
                fullWidth
                onClick={() => handleClick(score, answer)}
                selected={isSelected(score)}
                spacing={1}
                variant="poll"
              >
                {answer}
              </Stack>
              <Grow
                in={not(isNil(selected))}
                style={{
                  display: selected ? 'flex' : 'none',
                  height: selected ? 'unset' : 0
                }}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  sx={{ width: 1 }}
                >
                  <LinearProgress
                    aria-hidden="true"
                    sx={{ width: 1 }}
                    value={getPercentage(score)}
                    variant="determinate"
                  />
                  <Typography variant="subtitle1" whiteSpace="nowrap">
                    {getPercentage(score)}%
                  </Typography>
                </Stack>
              </Grow>
            </Fragment>
          ))}
        </Stack>
      </Stack>
    </Container>
  )
}

export default Poll
