/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { Container, Stack, Typography } from '@mui/material'

import Topics from 'components/topics/Topics'
import AllTopicsBlock from './components/AllTopicsBlock'
import ExploreBlockWrapper from './components/ExploreBlockWrapper'
import TrendingBlock from './components/TrendingBlock'
import ECRM from './components/ECRM'
import Icon from 'components/icon/Icon'
import SearchInput from 'pages/search/components/SearchInput'

const Explore = () => (
  <Container maxWidth="monitor">
    <Stack
      spacing={6}
      sx={{
        mx: 'auto',
        my: 0,
        pb: 5
      }}
    >
      <Stack
        alignItems="center"
        spacing={{ mobile: 4, laptop: 8 }}
        sx={{
          alignSelf: 'center',
          maxWidth: 528,
          pb: { mobile: 5, laptop: 11 },
          width: 1
        }}
      >
        <Typography variant="h1">Let&apos;s Explore</Typography>
        <SearchInput />
      </Stack>
      <ExploreBlockWrapper
        icon={<Icon fill>bolt</Icon>}
        title={
          <FormattedMessage defaultMessage="Featured Topics" id="VCoOFY" />
        }
      >
        <Topics />
      </ExploreBlockWrapper>

      <ExploreBlockWrapper
        icon={<Icon fill>psychiatry</Icon>}
        title={
          <FormattedMessage
            defaultMessage="All Well-Being Topics"
            id="MXtbhk"
          />
        }
      >
        <AllTopicsBlock />
      </ExploreBlockWrapper>

      <ECRM />

      <ExploreBlockWrapper
        icon={<Icon>trending_up</Icon>}
        title={<FormattedMessage defaultMessage="New & Trending" id="/CJs/f" />}
      >
        <TrendingBlock />
      </ExploreBlockWrapper>
    </Stack>
  </Container>
)

export default Explore
