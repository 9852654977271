export const MuiAutocomplete = palette => ({
  styleOverrides: {
    clearIndicator: {
      color: palette.surface.onSurface
    },
    popupIndicator: {
      color: palette.surface.onSurface
    }
  }
})
