export const MuiDrawer = () => ({
  styleOverrides: {
    docked: {
      '@media(min-width: 1024px)': {
        width: '18.75rem'
      },
      width: 300
    },
    paper: {
      '@media(min-width: 1024px)': {
        width: '18.75rem'
      },
      borderRadius: 0,
      borderRight: 'none',
      width: 300,
      zIndex: 4
    }
  }
})
