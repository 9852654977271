import { FormattedMessage } from 'react-intl'
import { Stack, Tooltip, Typography } from '@mui/material'
import Icon from 'components/icon/Icon'

const WhyWeAskForInfoDisclaimer = () => (
  <Tooltip
    arrow
    describeChild
    placement="top"
    title={
      <Typography variant="subtitle1">
        <FormattedMessage
          defaultMessage="Anything you share will only be used to customize content and provide personalized recommendations. All information you share is confidential."
          id="odtTWG"
        />
      </Typography>
    }
  >
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        alignSelf: 'flex-start',
        cursor: 'pointer',
        maxWidth: 300,
        px: 1
      }}
      tabIndex={0}
    >
      <Icon>help</Icon>
      <Typography variant="body1">
        <FormattedMessage
          defaultMessage="How is my information used?"
          id="/TZ9DQ"
        />
      </Typography>
    </Stack>
  </Tooltip>
)

export default WhyWeAskForInfoDisclaimer
