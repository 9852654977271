export const MuiAccordionSummary = () => ({
  styleOverrides: {
    root: {
      '&.Mui-expanded': {
        height: 48,
        minHeight: 0
      },
      borderRadius: 100
    }
  }
})
