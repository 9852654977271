import { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  path,
  equals,
  prop,
  reject,
  isEmpty,
  isNil,
  compose,
  nthArg,
  map,
  assoc
} from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import { Box, Divider, Pagination, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import Icon from 'components/icon/Icon'
import FilterMenu from 'components/filter/FilterMenu'
import useBuildFilters from 'components/filter/useBuildFilters'

const ExploreResourcesBlock = ({ subdomain }) => {
  const { domain } = useSelector(prop('school'))
  const scrollRef = useRef(null)
  const { contentType } = useSelector(prop('filter'))
  const { contentBase, sortValue } = useBuildFilters()

  const [page, setPage] = useState(1)

  const contentCheck = useMemo(() => {
    const arr = contentBase.filter(({ name }) =>
      contentType.some(type => equals(type, name))
    )
    const paramArr = map(prop('value'), arr)
    return paramArr
  }, [contentBase, contentType])

  const cardParams = useMemo(() => {
    const base = {
      'filter[subdomains]': path(['subdomain', 'id'], subdomain),
      'filter[types]': reject(equals(0), contentCheck),
      org_domain: domain,
      page,
      perPage: 9,
      'sort[method]': sortValue()
    }

    // if campus resource is selected
    // then provide the filter
    return contentCheck.includes(0)
      ? assoc('filter[campus_resource]', 1, base)
      : base
  }, [domain, subdomain, page, contentCheck, sortValue])

  const { data } = useGetCardsQuery(reject(isEmpty, cardParams), {
    refetchOnMountOrArgChange: true
  })

  const cards = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack
      sx={{
        alignItems: 'center',
        // eslint-disable-next-line sort-keys
        mt: { mobile: 3, tablet: 4 }
      }}
    >
      <Box
        ref={scrollRef}
        sx={{
          width: 1
        }}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Icon>explore</Icon>
          <Typography variant="h2">
            <FormattedMessage
              defaultMessage="Explore {subdomain}"
              id="hH8CUp"
              values={{
                subdomain: path(['subdomain', 'frontend_name'], subdomain)
              }}
            />
          </Typography>
        </Stack>
        <Divider />
      </Box>
      <FilterMenu hasContentFilter hasSortMenu />
      <Stack alignItems="center" spacing={3}>
        <CardGridWrapper>
          {cards.map(card => (
            <CardHandler card={card} key={prop('id', card)} />
          ))}
        </CardGridWrapper>
        <Pagination
          count={path(['meta', 'lastPage'], data)}
          onChange={compose(handleNav, setPage, nthArg(1))}
          page={page}
        />
      </Stack>
    </Stack>
  )
}

ExploreResourcesBlock.propTypes = {
  subdomain: PropTypes.shape({}).isRequired
}

export default ExploreResourcesBlock
