import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { and, defaultTo, isEmpty, isNil, not, prop } from 'ramda'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

const LogInButton = ({ children, icon = null }) => {
  const { domain, sso_link_two, sso_link } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const getSignInUrl = () => {
    const ssoEnabled = defaultTo(
      false,
      and(not(isNil(sso_link)), not(isEmpty(sso_link)))
    )

    const ssoMultipleIdps = not(isNil(sso_link_two))
    if (and(ssoEnabled, not(ssoMultipleIdps))) return sso_link

    return '/sign-in'
  }

  return (
    <Button
      disabled={isNil(domain)}
      LinkComponent={Link}
      onClick={() =>
        sendEvent('block_interactions', {
          block_title: 'Logged Out Header',
          click_element: 'Link',
          element_title: 'Log In',
          path: window.location.pathname
        })
      }
      startIcon={icon}
      to={getSignInUrl()}
      variant="outlined"
    >
      {children}
    </Button>
  )
}

LogInButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node
}

export default LogInButton
