/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { filter, not, prop, toLower } from 'ramda'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useGetScoresQuery, useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { useMemo } from 'react'
import DomainScore from 'pages/dashboard/components/DomainScore'
import Icon from 'components/icon/Icon'

const DomainSummary = ({ name, title, domain }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('tablet'))

  const { data: scores = [] } = useGetScoresQuery()

  const { data: quizData = [], isFetching } = useGetSelfCheckQuizDataQuery()

  const currentQuiz = useMemo(() => {
    if (isFetching) return {}
    return quizData.find(({ title }) => toLower(title).includes(name))
  }, [isFetching, quizData, name])

  const quizResults = useMemo(() => {
    if (isFetching) return []
    return filter(
      result => not(prop('displayAlert', result)),
      prop('results', currentQuiz)
    )
  }, [currentQuiz, isFetching])

  return (
    <Box
      sx={{
        // eslint-disable-next-line sort-keys
        px: { mobile: 2, laptop: 4 }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gridGap: 4,
          my: 1.5
        }}
      >
        <Icon>done_all</Icon>
        <Typography variant="overline">
          <FormattedMessage defaultMessage="your self check" id="iyUt9c" />
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: { mobile: 'flex-start', tablet: 'center' },
          display: 'flex',
          flexDirection: { mobile: 'column', tablet: 'row' },
          mb: 2
        }}
      >
        <Typography
          color="primary.main"
          sx={{
            textTransform: 'capitalize'
          }}
          variant="h4"
        >
          {domain}
        </Typography>
        <Typography
          color="secondary.dark"
          sx={{
            textTransform: 'capitalize'
          }}
        >
          {!isMobile && <>&nbsp;/</>} {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridGap: 16,
          gridTemplateColumns: { mobile: '1fr', tablet: 'repeat(2, 1fr)' }
        }}
      >
        {quizResults.map(subdomain => (
          <DomainScore
            key={prop('title', subdomain)}
            scores={scores}
            subdomain={subdomain}
          />
        ))}
      </Box>
    </Box>
  )
}

DomainSummary.propTypes = {
  domain: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default DomainSummary
