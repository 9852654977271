import { FormattedMessage } from 'react-intl'
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const AddPrioritiesCard = ({ toggleMenu }) => (
  <Card
    sx={{
      alignItems: 'center',
      bgcolor: 'surface.highest',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      height: 225,
      position: 'relative',
      transition: 'all .3s ease-in-out',
      width: 192,
      zIndex: 0
    }}
  >
    <CardActionArea onClick={() => toggleMenu()} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            backgroundImage: 'url(/vectors/you-logo.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 90,
            width: 102
          }}
        />
        <Typography textAlign="center" variant="body1">
          <FormattedMessage defaultMessage="Priorities change." id="GpXcE/" />
        </Typography>
      </Stack>
    </CardActionArea>
  </Card>
)

AddPrioritiesCard.propTypes = {
  toggleMenu: PropTypes.func.isRequired
}

export default AddPrioritiesCard
