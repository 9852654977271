export const MuiSelect = palette => ({
  styleOverrides: {
    icon: {
      color: palette.surface.onSurface
    },
    select: {
      '&[aria-expanded="true"]': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: 'all .3s ease-in-out'
      },
      ':hover': {
        backgroundColor: palette.surface.low
      }
    }
  }
})
