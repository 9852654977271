/* eslint-disable sort-keys */
import { useParams } from 'react-router-dom'
import {
  any,
  equals,
  filter,
  isEmpty,
  isNil,
  not,
  or,
  prop,
  propEq,
  toLower
} from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { Box, Skeleton, Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Quiz from './components/Quiz'
import FlowHeader from 'components/header/FlowHeader'
import Navigation from './components/Navigation'

const QuizPage = () => {
  const { quiz } = useParams()
  const { data, isFetching = true } = useGetSelfCheckQuizDataQuery()
  const { userDomains } = useSelector(prop('user'))

  const [question, setQuestion] = useState({})

  const currentQuiz = useMemo(() => {
    if (isNil(data)) return {}
    return data.find(({ title }) => toLower(title).includes(quiz))
  }, [data, quiz])

  // Hiding questions where this prop is true
  // https://gitlab.com/grit-digital-health/YOU-Frontend/-/issues/2236
  const questions = useMemo(() => {
    if (isEmpty(currentQuiz)) return []
    return filter(
      propEq(or(false, null), 'hide_from_reality_check'),
      prop('questions', currentQuiz)
    )
  }, [currentQuiz])

  useEffect(() => {
    if (not(isEmpty(questions))) setQuestion(prop(0, questions))
  }, [questions])

  if (
    any(equals(true))([
      isNil(data),
      isFetching,
      isEmpty(questions),
      isEmpty(question),
      isNil(userDomains)
    ])
  )
    return (
      <Box
        sx={{
          height: 'calc(100vh - 168px)',
          m: '48px auto',
          maxWidth: 1024,
          py: 6
        }}
      >
        <Stack alignItems="center" spacing={1} sx={{ width: 1 }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton
            height={16}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="100%"
          />
          <Skeleton height={63} variant="text" width="100%" />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton height={63} variant="text" width="75%" />
        </Stack>
      </Box>
    )

  return (
    <Box sx={{ height: 'calc(100vh - 168px)' }}>
      <FlowHeader />
      <Quiz
        currentQuiz={currentQuiz}
        question={question}
        questions={questions}
        setQuestion={setQuestion}
      />
      <Navigation
        question={question}
        questions={questions}
        setQuestion={setQuestion}
      />
    </Box>
  )
}

export default QuizPage
