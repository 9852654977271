import { Button, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const SkipButton = () => {
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('laptop'))

  return (
    <Button
      href="#main"
      sx={{
        '&:focus': {
          left: 16
        },
        left: '-100%',
        position: 'absolute',
        top: isDesktop ? 0 : 8,
        transition: 'left 0.5s ease-out',
        zIndex: 5
      }}
    >
      <FormattedMessage defaultMessage="Skip to Main Content" id="6LeQ+9" />
    </Button>
  )
}

export default SkipButton
