import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material'
import PropTypes from 'prop-types'
import { any, compose, equals, path } from 'ramda'
import { useLocation } from 'react-router'
import { useAnalytics } from 'hooks'
import { useMemo } from 'react'
import useBuildFilters from './useBuildFilters'
import Icon from 'components/icon/Icon'

const SortMenu = ({ active, handleSort }) => {
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const { sortOptions } = useBuildFilters()

  const eventName = useMemo(() => {
    if (
      any(equals(true))([
        pathname.includes('succeed'),
        pathname.includes('thrive'),
        pathname.includes('matter')
      ])
    )
      return 'you_subdomain_filter'
    return 'filter_engagement'
  }, [pathname])

  const handleAnalytics = val => {
    sendEvent(eventName, {
      filter_selection: val,
      page_title: pathname
    })
    handleSort(val)
  }

  return (
    <FormControl fullWidth sx={{ maxWidth: { mobile: '100%', tablet: 235 } }}>
      <InputLabel id="sort">Sort By</InputLabel>
      <Select
        label={`Sort By, selected: ${active}`}
        labelId="sort"
        onChange={compose(handleAnalytics, path(['target', 'value']))}
        renderValue={opt => opt}
        value={active}
      >
        <MenuItem>Sort By</MenuItem>
        {sortOptions.map(opt => (
          <MenuItem key={opt} value={opt}>
            <ListItemText primary={opt} />
            {equals(active, opt) && <Icon>check</Icon>}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SortMenu.propTypes = {
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleSort: PropTypes.func.isRequired
}

export default SortMenu
