import { Button, Slide, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  append,
  compose,
  equals,
  findIndex,
  gt,
  isNil,
  path,
  without
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedImpacts } from './destressSlice'

import body from './body'
import thoughts from './thoughts'
import relationships from './relationships'
import { useLocation } from 'react-router'
import { useAnalytics } from 'hooks'

const Impact = ({ type }) => {
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const dispatch = useDispatch()
  const selectedImpacts = useSelector(path(['destress', 'selectedImpacts']))

  const getData = () => {
    if (equals('body', type)) return body
    if (equals('thoughts', type)) return thoughts
    return relationships
  }

  const handleAnalytics = arr =>
    sendEvent('Interactive Content', {
      module_name: 'Destress',
      // Leaving commented out for a later date if they decide to bring it back
      // https://gitlab.com/grit-digital-health/YOU-Frontend/-/issues/2240
      // page_interaction: not(isEmpty(strArr)),
      path: pathname,
      selection: arr,
      step_name: `Impact ${type.charAt(0).toUpperCase() + type.slice(1)}`,
      trigger: 'Click'
    })

  const handleClick = val => {
    const baseArr = isNil(selectedImpacts) ? [] : selectedImpacts
    const found = findIndex(equals(val), baseArr)
    if (gt(found, -1)) {
      const removed = without([val], baseArr)
      handleAnalytics(removed)
      return dispatch(setSelectedImpacts(removed))
    }
    const added = append(val, baseArr)
    handleAnalytics(added)
    dispatch(setSelectedImpacts(added))
  }

  const isActive = item =>
    selectedImpacts?.some(equals(path(['props', 'defaultMessage'], item)))

  const renderTitle = () => {
    if (equals('body', type))
      return (
        <FormattedMessage
          defaultMessage="First, how does stress impact your body?"
          id="Am7OE4"
        />
      )
    if (equals('thoughts', type))
      return (
        <FormattedMessage
          defaultMessage="Next, how does stress impact your thoughts?"
          id="PhiUlx"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="Finally, how does stress impact your relationships with others?"
        id="mqYlr3"
      />
    )
  }

  return (
    <Stack alignItems="center" spacing={3}>
      <Slide direction="left" in>
        <Stack spacing={3} sx={{ textAlign: 'center' }}>
          <Typography component="h1" sx={{ maxWidth: 764 }} variant="h2">
            {renderTitle()}
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="Select all that apply."
              id="GXt1ln"
            />
          </Typography>
        </Stack>
      </Slide>
      <Slide direction="left" in timeout={500}>
        <Stack
          direction="row"
          spacing={3.5}
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: 707
          }}
          useFlexGap
        >
          {getData().map((item, idx) => (
            <Button
              aria-label={`${
                isActive(item) ? 'selected:' : 'unselected:'
              } ${path(['props', 'defaultMessage'], item)}`}
              key={idx}
              onClick={() =>
                compose(handleClick, path(['props', 'defaultMessage']))(item)
              }
              selected={isActive(item)}
              variant="select"
            >
              {item}
            </Button>
          ))}
        </Stack>
      </Slide>
    </Stack>
  )
}

Impact.propTypes = {
  type: PropTypes.string.isRequired
}

export default Impact
