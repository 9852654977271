export const MuiDialogTitle = () => ({
  styleOverrides: {
    root: {
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 1.5,
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'uppercase'
    }
  }
})
