import PropTypes from 'prop-types'
import {
  always,
  assoc,
  cond,
  equals,
  includes,
  map,
  prop,
  propEq,
  T,
  when
} from 'ramda'
import { useUpdateUserQuestionsMutation } from 'api/userApi'
import { Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updatePublicUser, updateUserData } from 'store/userSlice'

import Toast from 'components/toast/Toast'
import ButtonSelect from './ButtonSelect'
import YesNoQuestionInput from './YesNoQuestionInput'
import SelectQuestionInput from './SelectQuestionInput'
import PreferredName from './PreferredName'

function ProfileQuestion({ question, userAnswers }) {
  const dispatch = useDispatch()
  const { profile_answers } = useSelector(prop('user'))

  const [updateUser] = useUpdateUserQuestionsMutation()

  const handleAnswer = answer => {
    const id = prop('profile_question_id', answer)
    const answers = prop('answers', answer)

    if (equals(id, 37)) {
      return dispatch(
        updatePublicUser({
          name: 'preferred_name',
          value: prop(0, answers)
        })
      )
    }
    return dispatch(
      updateUserData({
        name: 'profile_answers',
        value: map(
          when(propEq(id, 'profile_question_id'), assoc('answers', answers))
        )(profile_answers)
      })
    )
  }

  // Using debounce for now
  const handleUpdateAnswer = async answer => {
    try {
      await updateUser(answer)
      handleAnswer(answer)
    } catch (err) {
      console.error('error updating answer & user', err)
    }
  }

  const renderInput = cond([
    [
      includes('multi'),
      always(
        <ButtonSelect
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      includes('single'),
      always(
        <SelectQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('text'),
      always(
        <PreferredName
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('yes-no-button'),
      always(
        <YesNoQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      T,
      always(
        <Toast
          isShown
          message={
            <FormattedMessage
              defaultMessage="could not find an input for this question type"
              id="Y4s/Bo"
            />
          }
          type="error"
        />
      )
    ]
  ])

  // This is here because the "yes no button" control is
  // returning a number of these inputs and you can't style
  // this unless we remove the stack wrapper
  return equals('yes-no-button', prop('control_type', question)) ? (
    renderInput(prop('control_type', question))
  ) : (
    <Stack spacing={4} sx={{ maxWidth: 481, width: 1 }}>
      {renderInput(prop('control_type', question))}
    </Stack>
  )
}

ProfileQuestion.propTypes = {
  question: PropTypes.shape({
    control_type: PropTypes.string
  }).isRequired,
  userAnswers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired
}

export default ProfileQuestion
