export const MuiTabs = () => ({
  styleOverrides: {
    flexContainer: {
      gap: 8,
      justifyContent: 'center'
    },
    indicator: {
      display: 'none'
    }
  }
})
