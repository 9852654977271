import { Button, Stack, Typography } from '@mui/material'
import { isNil, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const Success = () => {
  const { id } = useSelector(prop('school'))

  return (
    <Stack alignItems="center" spacing={8}>
      <Stack alignItems="center" spacing={3}>
        <Typography textAlign="center" variant="h1">
          Thanks For The Feedback!
        </Typography>
        <Typography
          sx={{ maxWidth: 500 }}
          textAlign="center"
          variant="subtitle1"
        >
          We take your opinions seriously and strive every day to make You at
          College a better experience for everyone.
        </Typography>
      </Stack>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        to={isNil(id) ? '/' : '/home'}
      >
        Return to You
      </Button>
    </Stack>
  )
}

export default Success
