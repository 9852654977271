/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Badge, Button, Stack, useMediaQuery } from '@mui/material'
import { add, and, equals, isEmpty, length, not, path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetFilters,
  setContentType,
  setIsMenuOpen,
  setSort,
  setTempContent,
  setTempTopic,
  setTopic
} from './filterSlice'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

import TopicFilter from './TopicFilter'
import ContentFilter from './ContentFilter'
import Icon from 'components/icon/Icon'
import SortMenu from './SortMenu'
import MobileFilter from './MobileFilter'

const FilterMenu = ({
  hasContentFilter = false,
  hasSortMenu = false,
  hasTopicFilter = false,
  isDisabled = false
}) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('laptop'))
  const topic = useSelector(path(['filter', 'topic']))
  const contentType = useSelector(path(['filter', 'contentType']))
  const sort = useSelector(path(['filter', 'sort']))
  const { pathname } = useLocation()

  const isBtnDisabled = and(isEmpty(topic), isEmpty(contentType))

  const handleTopic = arr => dispatch(setTopic(arr))
  const handleContent = arr => dispatch(setContentType(arr))
  const handleSort = val => dispatch(setSort(val))

  // On unmount reset filters
  // this is so it can be reusable across pages
  useEffect(() => {
    return () => {
      if (not(equals(pathname, '/search'))) dispatch(resetFilters())
    }
  }, [dispatch, pathname])

  const handleClear = () => {
    dispatch(setTopic([]))
    dispatch(setContentType([]))
    dispatch(setTempTopic([]))
    dispatch(setTempContent([]))
  }

  return (
    <Stack
      direction={{ mobile: 'column', tablet: 'row' }}
      sx={{
        alignSelf: 'center',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'space-between',
        maxWidth: { mobile: 1008, monitor: '63rem' },
        mb: 4,
        mt: 3,
        px: { mobile: 1, tablet: 0 },
        width: 1
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: { mobile: 'space-between', laptop: 'flex-start' }
        }}
      >
        {isDesktop ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
              width: hasTopicFilter && hasContentFilter ? 515 : 257
            }}
          >
            {hasTopicFilter && (
              <TopicFilter
                active={topic}
                handleFilter={handleTopic}
                isDisabled={isDisabled}
              />
            )}
            {hasContentFilter && (
              <ContentFilter
                active={contentType}
                handleFilter={handleContent}
                isDisabled={isDisabled}
              />
            )}
          </Stack>
        ) : (
          <Button
            onClick={() => dispatch(setIsMenuOpen(true))}
            startIcon={<Icon>filter_list</Icon>}
            sx={{
              minWidth: 123
            }}
            variant="outlined"
          >
            Filter
          </Button>
        )}
        <Button
          disabled={isBtnDisabled}
          onClick={handleClear}
          sx={{
            '.MuiBadge-root': {
              right: -26
            }
          }}
          variant="text"
        >
          Clear Filters
          <Badge
            badgeContent={add(length(contentType), isEmpty(topic) ? 0 : 1)}
            color="primary"
          ></Badge>
        </Button>
      </Stack>
      {hasSortMenu && <SortMenu active={sort} handleSort={handleSort} />}
      <MobileFilter
        hasContentFilter={hasContentFilter}
        hasTopicFilter={hasTopicFilter}
      />
    </Stack>
  )
}

FilterMenu.propTypes = {
  hasContentFilter: PropTypes.bool,
  hasSortMenu: PropTypes.bool,
  hasTopicFilter: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default FilterMenu
