export const MuiMenu = () => ({
  styleOverrides: {
    paper: {
      '& > .MuiList-root': {
        borderTop: '1px solid #000000',
        paddingTop: 0
      },
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  }
})
