/* eslint-disable sort-keys */
import { Button, Container, Slide, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { Link, useLocation } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const emojiButtonStyle = {
  fontSize: 16.667,
  letterSpacing: '0.25px',
  lineHeight: '23.33px',
  py: 1
}

const Feedback = () => {
  const { formatMessage } = useIntl()
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const handleClick = rating => {
    // We want this event for consistent user feedback tracking
    sendEvent('User_feedback', {
      feedback_rating: rating,
      path: pathname
    })
    // We want this event to see if the user is still using the flow
    // and if they are getting past this point
    sendEvent('Interactive Content', {
      feedback_rating: rating,
      module_name: 'Destress',
      path: pathname,
      step_name: 'Feedback',
      trigger: 'Click Button'
    })
  }

  const buttons = [
    {
      icon: <Icon fill>thumb_down</Icon>,
      label: formatMessage({
        defaultMessage: 'negative, unselected, interact to move to next step',
        id: 'sLdi6m'
      }),
      rating: 'Negative',
      subtitle: <FormattedMessage defaultMessage="No" id="oUWADl" />
    },
    {
      icon: <Icon fill>sentiment_neutral</Icon>,
      label: formatMessage({
        defaultMessage: 'neutral, unselected, interact to move to next step',
        id: 'unpHO9'
      }),
      rating: 'Neutral',
      subtitle: <FormattedMessage defaultMessage="Sort of" id="JwRM2A" />
    },
    {
      icon: <Icon fill>thumb_up</Icon>,
      label: formatMessage({
        defaultMessage: 'positive, unselected, interact to move to next step',
        id: '3yTSkO'
      }),
      rating: 'Positive',
      subtitle: <FormattedMessage defaultMessage="Yes" id="a5msuh" />
    }
  ]

  return (
    // eslint-disable-next-line sort-keys
    <Container maxWidth="monitor">
      <Stack alignItems="center" spacing={3}>
        <Slide direction="down" in>
          <Stack alignItems="center" spacing={3}>
            <Typography component="h1" variant="h2">
              <FormattedMessage defaultMessage="Let us know..." id="oqNR6o" />
            </Typography>
            <Typography textAlign="center" variant="subtitle1">
              <FormattedMessage
                defaultMessage="Did you find De-stress 101 helpful?"
                id="Cihd4/"
              />
            </Typography>
          </Stack>
        </Slide>
        <Slide direction="up" in>
          <Stack direction="row" spacing={{ mobile: 3, tablet: 8 }}>
            {buttons.map(({ icon, label, rating, subtitle }) => (
              <Stack alignItems="center" key={rating} spacing={1}>
                <Button
                  aria-label={label}
                  LinkComponent={Link}
                  onClick={() => handleClick(rating)}
                  sx={{ ...emojiButtonStyle }}
                  to="../confirmation"
                  variant="text"
                >
                  {icon}
                </Button>
                <Typography
                  sx={{ display: { mobile: 'none', tablet: 'block' } }}
                  variant="body2"
                >
                  {subtitle}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Slide>
      </Stack>
    </Container>
  )
}

export default Feedback
