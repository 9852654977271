/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { not, isEmpty, gte, length } from 'ramda'
import { useGetGoalsQuery } from 'api/goalsApi'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const stackStyles = {
  alignItems: 'center',
  justifyContent: 'center'
}

const CompletedGoal = () => {
  const { data: goals = [] } = useGetGoalsQuery()

  return (
    <Stack
      spacing={4}
      sx={{
        alignItems: 'center',
        pb: { mobile: 6, laptop: 16 },
        pt: { mobile: 4, laptop: 8 },
        px: { mobile: 2, laptop: 3 },
        textAlign: 'center'
      }}
    >
      <Typography variant="h1">
        <FormattedMessage defaultMessage="Well done!" id="lJKfqg" />
      </Typography>
      <Typography variant="h2">You just completed your goal!</Typography>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/completed-goal.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 248,
          width: 415
        }}
      />
      <Stack spacing={4} sx={{ ...stackStyles }}>
        {not(isEmpty(goals)) && (
          <>
            <Stack>
              <Typography variant="body1">
                Your hard work paid off! Now, on to the next one.
              </Typography>
              <Typography component="span" variant="body1">
                Looks like you have{' '}
                <b>
                  {length(goals)} {gte(length(goals), 2) ? 'goals' : 'goal'} in
                  progress
                </b>
              </Typography>
            </Stack>
            <Button LinkComponent={Link} to="/goals?active=my-goals">
              Check out in progress goals
            </Button>
          </>
        )}
        <Button
          LinkComponent={Link}
          to="/goals?active=explore"
          variant="outlined"
        >
          Set a new goal
        </Button>
      </Stack>
    </Stack>
  )
}

export default CompletedGoal
