import {
  reverse,
  sortBy,
  prop,
  reduce,
  propEq,
  filter,
  isNil,
  append,
  without,
  map,
  not
} from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useUpdateInterestsMutation } from 'api/userApi'
import useAnalytics from 'hooks/useAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'

const SubdomainSelector = () => {
  const dispatch = useDispatch()
  const { sendEvent } = useAnalytics()
  const { interested_subdomains: interestedSubdomains = [], userDomains = [] } =
    useSelector(prop('user'))

  const [updateInterests, { isLoading }] = useUpdateInterestsMutation()

  const subdomains = reduce(
    (subdomains, domain) => subdomains.concat(domain.subdomains),
    [],
    userDomains
  )

  const getSubdomains = id => {
    if (isNil(subdomains)) return []
    return reverse(
      sortBy(prop('sort'))(filter(propEq(id, 'section_id'), subdomains))
    )
  }

  const handleUpdate = interests => {
    dispatch(updateUserData({ name: 'has_viewed_a_priority', value: true }))
    dispatch(
      updateUserData({ name: 'interested_subdomains', value: interests })
    )
  }

  const updatePriorities = priority => {
    const found = interestedSubdomains.find(propEq(prop('id', priority), 'id'))
    if (isNil(found)) {
      sendEvent('block_interactions', {
        block_title: 'My Priorities',
        click_element: 'button',
        element_title: prop('onboarding_title', priority),
        path: window.location.pathname,
        selected: true
      })
      handleUpdate(append(priority, interestedSubdomains))
      updateInterests({
        subdomains: map(prop('id'), append(priority, interestedSubdomains))
      })
      return
    }
    sendEvent('block_interactions', {
      block_title: 'My Priorities',
      click_element: 'button',
      element_title: prop('onboarding_title', priority),
      path: window.location.pathname,
      selected: false
    })
    handleUpdate(without([priority], interestedSubdomains))
    updateInterests({
      subdomains: map(prop('id'), without([priority], interestedSubdomains))
    })
  }

  return (
    <Stack alignItems="center" mt={3}>
      <Stack
        direction="row"
        sx={{
          flexWrap: 'wrap',
          gap: 3.5
        }}
      >
        {userDomains.map(({ id, frontend_name, subtitle }) => (
          <Box key={id}>
            <Stack alignItems="baseline" direction="row" mb={1} spacing={0.5}>
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                tabIndex={0}
                variant="h5"
              >
                {frontend_name} /
              </Typography>
              <Typography textTransform="capitalize" variant="body2">
                {subtitle}
              </Typography>
            </Stack>

            <Stack spacing={2}>
              {getSubdomains(id).map(subdomain => (
                <Button
                  aria-disabled={isLoading ? 'true' : 'false'}
                  aria-label={
                    interestedSubdomains.some(
                      propEq(prop('id', subdomain), 'id')
                    )
                      ? `Selected: ${frontend_name} ${prop('title', subdomain)}`
                      : `Unselected: ${frontend_name} ${prop(
                          'title',
                          subdomain
                        )}`
                  }
                  disabled={isLoading}
                  key={prop('id', subdomain)}
                  onClick={() => not(isLoading) && updatePriorities(subdomain)}
                  selected={interestedSubdomains.some(
                    propEq(prop('id', subdomain), 'id')
                  )}
                  variant="select"
                >
                  {prop('title', subdomain)}
                </Button>
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}

export default SubdomainSelector
