import { Box, Slide, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const GreatWork = () => (
  <Stack>
    <Stack alignItems="center" spacing={3}>
      <Slide direction="down" in timeout={500}>
        <Typography component="h1" variant="h2">
          <FormattedMessage defaultMessage="Great Work!" id="hl2hlF" />
        </Typography>
      </Slide>
      <Slide direction="up" in timeout={500}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ maxWidth: 582, textAlign: 'center' }}
        >
          <Box
            component="img"
            src="/vectors/interactive-content/books.svg"
            sx={{ maxWidth: 124 }}
          />
          <Typography
            sx={{ maxWidth: 325, textTransform: 'none' }}
            variant="subtitle1"
          >
            <FormattedMessage
              defaultMessage="Way to reflect. Now let's tackle that stress head on and find some relief in the here and now."
              id="YczuqK"
            />
          </Typography>
        </Stack>
      </Slide>
    </Stack>
  </Stack>
)

export default GreatWork
