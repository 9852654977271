import { Box, Container, Slide, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const Breathing = () => (
  // eslint-disable-next-line sort-keys
  <Container maxWidth="monitor">
    <Stack alignItems="center" spacing={3}>
      <Slide direction="down" in>
        <Stack alignItems="center" spacing={3}>
          <Stack
            alignItems="center"
            spacing={3}
            sx={{ maxWidth: 808, textAlign: 'center' }}
          >
            <Typography component="h1" variant="h2">
              <FormattedMessage
                defaultMessage="With over 25,000 daily breaths at your disposal, why not spare a few for a little breathing exercise?"
                id="fo7PUg"
              />
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage="Try it out now with this guided video."
                id="gwpAcC"
              />
            </Typography>
          </Stack>
        </Stack>
      </Slide>
      <Slide direction="up" in timeout={500}>
        <Box
          sx={{
            height: 0,
            maxWidth: 700,
            overflow: 'hidden',
            pb: 49,
            position: 'relative',
            width: 1
          }}
        >
          <Box
            allow="autoplay; encrypted-media"
            allowFullScreen
            component="iframe"
            src="https://www.youtube.com/embed/iaQed_Xdyvw"
            sx={{
              border: 'none',
              borderRadius: 4,
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: 1
            }}
            title="Embedded youtube: breathing"
          />
        </Box>
      </Slide>
    </Stack>
  </Container>
)

export default Breathing
