import PropTypes from 'prop-types'
import { compose, assoc, path, prop } from 'ramda'
import { TextField } from '@mui/material'

const Name = ({ goal, setGoal }) => (
  <TextField
    fullWidth
    // update goal title on change
    // get value from e.target.value
    // associate value to goal title in goal object
    // set goal
    onChange={compose(setGoal, assoc('title'), path(['target', 'value']))}
    placeholder="Name your Goal"
    // eslint-disable-next-line sort-keys
    sx={{ width: { mobile: 1, laptop: '50%' } }}
    value={prop('title', goal)}
  />
)

Name.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired
}

export default Name
