/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Stack } from '@mui/material'
import { equals, prop, any, not, isNil, __, sort } from 'ramda'
import { useGetUserDomainsQuery } from 'api/userApi'
import { useDispatch, useSelector } from 'react-redux'
import { setUserDomains } from 'store/userSlice'
import { useLocation } from 'react-router-dom'

import Sidebar from 'components/sidebar/Sidebar'
import Footer from 'components/footer/Footer'
import LoggedOutSidebar from 'components/sidebar/LoggedOutSidebar'
import Header from 'components/header/Header'

const MainWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(prop('user'))

  const { pathname } = useLocation()

  const anyCheck = [
    equals(pathname, '/'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('referral'),
    pathname.includes('redirect'),
    pathname.includes('logout'),
    pathname.includes('profile'),
    pathname.includes('student'),
    equals(pathname, '/resources'),
    pathname.includes('subdomainName'),
    pathname.includes('about'),
    pathname.includes('article'),
    pathname.includes('succeed'),
    pathname.includes('matter'),
    pathname.includes('thrive'),
    pathname.includes('goals'),
    pathname.includes('favorites'),
    pathname.includes('feedback'),
    pathname.includes('destress'),
    pathname.includes('create')
  ]

  const fullWidthCheck = [
    equals(pathname, '/'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('password'),
    pathname.includes('verify'),
    pathname.includes('onboarding'),
    pathname.includes('feedback'),
    pathname.includes('destress'),
    pathname.includes('self-checks/succeed'),
    pathname.includes('self-checks/thrive'),
    pathname.includes('self-checks/matter'),
    pathname.includes('create')
  ]

  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const { data: userDomains } = useGetUserDomainsQuery(__, {
    skip: isNil(id)
  })

  useEffect(() => {
    if (not(isNil(userDomains))) {
      // writing this here to make sure user domains are set in the particular order
      // if the decision to change order is different then just remove this check
      const orderDomains = domains => {
        const order = ['Succeed', 'Thrive', 'Matter']
        return sort(
          (a, b) =>
            order.indexOf(prop('title', a)) - order.indexOf(prop('title', b)),
          domains
        )
      }
      dispatch(setUserDomains(orderDomains(userDomains)))
    }
  }, [dispatch, userDomains])

  if (equals(typeof window, 'undefined')) return null

  return (
    <>
      <Header />
      <Stack direction="row" sx={{ justifyContent: 'space-between', width: 1 }}>
        <Sidebar />
        <LoggedOutSidebar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />
        <Box
          component="article"
          id="main"
          sx={{
            position: 'relative',
            // only in destress/onboarding there should be 24px top
            pt: any(equals(true))(anyCheck) ? 0 : { mobile: 3, laptop: 6 },
            width: {
              mobile: 1,
              laptop: any(equals(true))(fullWidthCheck)
                ? 1
                : 'calc(100% - 300px)',
              monitor: any(equals(true))(fullWidthCheck)
                ? 1
                : 'calc(100% - 18.75rem)'
            }
          }}
        >
          {children}
          <Footer />
        </Box>
      </Stack>
    </>
  )
}

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default MainWrapper
