/* eslint-disable sort-keys */
import {
  path,
  length,
  prop,
  gt,
  isEmpty,
  compose,
  nthArg,
  equals,
  map,
  reject,
  or,
  not,
  isNil,
  assoc
} from 'ramda'
import { Box, Divider, Pagination, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useRef, useState } from 'react'
import { setSearchTerm, setTempSearchTerm } from './searchSlice'
import { useGetCardsQuery } from 'api/cardsApi'

import EmptyState from './components/EmptyState'
import SearchInput from './components/SearchInput'
import useBuildFilters from 'components/filter/useBuildFilters'
import FilterMenu from 'components/filter/FilterMenu'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import CardHandler from 'components/cards/CardHandler'

const Search = () => {
  const dispatch = useDispatch()
  const scrollRef = useRef(null)
  const searchTerm = useSelector(path(['search', 'searchTerm']))
  const { domain } = useSelector(prop('school'))
  const { contentType } = useSelector(prop('filter'))
  const { contentBase, domainId } = useBuildFilters()

  const [page, setPage] = useState(1)

  const contentCheck = useMemo(() => {
    const arr = contentBase.filter(({ name }) =>
      contentType.some(type => equals(type, name))
    )
    const paramArr = map(prop('value'), arr)
    return paramArr
  }, [contentBase, contentType])

  const queryparams = useMemo(() => {
    const base = {
      'filter[domains]': domainId(),
      'filter[types]': reject(equals(0), contentCheck),
      org_domain: domain,
      page,
      perPage: 15,
      search: searchTerm
    }

    // if campus resource is selected
    // then provide the filter
    return contentCheck.includes(0)
      ? assoc('filter[campus_resource]', 1, base)
      : base
  }, [contentCheck, domainId, domain, page, searchTerm])

  const { data } = useGetCardsQuery(queryparams, {
    skip: isEmpty(searchTerm)
  })

  const searchResults = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  // On unmount reset filters
  // this is so it can be reusable across pages
  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''))
      dispatch(setTempSearchTerm(''))
    }
  }, [dispatch])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack
      id="search-page"
      spacing={4}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        pb: 9
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: { mobile: 4, laptop: 8 },
          maxWidth: 528,
          pb: { mobile: 5, laptop: 11 },
          width: 1
        }}
      >
        <Typography mx="auto" variant="h1">
          Search YOU
        </Typography>
        <SearchInput />
      </Box>
      {or(gt(length(searchResults), 0), not(isEmpty(searchTerm))) ? (
        <Stack spacing={4} sx={{ px: { mobile: 4, laptop: 2 }, width: 1 }}>
          <Stack spacing={2}>
            <Typography variant="overline">
              {path(['meta', 'total'], data)} Search Results
            </Typography>
            <Divider />
          </Stack>
          <Stack alignItems="center" ref={scrollRef} spacing={3}>
            <FilterMenu hasContentFilter hasTopicFilter />
            <CardGridWrapper noPadding>
              {searchResults.map(card => (
                <CardHandler card={card} key={prop('id', card)} />
              ))}
            </CardGridWrapper>
            <Pagination
              count={path(['meta', 'lastPage'], data)}
              onChange={compose(handleNav, setPage, nthArg(1))}
              page={page}
            />
          </Stack>
        </Stack>
      ) : (
        <EmptyState />
      )}
    </Stack>
  )
}

export default Search
