import { and, equals, find, isEmpty, isNil, not, prop, reject } from 'ramda'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  TYPE_CAMPUS_GOAL,
  TYPE_LIFE_LESSON,
  TYPE_SKILL,
  TYPE_TIP
} from 'utils/card-functions'

const useBuildFilters = () => {
  const { enable_goals, enable_tips, hide_resources, letters, tips_data } =
    useSelector(prop('school'))
  const { is_secondary_user, userDomains } = useSelector(prop('user'))
  const { sort, topic } = useSelector(prop('filter'))

  const sortOptions = ['Most Relevant', 'Most Recent', 'Most Popular']

  const sortValue = useCallback(() => {
    if (equals('Most Recent', sort)) return 'recency'
    if (equals('Most Popular', sort)) return 'popularity'
    return null
  }, [sort])

  const topicOptions = [
    'Academics & Success',
    'Mental & Physical Health',
    'Purpose & Connections'
  ]

  const contentBase = [
    {
      name: 'Expert Info',
      value: TYPE_LIFE_LESSON
    },
    { name: prop('tips_category_title', tips_data), value: TYPE_TIP },
    { name: 'Goals', value: TYPE_CAMPUS_GOAL },
    { name: `${letters} Resources`, value: 0 },
    { name: 'Skill', value: TYPE_SKILL }
  ]

  const domainId = useCallback(() => {
    const succeed = find(({ title }) => equals('Succeed', title))(userDomains)
    const thrive = find(({ title }) => equals('Thrive', title))(userDomains)
    const matter = find(({ title }) => equals('Matter', title))(userDomains)

    const newArr = topic.map(opt => {
      if (equals('Academics & Success', opt)) return prop('id', succeed)
      if (equals('Mental & Physical Health', opt)) return prop('id', thrive)
      if (equals('Purpose & Connections', opt)) return prop('id', matter)
      return 0
    })

    return isEmpty(topic) ? [0] : newArr
  }, [topic, userDomains])

  const buildFilter = (name, show = true) => {
    if (not(show)) return
    return name
  }

  const contentTypes = [
    buildFilter('Expert Info'),
    buildFilter(prop('tips_category_title', tips_data), enable_tips),
    buildFilter('Goals', and(enable_goals, not(is_secondary_user))),
    buildFilter(`${letters} Resources`, not(hide_resources)),
    buildFilter('Skill')
  ]

  const contentOptions = reject(isNil, contentTypes)

  return {
    contentBase,
    contentOptions,
    domainId,
    sortOptions,
    sortValue,
    topicOptions
  }
}

export default useBuildFilters
