import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { setActivity } from './destressSlice'
import { useDispatch } from 'react-redux'

const cards = [
  {
    link: './breathing',
    subtitle: (
      <FormattedMessage
        defaultMessage="Reduce stress in your body"
        id="tjcAH2"
      />
    ),
    title: <FormattedMessage defaultMessage="Take a Breath" id="se4lzF" />
  },
  {
    link: './gratitude',
    subtitle: (
      <FormattedMessage
        defaultMessage="Reduce stress in your thoughts"
        id="N1wZMf"
      />
    ),
    title: <FormattedMessage defaultMessage="Notice the Good" id="dd39ZE" />
  },
  {
    link: './kindness',
    subtitle: (
      <FormattedMessage
        defaultMessage="Reduce stress in your relationships"
        id="WT5CHf"
      />
    ),
    title: (
      <FormattedMessage defaultMessage="Random Acts of Kindness" id="zXGC1g" />
    )
  }
]

const Activity = () => {
  const dispatch = useDispatch()
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const handleClick = link => {
    dispatch(setActivity(link))
    const trimmed = link.replace('./', '')
    sendEvent('Interactive Content', {
      activity_selection: trimmed.charAt(0).toUpperCase() + trimmed.slice(1),
      module_name: 'Destress',
      path: pathname,
      step_name: 'Activity Selection',
      trigger: 'Click Start Button'
    })
  }

  return (
    <Stack
      alignItems="center"
      spacing={8}
      sx={{
        width: 1
      }}
    >
      <Slide direction="left" in timeout={250}>
        <Stack spacing={3} sx={{ textAlign: 'center' }}>
          <Typography component="h1" variant="h2">
            <FormattedMessage
              defaultMessage="Let's get you on your way to de-stressing."
              id="emlOzS"
            />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="Which activity would be most helpful right now?"
              id="ZZoZeG"
            />
          </Typography>
        </Stack>
      </Slide>
      <Slide direction="left" in timeout={500}>
        <Stack
          direction={{
            mobile: 'column',
            // eslint-disable-next-line sort-keys
            tablet: 'row'
          }}
          spacing={3}
          sx={{
            alignItems: { mobile: 'center', tablet: 'stretch' },
            justifyContent: 'center',
            width: 1
          }}
        >
          {cards.map(({ link, subtitle, title }) => (
            <Card key={link} sx={{ height: 'auto', maxWidth: 273, width: 1 }}>
              <CardContent sx={{ height: '100%' }}>
                <Stack
                  spacing={3}
                  sx={{
                    height: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <Stack alignItems="center" spacing={3}>
                    <Typography textAlign="center" variant="h4">
                      {title}
                    </Typography>
                    <Typography textAlign="center" variant="subtitle2">
                      {subtitle}
                    </Typography>
                  </Stack>
                  <CardActionArea sx={{ justifySelf: 'flex-end' }}>
                    <Button
                      aria-label="Start, link, interact with this link to be taken to the activity"
                      fullWidth
                      LinkComponent={Link}
                      onClick={() => handleClick(link)}
                      to={link}
                    >
                      <FormattedMessage defaultMessage="start" id="zTylMM" />
                    </Button>
                  </CardActionArea>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Slide>
    </Stack>
  )
}

export default Activity
