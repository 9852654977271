import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { TYPE_STORY } from 'utils/card-functions'
import { equals, prop } from 'ramda'
import { Stack, Typography } from '@mui/material'
import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'

const RecommendedCards = ({ recommendedCards, type = null, blockTitle }) => (
  <Stack
    alignItems="center"
    spacing={3}
    sx={{
      pb: 3
    }}
  >
    <Typography component="span" variant="h4">
      {equals(type, TYPE_STORY) ? (
        <FormattedMessage defaultMessage="Supporting Resources" id="3kJcZk" />
      ) : (
        <FormattedMessage defaultMessage="Recommended for You" id="41sr4c" />
      )}
    </Typography>
    <CardGridWrapper>
      {recommendedCards.map(card => (
        <CardHandler
          blockTitle={blockTitle}
          card={card}
          key={prop('id', card)}
        />
      ))}
    </CardGridWrapper>
  </Stack>
)

RecommendedCards.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  recommendedCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default RecommendedCards
