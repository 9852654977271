import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import CardHandler from 'components/cards/CardHandler'

const PickUpWhereYouLeftOffItem = ({ card = null, subtitle }) => (
  <Stack
    spacing={2}
    sx={{
      maxWidth: { mobile: 320, monitor: '20rem' },
      width: 1
    }}
  >
    <Stack alignItems="center">
      <Typography
        component="span"
        textAlign="center"
        textTransform="capitalize"
        variant="h4"
      >
        {subtitle}
      </Typography>
    </Stack>
    <CardHandler blockTitle="Pick Up Where You Left Off Block" card={card} />
  </Stack>
)

PickUpWhereYouLeftOffItem.propTypes = {
  card: PropTypes.shape({}),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default PickUpWhereYouLeftOffItem
