import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardMedia,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { inc, length, not, path } from 'ramda'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'hooks'
import Icon from 'components/icon/Icon'

const UnsetGoalContent = ({
  id,
  img = null,
  steps = [],
  title,
  useImagePath = false
}) => {
  const cloudFront = useSelector(path(['school', 'cloudfront_domain']))
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  const { sendEvent } = useAnalytics()

  const handleClick = () => {
    if (pathname.includes('goals')) {
      sendEvent('block_interactions', {
        block_title: 'Goals Explore',
        click_element: 'Link',
        element_title: title,
        path: pathname
      })
    }
  }

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          alignItems: 'center',
          height: 214,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 1
        }}
      >
        <Accordion
          elevation={0}
          expanded={isOpen}
          onChange={() => setIsOpen(not(isOpen))}
          sx={{
            backgroundColor: 'card.background',
            height: isOpen ? 1 : 40,
            position: 'absolute',
            transition: 'all 0.3s, transform 0.3s',
            width: 1,
            zIndex: 1
          }}
        >
          <AccordionSummary
            aria-label="Recommended steps"
            component={Button}
            expandIcon={<Icon>keyboard_arrow_down</Icon>}
            fullWidth
            variant="text"
          >
            {length(steps)} Recommended Steps
          </AccordionSummary>
          <AccordionDetails
            sx={{
              height: 174,
              overflowY: 'auto',
              width: 268
            }}
          >
            <Stack spacing={2}>
              {steps.map((step, idx) => (
                <Stack key={idx}>
                  <Typography variant="subtitle2">Step {inc(idx)}</Typography>
                  <Typography variant="body1">{step}</Typography>
                </Stack>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <CardMedia
          alt=""
          component="img"
          src={useImagePath ? img : `https://${cloudFront}/${img}`}
          sx={{
            bottom: 0,
            height: 174,
            position: 'absolute',
            width: 268,
            zIndex: 0
          }}
        />
      </Stack>
      {not(useImagePath) && (
        <Button
          fullWidth
          LinkComponent={Link}
          onClick={handleClick}
          to={`/goals/create/premade?cardId=${id}`}
        >
          Set Goal
        </Button>
      )}
    </>
  )
}

UnsetGoalContent.propTypes = {
  id: PropTypes.number.isRequired,
  img: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  useImagePath: PropTypes.bool
}

export default UnsetGoalContent
