import { useParams } from 'react-router-dom'
import { any, equals, isNil, toLower, __ } from 'ramda'
import { Skeleton, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useGetScoresQuery, useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { useMemo } from 'react'

import QuizResults from './components/QuizResults'
import UserFeedback from 'components/user-feedback/UserFeedback'

const ResultsPage = () => {
  const { quiz } = useParams()

  const { data: quizData, isFetching: isFetchingQuizData } =
    useGetSelfCheckQuizDataQuery()

  const { data, isFetching } = useGetScoresQuery(__, {
    refetchOnMountOrArgChange: true
  })

  const currentQuiz = useMemo(() => {
    if (isNil(quizData)) return {}
    return quizData.find(({ title }) => toLower(title).includes(quiz))
  }, [quiz, quizData])

  if (
    any(equals(true))([
      isFetchingQuizData,
      isFetching,
      isNil(data),
      isNil(quizData)
    ])
  )
    return (
      <Stack alignItems="center" spacing={2} sx={{ pt: 5, px: 2 }}>
        <Skeleton height={63} variant="text" width="60%" />
        <Skeleton height={132} variant="circular" width={132} />
        <Skeleton height={63} variant="text" width="80%" />
        <Skeleton height={14} variant="text" width="20%" />
        <Skeleton height={140} variant="rectangular" width="50%" />
        <Skeleton height={210} variant="rectangular" width="100%" />
        <Skeleton height={210} variant="rectangular" width="100%" />
      </Stack>
    )

  return (
    <Stack alignItems="center" sx={{ pt: 5 }}>
      <QuizResults currentQuiz={currentQuiz} results={data} />
      <UserFeedback
        hasNeutral
        negativeTitle={
          <FormattedMessage
            defaultMessage="Setting priorities takes time. Explore more and revisit the convo."
            id="5166yb"
          />
        }
        positiveTitle={
          <FormattedMessage
            defaultMessage="Heck ya! Harness the momentum."
            id="aAVn92"
          />
        }
        subtitle={
          <FormattedMessage
            defaultMessage="Did these self-check results help you prioritize aspects of your well-being?"
            id="8+l2+r"
          />
        }
      />
    </Stack>
  )
}

export default ResultsPage
