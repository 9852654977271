/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Collapse,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { or, not, prop, reverse, slice, sortBy, length, isEmpty } from 'ramda'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const AllTopicsBlock = () => {
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('tablet'))
  const [isExpanded, setIsExpanded] = useState(false)

  const { userDomains } = useSelector(prop('user'))

  useEffect(() => {
    if (isTablet) {
      setIsExpanded(true)
    }
    setIsExpanded(false)
  }, [isTablet])

  if (isEmpty(userDomains))
    return (
      <Stack
        data-testid="skeleton"
        direction={{ mobile: 'column', laptop: 'row' }}
        spacing={{ mobile: 1, laptop: 3 }}
      >
        <Skeleton height={200} variant="rectangular" width="100%" />
        <Skeleton height={200} variant="rectangular" width="100%" />
        <Skeleton height={200} variant="rectangular" width="100%" />
      </Stack>
    )

  const domainSubtext = {
    Matter: (
      <FormattedMessage defaultMessage="connections & purpose" id="XY6yEK" />
    ),
    Succeed: <FormattedMessage defaultMessage="life & career" id="xkryKP" />,
    Thrive: <FormattedMessage defaultMessage="mind & body" id="Q2hKy0" />
  }

  const sortSubdomains = subs => reverse(sortBy(prop('sort'))(subs))

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          // eslint-disable-next-line sort-keys
          gridTemplateColumns: { mobile: '1fr', tablet: 'repeat(3, 1fr)' },
          px: 3
        }}
      >
        {userDomains.map(
          ({ id, title, subdomains, frontend_url_slug: slug }) => (
            <Box key={id}>
              <Stack
                alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                direction={{ mobile: 'column', tablet: 'row' }}
                spacing={0.5}
              >
                <Typography noWrap variant="h5">
                  <FormattedMessage
                    defaultMessage="{title}"
                    id="2YgLVn"
                    values={{ title }}
                  />
                  {not(isTablet) && ' /'}
                </Typography>
                <Typography
                  noWrap
                  textTransform="capitalize"
                  variant="subtitle2"
                >
                  {prop(title, domainSubtext)}
                </Typography>
              </Stack>
              <Stack spacing={1} sx={{ mt: 1 }}>
                {slice(0, 3, sortSubdomains(subdomains)).map(
                  ({ id, title, frontend_url_slug }) => (
                    <Button
                      aria-label={`${slug} ${frontend_url_slug}`}
                      key={title}
                      LinkComponent={Link}
                      state={{ groupId: id }}
                      sx={{
                        whiteSpace: 'unset'
                      }}
                      to={`/${slug}/${frontend_url_slug}`}
                      variant="text"
                    >
                      {title}
                    </Button>
                  )
                )}
                <Collapse in={or(isExpanded, isTablet)}>
                  <Stack spacing={1}>
                    {slice(
                      3,
                      length(subdomains),
                      sortSubdomains(subdomains)
                    ).map(({ id, title, frontend_url_slug }) => (
                      <Button
                        aria-label={`${slug} ${frontend_url_slug}`}
                        key={title}
                        LinkComponent={Link}
                        state={{ groupId: id }}
                        sx={{
                          whiteSpace: 'unset'
                        }}
                        to={`/${slug}/${frontend_url_slug}`}
                        variant="text"
                      >
                        {title}
                      </Button>
                    ))}
                  </Stack>
                </Collapse>
              </Stack>
            </Box>
          )
        )}
      </Box>

      <Button
        endIcon={
          isExpanded ? (
            <Icon>keyboard_arrow_up</Icon>
          ) : (
            <Icon>keyboard_arrow_down</Icon>
          )
        }
        onClick={() => setIsExpanded(not(isExpanded))}
        sx={{
          alignSelf: 'center',
          // eslint-disable-next-line sort-keys
          display: { mobile: 'none', tablet: 'flex' },
          mt: 3
        }}
        variant="text"
      >
        {isExpanded ? (
          <FormattedMessage defaultMessage="show less" id="vb8spb" />
        ) : (
          <FormattedMessage defaultMessage="show more" id="IRPFF9" />
        )}
      </Button>
    </Stack>
  )
}

export default AllTopicsBlock
