/* eslint-disable sort-keys */
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { equals, isNil, not, prop } from 'ramda'
import { useSelector } from 'react-redux'
import Toast from 'components/toast/Toast'

const SSOSignIn = () => {
  const { formatMessage } = useIntl()
  // We will only ever have two per sso links per school according to management.
  // - Stan 3/1/2022
  const { sso_name, sso_link, sso_name_two, sso_link_two } = useSelector(
    prop('school')
  )

  return (
    <Stack sx={{ mt: 3 }}>
      <Toast
        isShown={equals(window.location.hash, '#error-with-sso')}
        message={
          <FormattedMessage
            defaultMessage="Hmm. There was a problem with your single-sign-on. Contact your organization’s IT support to resolve it."
            id="gQjOju"
          />
        }
        type="error"
      />
      <Stack spacing={3}>
        {not(isNil(sso_name)) && (
          <Button
            aria-label={formatMessage(
              {
                defaultMessage: 'Continue with {ssoType}',
                id: 'dvCz+c'
              },
              { ssoType: sso_name }
            )}
            LinkComponent={Link}
            to={sso_link}
          >
            <FormattedMessage
              defaultMessage="Continue with {name}"
              id="8tbL0B"
              values={{ name: sso_name }}
            />
          </Button>
        )}

        {not(isNil(sso_name_two)) && (
          <Button
            aria-label={formatMessage(
              {
                defaultMessage: 'Continue with {ssoType}',
                id: 'dvCz+c'
              },
              { ssoType: sso_name_two }
            )}
            LinkComponent={Link}
            to={sso_link_two}
          >
            <FormattedMessage
              defaultMessage="Continue with {name}"
              id="8tbL0B"
              values={{ name: sso_name_two }}
            />
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default SSOSignIn
