/* eslint-disable sort-keys */
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

const Banner = ({ imageUrl, isResource = false }) => {
  const { accent_color, primary_color } = useSelector(prop('school'))

  return (
    <Box
      data-testid="banner"
      sx={{
        background: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 383,
        position: 'relative',
        width: 1
      }}
    >
      {isResource && (
        <>
          <Box
            sx={{
              backgroundColor: accent_color,
              bottom: 6,
              height: 167,
              mask: 'url(/vectors/wave-2-alt.svg) no-repeat 0%',
              maskSize: { mobile: 'cover', tablet: '100%' },
              position: 'absolute',
              width: 1
            }}
          />
          <Box
            sx={{
              backgroundColor: primary_color,
              bottom: -1,
              height: 167,
              mask: 'url(/vectors/wave-2-alt.svg) no-repeat 0%',
              maskSize: { mobile: 'cover', tablet: '100%' },
              position: 'absolute',
              width: 1
            }}
          />
        </>
      )}
    </Box>
  )
}

Banner.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  isResource: PropTypes.bool
}

export default Banner
