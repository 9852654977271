import { Button, Collapse, Stack, useMediaQuery } from '@mui/material'
import { useGetReferralLinksQuery } from 'api/schoolApi'
import { any, equals, isEmpty, isNil, not, path, slice } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import useAnalytics from 'hooks/useAnalytics'
import Icon from 'components/icon/Icon'

const ReferralLinks = () => {
  const letters = useSelector(path(['school', 'letters']))
  const domain = useSelector(path(['school', 'domain']))
  const isStaffFaculty = useSelector(path(['user', 'is_secondary_user']))

  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('laptop'))
  const { sendEvent } = useAnalytics()
  const { formatMessage } = useIntl()

  const {
    data,
    isFetching = true,
    isError
  } = useGetReferralLinksQuery(
    { domain, isStaffFaculty },
    { skip: isNil(domain) }
  )

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(true)
    }
  }, [isDesktop])

  const links = useMemo(() => {
    if (any(equals(true))([isFetching, isEmpty(data), isNil(data)])) return null
    return slice(0, 3, data)
  }, [data, isFetching])

  if (any(equals(true))([isFetching, isEmpty(links), isNil(links), isError]))
    return null

  const handleClick = ({ id, link, name }) => {
    sendEvent('Strategic Link', {
      'Link ID': id,
      'Link Name': name,
      'Link URL': link
    })
    window.open(link, '_blank')
  }

  return (
    <Collapse collapsedSize={48} in={isOpen}>
      <Button
        aria-label={
          isOpen
            ? formatMessage(
                {
                  defaultMessage: 'expanded, Featured at {letters}',
                  id: 'QFNiKl'
                },
                {
                  letters
                }
              )
            : formatMessage(
                {
                  defaultMessage: 'collapsed, Featured at {letters}',
                  id: 'R10ycv'
                },
                {
                  letters
                }
              )
        }
        endIcon={
          <Icon
            sx={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: '.2s linear'
            }}
          >
            keyboard_arrow_down
          </Icon>
        }
        onClick={() => setIsOpen(not(isOpen))}
        startIcon={<Icon>bolt</Icon>}
        variant="sidebar"
      >
        <FormattedMessage
          defaultMessage="Featured at {letters}"
          id="iOkM+s"
          values={{
            letters
          }}
        />
      </Button>
      <Stack alignItems="center">
        {links.map(({ id, link, name }) => (
          <Button
            endIcon={<Icon>open_in_new</Icon>}
            key={id}
            onClick={() => handleClick({ id, link, name })}
            sx={{
              justifyContent: 'space-between',
              textAlign: 'left'
            }}
            variant="sidebar"
          >
            {name}
          </Button>
        ))}
      </Stack>
    </Collapse>
  )
}

export default ReferralLinks
