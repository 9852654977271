import { Card, CardContent, Skeleton, Stack } from '@mui/material'

const LoadingCard = () => (
  <Card>
    <Skeleton height={180} variant="rectangular" width="100%" />
    <CardContent
      sx={{
        ':last-child': {
          pb: 0
        },
        pb: 0
      }}
    >
      <Stack spacing={1}>
        <Skeleton
          height={23}
          sx={{ borderRadius: 7.5 }}
          variant="rounded"
          width={110}
        />
        <Skeleton height={24} variant="rectangular" />
        <Stack>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
)

export default LoadingCard
