import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import {
  TYPE_RESOURCE,
  TYPE_FACT_TIP,
  TYPE_LIFE_LESSON,
  TYPE_TOOL,
  TYPE_TIP,
  TYPE_SKILL
} from 'utils/card-functions'
import { equals } from 'ramda'
import Icon from 'components/icon/Icon'

const ImageIcon = ({ type }) => {
  const typeIcon = () => {
    if (equals(TYPE_LIFE_LESSON, type)) {
      return <Icon>import_contacts</Icon>
    }
    if (equals(TYPE_RESOURCE, type)) return <Icon>location_on</Icon>
    if (equals(TYPE_TOOL, type)) return <Icon>build</Icon>
    if (equals(TYPE_SKILL, type)) return <Icon>rocket</Icon>
    if (equals(TYPE_FACT_TIP, type)) return <Icon>lightbulb</Icon>
    if (equals(TYPE_TIP, type)) return <Icon>contract_edit</Icon>
  }

  return (
    <Box
      sx={{
        backgroundColor: 'surface.high',
        borderRadius: '50%',
        height: 40,
        padding: 1,
        position: 'absolute',
        right: 8,
        top: 8,
        width: 40
      }}
    >
      {typeIcon()}
    </Box>
  )
}

ImageIcon.propTypes = {
  type: PropTypes.number.isRequired
}

export default ImageIcon
