export const MuiPaginationItem = palette => ({
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: palette.button.tonal.background,
        color: palette.button.tonal.onSurface
      },
      color: palette.button.tonal.onSurface
    }
  }
})
