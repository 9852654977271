/* eslint-disable sort-keys */
import { Box, Stack, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { flip, isNil } from 'ramda'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

import QuestionsPage from './components/QuestionsPage'
import SettingsPage from './components/SettingsPage'

const Profile = () => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  // Prevents console warning
  // null check search params
  const active = search.get('active') ?? 'about'

  const handleRouteChange = active => navigate(`/profile?active=${active}`)

  useEffect(() => {
    if (isNil(active)) navigate('/profile?active=about')
  }, [active, navigate])

  return (
    <Stack spacing={2} sx={{ mt: { mobile: 8, laptop: 14 } }}>
      <Typography textAlign="center" variant="h1">
        Profile
      </Typography>

      <Box>
        <TabContext value={active}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Stack
              aria-label="profile tabs"
              component={TabList}
              direction="row"
              onChange={flip(handleRouteChange)}
              spacing={1}
              sx={{
                alignItems: 'center',
                borderBottom: 1,
                borderColor: 'surface.highest',
                justifyContent: 'center',
                pb: 4,
                width: 1
              }}
            >
              <Tab label="About You" value="about" />
              <Tab label="Settings" value="settings" />
            </Stack>
          </Box>
          <TabPanel value="about">
            <QuestionsPage />
          </TabPanel>
          <TabPanel value="settings">
            <SettingsPage />
          </TabPanel>
        </TabContext>
      </Box>
    </Stack>
  )
}

export default Profile
