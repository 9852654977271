import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Button, Grow, Stack, Typography } from '@mui/material'
import parse from 'html-react-parser'
import Icon from 'components/icon/Icon'

const VideoTranscript = ({ videoTranscript }) => {
  const [showTranscript, setShowTranscript] = useState(false)

  return (
    <Stack alignItems="center" justifyContent="center">
      <Button
        endIcon={
          showTranscript ? (
            <Icon>keyboard_arrow_up</Icon>
          ) : (
            <Icon>keyboard_arrow_down</Icon>
          )
        }
        onClick={() => setShowTranscript(!showTranscript)}
        variant="text"
      >
        {showTranscript ? (
          <FormattedMessage
            defaultMessage="Hide Audio Transcript"
            id="SsirJy"
          />
        ) : (
          <FormattedMessage
            defaultMessage="View Audio Transcript"
            id="BPWeoX"
          />
        )}
      </Button>
      <Grow in={showTranscript}>
        <Typography
          sx={{
            bgcolor: 'surface.container',
            height: showTranscript ? 'auto' : 0,
            mt: 2,
            p: showTranscript ? 2 : 0
          }}
          variant="subtitle1"
        >
          {showTranscript && parse(videoTranscript)}
        </Typography>
      </Grow>
    </Stack>
  )
}

VideoTranscript.propTypes = {
  videoTranscript: PropTypes.string.isRequired
}

export default VideoTranscript
