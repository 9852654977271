import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useAnalytics } from 'hooks'
import { equals } from 'ramda'
import SocialBadge from 'components/social-badge/SocialBadge'

const PrioritiesImageCard = ({
  index,
  priority,
  imgURL,
  priorityURL,
  socialCount
}) => {
  const [isActive, setIsActive] = useState(false)
  const { sendEvent } = useAnalytics()

  return (
    <Stack
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      sx={{
        position: 'relative',
        zIndex: 0
      }}
    >
      <CardActionArea
        component={Link}
        onClick={() =>
          sendEvent('block_interactions', {
            block_title: 'Priority Carousel Card',
            click_element: 'Link',
            element_title: priority,
            path: window.location.pathname
          })
        }
        sx={{
          height: '100%',
          position: 'relative',
          zIndex: isActive ? 1 : 100
        }}
        to={priorityURL}
      >
        <CardMedia
          alt=""
          component="img"
          height={225}
          src={imgURL}
          sx={{
            borderBottomLeftRadius: equals(0, index) ? 16 : 0,
            borderTopLeftRadius: equals(0, index) ? 16 : 0,
            width: 1
          }}
        />
        <Box
          sx={{
            bottom: 10,
            left: 'calc(50% - 66px)',
            opacity: isActive ? 1 : 0,
            position: 'absolute',
            transition: 'all .3s ease-in-out'
          }}
        >
          <SocialBadge socialCount={socialCount} />
        </Box>
      </CardActionArea>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography aria-hidden variant="body.2">
          {priority}
        </Typography>
      </CardContent>
    </Stack>
  )
}

PrioritiesImageCard.propTypes = {
  imgURL: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  priority: PropTypes.string.isRequired,
  priorityURL: PropTypes.string.isRequired,
  socialCount: PropTypes.number.isRequired
}

export default PrioritiesImageCard
