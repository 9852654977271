export const MuiDivider = palette => ({
  styleOverrides: {
    fullWidth: {
      height: '1px'
    },
    root: {
      borderColor: palette.surface.high
    }
  },
  variants: [
    {
      props: { orientation: 'horizontal' },
      style: {
        margin: '8px 0',
        width: '100%'
      }
    },
    {
      props: { variant: 'card accent' },
      style: {
        height: 3,
        margin: 0,
        width: '100%'
      }
    }
  ]
})
