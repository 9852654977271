import PropTypes from 'prop-types'
import { Box, Stack } from '@mui/material'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

const BackgroundImage = ({
  imageUrl = null,
  isCampusResource = false,
  isFactTip = false,
  isSkill = false
}) => {
  const { flagImage, primary_color } = useSelector(prop('school'))

  const Flag = () => (
    <Stack
      data-testid="flag"
      sx={{
        alignItems: 'center',
        backgroundColor: primary_color,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '50%',
        bottom: 18,
        height: 40,
        justifyContent: 'center',
        maxWidth: 'initial',
        position: 'absolute',
        right: 18,
        width: 40
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${flagImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bgcolor: 'transparent',
          height: 32,
          maxWidth: 'initial',
          position: 'absolute',
          width: 32
        }}
      />
    </Stack>
  )

  return (
    <Box
      data-testid="background-image"
      sx={{
        aspectRatio: '2/1',
        backgroundImage: `url(${isFactTip ? '/vectors/fact-tip-card-bg.svg' : imageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: isSkill ? 'contain' : 'cover',
        borderRadius: 6,
        maxWidth: 600,
        position: 'relative',
        width: 1
      }}
    >
      {isCampusResource && <Flag />}
    </Box>
  )
}

BackgroundImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  isCampusResource: PropTypes.bool,
  isFactTip: PropTypes.bool,
  isSkill: PropTypes.bool
}

export default BackgroundImage
