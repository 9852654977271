import { FormattedMessage } from 'react-intl'
import { Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const EmptyState = () => (
  <Stack
    alignItems="center"
    spacing={2}
    // eslint-disable-next-line sort-keys
    sx={{ mt: { mobile: 4, tablet: 8 }, px: 2 }}
  >
    <Typography variant="subtitle1">
      You don’t currently have any active goals.
    </Typography>
    <Button
      endIcon={<Icon>arrow_forward</Icon>}
      LinkComponent={Link}
      to="/goals/create/new"
      variant="text"
    >
      <FormattedMessage defaultMessage="create my own goal" id="wn1nUL" />
    </Button>
  </Stack>
)

export default EmptyState
