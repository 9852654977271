import PropTypes from 'prop-types'
import { memo } from 'react'
import { FormControl, MenuItem, Select, Typography } from '@mui/material'
import {
  defaultTo,
  find,
  includes,
  join,
  not,
  path,
  prop,
  propEq,
  split
} from 'ramda'
import { useAnalytics } from 'hooks'

const SelectQuestionInput = ({
  question,
  userAnswers = [],
  onUpdateAnswer
}) => {
  const { sendEvent } = useAnalytics()
  const mappedAnswers = prop('answers', question)

  const selectedAnswer = defaultTo(
    '',
    prop('answer', find(propEq(prop(0, userAnswers), 'id'), mappedAnswers))
  )

  // Find the selection based on the val passed
  // send the "name" to analytics
  const handleAnalytics = val => {
    const found = mappedAnswers.find(propEq(val, 'id'))
    sendEvent('profile_question', {
      answer: prop('answer', found),
      question: prop('analytics_property_name', question)
    })
  }

  const renderLabel = () => join(' ', split('_', prop('name', question)))

  return (
    <FormControl spacing={1} sx={{ width: 1 }}>
      <Typography variant="h4">{prop('question', question)}</Typography>
      <Typography
        component="label"
        id={`select-question-${prop('id', question)}`}
        sx={{
          height: 0,
          textIndent: '-9999px'
        }}
      >
        {renderLabel()}
      </Typography>
      <Select
        label={renderLabel()}
        labelId={`select-question-${prop('id', question)}`}
        onChange={e => {
          const selected = path(['target', 'value'], e)
          const found = find(propEq(selected, 'answer'), mappedAnswers)
          const updatedAnswer = {
            answers: [prop('id', found)],
            profile_question_id: prop('id', question)
          }
          if (not(includes(userAnswers, updatedAnswer))) {
            onUpdateAnswer(updatedAnswer)
            handleAnalytics(prop('id', found))
          }
        }}
        value={selectedAnswer}
      >
        {mappedAnswers.map(({ answer, id }) => (
          <MenuItem key={id} value={answer}>
            {answer}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SelectQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    question: PropTypes.string
  }).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired
}

export default memo(SelectQuestionInput)
