import useSession from 'hooks/useSession'
import { and, isNil, not, prop } from 'ramda'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const SessionHandler = () => {
  const { domain } = useSelector(prop('school'))

  const { sessionEvent } = useSession()
  const keenSessionId = sessionStorage.getItem('@you:keenSessionId')

  useEffect(() => {
    if (and(isNil(keenSessionId), not(isNil(domain)))) {
      // timestamp + a random 4 alphanumeric string in case of users accessing at precisely same millisecond
      const newId = Date.now().toString() + Math.random().toString(36).slice(9)
      sessionStorage.setItem('@you:keenSessionId', newId)
      sessionEvent('init')
    }
  }, [keenSessionId, domain, sessionEvent])

  return
}

export default SessionHandler
