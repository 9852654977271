/* eslint-disable sort-keys */
export const MuiContainer = () => ({
  styleOverrides: {
    root: {
      '@media(min-width: 1440px)': {
        maxWidth: 'calc(75rem - 32px)',
        paddingLeft: 16,
        paddingRight: 16
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    }
  }
})
