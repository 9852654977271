import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { Button, Stack, Typography } from '@mui/material'
import {
  useWelcomeUnsubscribeMutation,
  useReengagementUnsubscribeMutation
} from 'api/userApi'
import { equals } from 'ramda'

const Unsubscribe = () => {
  const { email, type } = useParams()
  const { sendEvent } = useAnalytics()

  const [unsubscribed, setUnsubscribed] = useState(false)

  const [unsubscribe] = useWelcomeUnsubscribeMutation()
  const [unsubscribeReengagment] = useReengagementUnsubscribeMutation()

  const handleClick = () => {
    setUnsubscribed(true)
    if (equals('welcome', type)) sendEvent('Welcome_Email_Unsubscribe', {})
    if (equals('reengagement', type))
      sendEvent('re-engagement_email_unsubscribe', {})
    const body = new FormData()
    body.append('email', email)
    if (equals('welcome', type)) unsubscribe(body)
    if (equals('reengagement', type)) unsubscribeReengagment(body)
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center',
          borderRadius: 2,
          justifyContent: 'center',
          maxWidth: 450,
          my: 8,
          p: 4,
          width: 1
        }}
      >
        <Typography component="h1" textAlign="center" variant="h2">
          <FormattedMessage defaultMessage="Sorry to see you go!" id="oJcW5s" />
        </Typography>
        <Typography textAlign="center" variant="body2">
          <FormattedMessage
            defaultMessage="Click the button to unsubscribe from {type} emails"
            id="DX66Z5"
            values={{ type }}
          />
        </Typography>
        <Button onClick={handleClick}>
          <FormattedMessage defaultMessage="UNSUBSCRIBE" id="TuxS79" />
        </Button>
        {unsubscribed && (
          <Typography textAlign="center" variant="body2">
            <FormattedMessage
              defaultMessage="You have been unsubscribed."
              id="krbxTo"
              values={{ email }}
            />
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default Unsubscribe
