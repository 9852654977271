/* eslint-disable react/jsx-key */
import { FormattedMessage } from 'react-intl'

const Body = [
  <FormattedMessage defaultMessage="Headaches" id="6RLpCP" />,
  <FormattedMessage defaultMessage="Muscle tension" id="OHXHPa" />,
  <FormattedMessage defaultMessage="Racing heart" id="2ifDXP" />,
  <FormattedMessage
    defaultMessage="Uneasy feeling in my stomach"
    id="ZCjA9y"
  />,
  <FormattedMessage defaultMessage="Sweaty palms" id="q/+zw+" />,
  <FormattedMessage defaultMessage="Fatigue" id="L/GDBt" />,
  <FormattedMessage defaultMessage="Trouble sleeping" id="ivGdpw" />,
  <FormattedMessage defaultMessage="Change in appetite" id="k73oNY" />,
  <FormattedMessage defaultMessage="Shortness of breath" id="FRHMej" />,
  <FormattedMessage
    defaultMessage="Using alcohol or drugs to cope"
    id="f2CCEw"
  />,
  <FormattedMessage
    defaultMessage="Other (What's unique to you?)"
    id="UBuXPa"
  />,
]

export default Body
