/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { equals, isNil, not, path } from 'ramda'
import { createContext, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setMode } from 'store/themeSlice'

import darkTheme from 'theme/darkTheme'
import lightTheme from 'theme/lightTheme'

export const ThemeContext = createContext({})

const ThemeContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const initialMode = localStorage.getItem('colorMode')
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const mode = useSelector(path(['theme', 'mode']))

  // This sets our initial local storage state
  // if it doesn't exist set to the users prefered settings
  // if it does exist set to the local storage state
  useEffect(() => {
    if (isNil(initialMode)) {
      localStorage.setItem('colorMode', prefersDarkMode ? 'dark' : 'light')
      dispatch(setMode(prefersDarkMode ? 'dark' : 'light'))
      return
    }
    if (not(isNil(initialMode))) {
      dispatch(setMode(initialMode))
    }
  }, [dispatch, initialMode, prefersDarkMode])

  const switchColorMode = () => {
    const newmode = equals(mode, 'light') ? 'dark' : 'light'
    dispatch(setMode(newmode))
    localStorage.setItem('colorMode', newmode)
  }

  const light = createTheme({ ...lightTheme, mode })

  const dark = createTheme({ ...darkTheme, mode })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={{ mode, switchColorMode }}>
        <ThemeProvider theme={equals(mode, 'light') ? light : dark}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {children}
          </LocalizationProvider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  )
}

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThemeContextProvider
