/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { prop, inc, length } from 'ramda'
import { Box, LinearProgress, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import QuizQuestion from './QuizQuestion'

const Quiz = ({ currentQuiz, question, questions, setQuestion }) => (
  <Stack
    spacing={4}
    sx={{ m: '0 auto', maxWidth: 1024, position: 'relative', px: 2, py: 12 }}
  >
    <Stack alignItems="center" spacing={2}>
      <Typography component="h1" variant="overline">
        <FormattedMessage
          defaultMessage="{quizType} self check"
          id="qfqa6e"
          values={{ quizType: prop('title', currentQuiz) }}
        />
      </Typography>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="subtitle1">
          <FormattedMessage defaultMessage="Question:" id="XbWN0a" />
        </Typography>
        <Typography id="count" variant="subtitle1">
          {inc(questions.indexOf(question))} / {length(questions)}
        </Typography>
      </Stack>
      <Box
        sx={{
          maxWidth: 480,
          width: 1
        }}
      >
        <LinearProgress
          aria-hidden="true"
          sx={{
            width: 1
          }}
          value={(inc(questions.indexOf(question)) / length(questions)) * 100}
          variant="determinate"
        />
      </Box>
    </Stack>
    <QuizQuestion
      currentQuiz={currentQuiz}
      question={question}
      questions={questions}
      setQuestion={setQuestion}
    />
  </Stack>
)

Quiz.propTypes = {
  currentQuiz: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({}).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestion: PropTypes.func.isRequired
}

export default Quiz
