import { Route, Routes } from 'react-router-dom'

import App from 'App'
import Welcome from 'pages/welcome/Welcome'
import Accessibility from 'pages/accessibility/Accessibility'
import TermsAndConditions from 'pages/terms-and-conditions/TermsAndConditions'
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy'

// Auth
import SignIn from 'pages/authentication/sign-in/SignIn'
import SignInDemo from 'pages/authentication/sign-in/SignInDemo'
import SignUp from 'pages/authentication/sign-up/SignUp'
import SignUpDemo from 'pages/authentication/sign-up/SignUpDemo'
import Logout from 'pages/authentication/logout/Logout'
import EmailReset from 'pages/authentication/password-reset/EmailReset'
import PasswordReset from 'pages/authentication/password-reset/PasswordReset'

// SSO
import AccountCreated from 'pages/sso/AccountCreated'

// Authenticated Routes
import Dashboard from 'pages/dashboard/Dashboard'
import SubdomainPage from 'pages/subdomain/SubdomainPage'
// User
import Verify from 'pages/user/verify/Verify'
import Unsubscribe from 'pages/user/unsubscribe/Unsubscribe'
import Profile from 'pages/user/profile/Profile'
// Articles
import ArticlePage from 'pages/article/Article'
// Destress
import Destress from 'pages/destress/Destress'
import DestressLayout from 'pages/destress/DestressLayout'
import Introduction from 'pages/destress/components/Introduction'
import Poll from 'pages/destress/components/Poll'
import Cards from 'pages/destress/components/Cards'
import Education from 'pages/destress/components/Education'
import Impact from 'pages/destress/components/Impact'
import GreatWork from 'pages/destress/components/GreatWork'
import Activity from 'pages/destress/components/Activity'
import KeepRolling from 'pages/destress/components/KeepRolling'
import ActsOfKindness from 'pages/destress/components/ActsOfKindness'
import Gratitude from 'pages/destress/components/Gratitude'
import Breathing from 'pages/destress/components/Breathing'
import DestressResources from 'pages/destress/components/Resources'
import Feedback from 'pages/destress/components/Feedback'
import Email from 'pages/destress/components/Email'
import Confirmation from 'pages/destress/components/Confirmation'
// Explore
import Explore from 'pages/explore/Explore'
import Group from 'pages/explore/Group'
// Favorites
import Favorites from 'pages/favorites/Favorites'
// Feedback
import FeedbackPage from 'pages/feedback/Feedback'
// Goals
import GoalsPage from 'pages/goals/Goals'
import CompletedGoal from 'pages/goals/complete/CompletedGoal'
import NewGoal from 'pages/goals/create/new/NewGoal'
import PremadeGoal from 'pages/goals/create/premade/PremadeGoal'
import EditGoal from 'pages/goals/edit/EditGoal'
import ViewGoal from 'pages/goals/view/ViewGoal'
// Onboarding
import Onboarding from 'pages/onboarding/Onboarding'
import OnboardingLayout from 'pages/onboarding/OnboardingLayout'
// Referral
import Referral from 'pages/referral/Referral'
// Resources
import Resources from 'pages/campus-resources/Resources'
// Search
import Search from 'pages/search/Search'
// Self Checks
import SelfChecks from 'pages/self-checks/SelfChecks'
import QuizPage from 'pages/self-checks/quiz/QuizPage'
import ResultsPage from 'pages/self-checks/results/ResultsPage'
// Stats Dash
import StatsDash from 'pages/stats-dashboard/StatsDash'
// Student Content
import StudentContent from 'pages/student-content/StudentContent'
// Student Posts
import StudentPosts from 'pages/student-posts/StudentPosts'

// Error
import NotFound from 'pages/404/404'

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<App />} />
      {/* Non Authenticated Routes */}
      <Route element={<Welcome />} index />
      <Route element={<Accessibility />} path="accessibility" />
      <Route element={<TermsAndConditions />} path="terms-and-conditions" />
      <Route element={<PrivacyPolicy />} path="privacy-policy" />

      {/* Authentication & SSO */}
      <Route path="sign-in">
        <Route element={<SignIn />} index />
        <Route element={<SignInDemo />} path="demo" />
      </Route>
      <Route path="sign-up">
        <Route element={<SignUp />} index />
        <Route element={<SignUpDemo />} path="demo" />
      </Route>
      <Route
        element={<AccountCreated />}
        path="sso/login/:token/:accountCreated"
      />
      <Route element={<Logout />} path="logout" />
      <Route path="password-reset">
        <Route element={<EmailReset />} index />
        <Route element={<PasswordReset />} path=":token" />
      </Route>

      {/* Authenticated Routes */}
      {/* User */}
      <Route element={<Verify />} path="verify/:token" />
      <Route element={<Unsubscribe />} path="unsubscribe/:type/:email" />
      <Route element={<Profile />} path="profile" />
      <Route element={<Dashboard />} path="home" />
      <Route element={<SubdomainPage />} path=":domainName/:subdomainName" />
      {/* Articles */}
      <Route path="article/:id/:articleName">
        <Route element={<ArticlePage />} index />
      </Route>
      {/* Destress */}
      <Route path="destress">
        <Route element={<Destress />} index />
        <Route element={<DestressLayout />}>
          <Route element={<Introduction />} path="introduction" />
          <Route element={<Poll />} path="poll" />
          <Route element={<Cards />} path="information" />
          <Route element={<Education />} path="education" />
          <Route element={<Impact key={0} type="body" />} path="impact-body" />
          <Route
            element={<Impact key={1} type="thoughts" />}
            path="impact-thoughts"
          />
          <Route
            element={<Impact key={2} type="relationships" />}
            path="impact-relationships"
          />
          <Route element={<GreatWork />} path="great-work" />
          <Route path="activity">
            <Route element={<Activity />} index />
            <Route element={<ActsOfKindness />} path="kindness" />
            <Route element={<Gratitude />} path="gratitude" />
            <Route element={<Breathing />} path="breathing" />
          </Route>
          <Route element={<KeepRolling />} path="keep-rolling" />
          <Route element={<Email />} path="email" />
          <Route element={<DestressResources />} path="resources" />
          <Route element={<Feedback />} path="feedback" />
          <Route element={<Confirmation />} path="confirmation" />
        </Route>
      </Route>
      {/* Explore */}
      <Route path="explore">
        <Route element={<Explore />} index />
        <Route element={<Group />} path="group/:groupId" />
      </Route>
      {/* Favorites */}
      <Route element={<Favorites />} path="favorites" />
      {/* Feedback */}
      <Route element={<FeedbackPage />} path="feedback" />
      {/* Goals */}
      <Route path="goals">
        <Route element={<GoalsPage />} index />
        <Route element={<CompletedGoal />} path="complete" />
        <Route element={<NewGoal />} path="create/new" />
        <Route element={<PremadeGoal />} path="create/premade" />
        <Route element={<EditGoal />} path="edit/:id" />
        <Route element={<ViewGoal />} path="view/:id" />
      </Route>
      {/* Onboarding */}
      <Route path="onboarding">
        <Route element={<Onboarding />} index />
        <Route element={<OnboardingLayout />} path=":step" />
      </Route>
      {/* Referral */}
      <Route element={<Referral />} path="referral" />
      {/* Resources */}
      <Route element={<Resources />} path="resources" />
      {/* Search */}
      <Route element={<Search />} path="search" />
      {/* Self Checks */}
      <Route path="self-checks">
        <Route element={<SelfChecks />} index />
        <Route element={<QuizPage />} path=":quiz" />
        <Route element={<ResultsPage />} path="results/:quiz" />
      </Route>
      {/* Stats */}
      <Route element={<StatsDash />} path="stats-dashboard" />
      {/* Student Content */}
      <Route element={<StudentContent />} path="student-content" />
      {/* StudentPosts */}
      <Route element={<StudentPosts />} path="student-posts" />
      {/* Error */}
      <Route element={<NotFound />} path="*" />
    </Routes>
  )
}

export default AppRoutes
