/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import SocialBadge from 'components/social-badge/SocialBadge'
import NextSteps from './NextSteps'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { prop, equals, and, gte, lte, not, isNil, propEq } from 'ramda'
import useAnalytics from 'hooks/useAnalytics'

import {
  Box,
  Button,
  Card,
  CardActionArea,
  Collapse,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const ResultsCard = ({ subdomain, results }) => {
  const { userDomains } = useSelector(prop('user'))
  const intl = useIntl()
  const { sendEvent } = useAnalytics()
  const { quiz } = useParams()
  const [search] = useSearchParams()
  const selected = parseInt(search.get('selected'))

  const [expanded, setExpanded] = useState(false)

  const subdomainId = prop('id', subdomain)

  useEffect(() => {
    // we have to time this out so that the view size doesnt change when we are scrolling to the element
    // we delay by 250 ms because it's imperceptible to user but smooths out where scroll lands
    setTimeout(() => {
      // need to parseInt here since equals does not do type coercion
      if (equals(selected, subdomainId)) {
        setExpanded(true)
      } else {
        // otherwise we reset the height and expanded
        setExpanded(false)
      }
    }, 250)
  }, [selected, subdomainId])

  // todo - get the emojis for different score levels (should standardize this on the BE)

  // results are a giant list of all answers for the entire quiz
  // we filter that down to the subdomain we are currently on
  // then sum the values associated to those answers
  // then determine the score rating
  // this score is given to us in the subdomain.answers list which contains
  // ranges of integer values that correspond to the score of 1 through 4
  const userAnswers = results.filter(({ reality_check_question_id }) =>
    prop('values', subdomain)
      .map(({ id }) => id)
      .includes(reality_check_question_id)
  )

  const sumAnswerValues = userAnswers.reduce(
    (sum, answer) => sum + answer.score,
    0
  )

  const score = prop('answers', subdomain).find(answer =>
    and(
      gte(sumAnswerValues, prop('low_value', answer)),
      lte(sumAnswerValues, prop('high_value', answer))
    )
  )

  useEffect(() => {
    if (and(not(isNil(quiz)), not(isNil(score))))
      sendEvent('reality_check_indv_result', {
        section_name: quiz.charAt(0).toUpperCase() + quiz.slice(1),
        section_result_name: prop('title', subdomain),
        section_result_score: prop('star', score)
      })
  }, [quiz, score, sendEvent, subdomain])

  const handleExpand = () => {
    setExpanded(not(expanded))
  }

  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomainObject = subdomains.find(propEq(subdomainId, 'id'))

  const renderStars = () => {
    const components = []
    for (let i = 0; i < prop('star', score); i++) {
      components.push(
        <LinearProgress
          aria-hidden="true"
          key={i}
          sx={{
            width: 57
          }}
          value={100}
          variant="determinate"
        />
      )
    }
    return components
  }

  return (
    <Card
      id={prop('id', subdomain)}
      sx={{
        height: 'auto',
        transition: 'all 1s ease'
      }}
      variant="fullWidth"
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '180px 1fr' }
        }}
      >
        <Box
          sx={{
            background: `url(${prop('display_image', subdomainObject)})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: 4
          }}
        />
        <CardActionArea
          aria-label={
            expanded
              ? intl.formatMessage(
                  { defaultMessage: '{title}, expanded', id: 'HvOC5i' },
                  { title: prop('title', subdomain) }
                )
              : intl.formatMessage(
                  { defaultMessage: '{title}, collapsed', id: 'RDLZ7H' },
                  { title: prop('title', subdomain) }
                )
          }
          onClick={handleExpand}
        >
          <Stack
            spacing={2}
            sx={{
              p: 2
            }}
          >
            <Typography component="span" display="block" variant="h4">
              {prop('title', subdomain)}
            </Typography>
            <Typography component="span" display="block" variant="subtitle2">
              {prop('type', score)}
            </Typography>
            <Stack direction="row" spacing={0.5}>
              {renderStars()}
            </Stack>
            <Box>
              <SocialBadge
                socialCount={prop('social_count', subdomainObject)}
              />
            </Box>
            <Box
              sx={{
                alignItems: { mobile: 'flex-start', tablet: 'center' },
                flexDirection: { mobile: 'column', tablet: 'row' },
                justifyContent: { mobile: 'center', tablet: 'space-between' },
                width: 1
              }}
            >
              <Typography
                alignItems="center"
                component="span"
                display="flex"
                variant="caption"
              >
                {expanded ? (
                  <FormattedMessage
                    defaultMessage="Close details"
                    id="fhfSHc"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="About your score"
                    id="Z8mKgK"
                  />
                )}
                {expanded ? (
                  <Icon>keyboard_arrow_up</Icon>
                ) : (
                  <Icon>keyboard_arrow_down</Icon>
                )}
              </Typography>
            </Box>
          </Stack>
        </CardActionArea>
      </Box>
      <Collapse in={expanded}>
        <Stack p={2} spacing={4} width={1}>
          <Stack spacing={2}>
            <Typography component="span" variant="h4">
              <FormattedMessage defaultMessage="About my score" id="ZfhGR/" />
            </Typography>
            <Typography
              component="span"
              sx={{
                width: 1
              }}
              variant="caption"
            >
              {prop('text', score)}
            </Typography>
            <Button LinkComponent={Link} to={prop('link', subdomain)}>
              <FormattedMessage
                defaultMessage="Explore this topic"
                id="d/OuzM"
              />
            </Button>
          </Stack>

          <NextSteps subdomainId={subdomainId} />
        </Stack>
      </Collapse>
    </Card>
  )
}

ResultsCard.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subdomain: PropTypes.shape({}).isRequired
}

export default ResultsCard
