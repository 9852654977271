import { FormattedMessage } from 'react-intl'
import { Box, Container, Stack, Tab, Typography } from '@mui/material'
import { and, equals, flip, isNil, not, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TabContext, TabList } from '@mui/lab'

const StatsDash = () => {
  const {
    mixpanel_primary_user_dashboard_url: primaryUrl,
    mixpanel_secondary_user_dashboard_url: secondaryUrl
  } = useSelector(prop('school'))
  const { allowed_to_view_stats_endpoint: allowedToViewStats, id } =
    useSelector(prop('user'))

  const isLoggedIn = not(isNil(id))
  const navigate = useNavigate()

  const [value, setValue] = useState('primary')

  const srcUrl = useMemo(() => {
    if (isNil(secondaryUrl)) return primaryUrl
    return equals('primary', value) ? primaryUrl : secondaryUrl
  }, [primaryUrl, secondaryUrl, value])

  if (and(isLoggedIn, not(allowedToViewStats))) {
    navigate('/404')
  }

  return (
    <Container maxWidth="monitor" sx={{ mb: 2 }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{ width: 1 }}
      >
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Stats Dashboard" id="pXwsW3" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage defaultMessage="Select your report" id="t2eKIL" />
        </Typography>
        <TabContext value={value}>
          <Stack
            component={TabList}
            direction="row"
            onChange={flip(setValue)}
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              pb: 3,
              width: 1
            }}
          >
            <Tab label="Students" value="primary" variant="tab" />
            {not(isNil(secondaryUrl)) && (
              <Tab label="Faculty" value="secondary" variant="tab" />
            )}
          </Stack>
        </TabContext>
        <Box
          component="iframe"
          id="mixpanel"
          src={srcUrl}
          sx={{
            border: 'none',
            borderRadius: 4,
            height: 524,
            width: 1
          }}
        />
      </Stack>
    </Container>
  )
}

export default StatsDash
