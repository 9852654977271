import { LinearProgress, Stack, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { always, any, cond, equals, includes, not } from 'ramda'
import { useMemo } from 'react'

const Heading = () => {
  const { pathname } = useLocation()

  const currStep = cond([
    [includes('poll'), always(1)],
    [includes('information'), always(2)],
    [includes('education'), always(3)],
    [includes('impact-body'), always(4)],
    [includes('impact-thoughts'), always(5)],
    [includes('impact-relationships'), always(6)],
    [equals('/destress/activity'), always(7)],
    [includes('kindness'), always(8)],
    [includes('gratitude'), always(8)],
    [includes('breathing'), always(8)],
    [includes('resources'), always(9)]
  ])

  const isHidden = useMemo(() => {
    if (
      any(equals(true))([
        includes('introduction', pathname),
        includes('great-work', pathname),
        includes('keep-rolling', pathname),
        includes('feedback', pathname),
        includes('confirmation', pathname),
        includes('email', pathname)
      ])
    )
      return true
    return false
  }, [pathname])

  return (
    <Stack
      alignItems="center"
      spacing={2}
      sx={{
        maxWidth: 350,
        my: { mobile: 4, tablet: 8 },
        width: 1
      }}
    >
      {not(isHidden) && (
        <>
          <Typography variant="overline">Destress 101</Typography>
          <Typography component="span" textAlign="center" variant="subtitle1">
            Step {currStep(pathname)} / 9
          </Typography>
          <LinearProgress
            aria-hidden="true"
            color="inherit"
            sx={{
              width: 1
            }}
            value={(currStep(pathname) / 9) * 100}
            variant="determinate"
          />
        </>
      )}
    </Stack>
  )
}

export default Heading
