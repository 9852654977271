import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  history: [],
  visitTime: null
}

const historySlice = createSlice({
  initialState,
  name: 'history',
  reducers: {
    setHistory: (state, { payload }) => assoc('history', payload, state),
    setVisitTime: (state, { payload }) => assoc('visitTime', payload, state)
  }
})

export const { setHistory, setVisitTime } = historySlice.actions

export default historySlice.reducer
