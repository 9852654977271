import PropTypes from 'prop-types'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { any, assoc, compose, equals, gt, length, path, prop } from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'

const Form = ({ formData, isFetching, sendFeedback, setFormData }) => {
  const { formatMessage } = useIntl()

  const textLength = length(prop('text', formData))

  return (
    <Box>
      <Stack spacing={3}>
        <Typography textAlign="center" variant="h1">
          <FormattedMessage
            defaultMessage="We Welcome Your Feedback"
            id="v87wCE"
          />
        </Typography>
        <Typography textAlign="center" variant="subtitle1">
          <FormattedMessage
            defaultMessage="When we ask, 'How can YOU help you?', we mean it. Use the form below to ask questions, share your ideas or even send words of affirmation."
            id="PrZLId"
          />
        </Typography>
        <Typography textAlign="center" variant="subtitle1">
          <FormattedMessage
            defaultMessage="All messages submitted here are anonymous. If you wish for a response from our team, please include an email address where we can reach you. Thank you!"
            id="W4Pevy"
          />
        </Typography>
      </Stack>
      <Stack
        spacing={3}
        sx={{
          alignItems: 'center',
          mt: 6
        }}
      >
        <Box
          sx={{
            width: '100%',
            // eslint-disable-next-line sort-keys
            '& .MuiInputBase-root': { height: '100%' }
          }}
        >
          <TextField
            aria-label={formatMessage(
              {
                defaultMessage:
                  'Feedback Message. {length} characters remaining.',
                id: 'wgAMio'
              },
              {
                length: 500 - textLength
              }
            )}
            data-testid="textarea"
            error={gt(textLength, 500)}
            fullWidth
            helperText={
              gt(textLength, 500) ? (
                formatMessage({
                  defaultMessage: 'Max Character Limit is 500',
                  id: 'xUPp31'
                })
              ) : (
                <Typography component="span" variant="body2">
                  Character Limit: {500 - textLength}
                </Typography>
              )
            }
            label="*Feedback"
            multiline
            onChange={compose(
              setFormData,
              assoc('text'),
              path(['target', 'value'])
            )}
            rows={10}
            sx={{
              '& > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
                borderColor: 'surface.onSurface'
              },
              '& > .MuiInputBase-root, & div > textarea': {
                backgroundColor: 'transparent'
              },
              '& div > textarea': {
                borderRadius: 0
              }
            }}
            value={prop('text', formData)}
            variant="outlined"
          />
        </Box>
        <TextField
          aria-label={formatMessage({
            defaultMessage: 'email address',
            id: 'eGwKE1'
          })}
          data-testid="textbox"
          fullWidth
          label={formatMessage({
            defaultMessage: 'Email Address',
            id: 'xxQxLE'
          })}
          onChange={compose(
            setFormData,
            assoc('email'),
            path(['target', 'value'])
          )}
          value={prop('email', formData)}
        />
        <Button
          disabled={any(equals(true))([
            gt(textLength, 500),
            equals(textLength, 0),
            prop('success', formData),
            prop('error', formData)
          ])}
          onClick={sendFeedback}
        >
          {isFetching ? (
            <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
          ) : (
            <FormattedMessage defaultMessage="Send Feedback" id="b11hht" />
          )}
        </Button>
      </Stack>
    </Box>
  )
}

Form.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired
}

export default Form
