import { api as baseQuery } from './baseQuery'

export const cardsApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    favorite: mutation({
      invalidatesTags: ['favorite', 'cards', 'card', 'pick up cards'],
      query: ({ isFavorite, card_id }) => ({
        body: { card_id },
        method: 'POST',
        url: `/favorite-${isFavorite ? 'add' : 'remove'}`
      })
    }),
    getCard: query({
      providesTags: ['card'],
      query: id => `/v2/cards/${id}`
    }),
    getCardSentiment: query({
      providesTags: ['card sentiment'],
      query: id => `/card-sentiment/${id}`
    }),
    getCards: query({
      providesTags: ['cards'],
      query: params => ({
        params,
        url: '/v4/cards'
      })
    }),
    getFeaturedCards: query({
      providesTags: ['featured cards'],
      query: qp => `/featured-cards${qp}`
    }),
    getPickupCards: query({
      providesTags: ['pick up cards'],
      query: () => '/v2/cards/pick-up-where-you-left-off'
    }),
    setCardSentiment: mutation({
      invalidatesTags: ['card sentiment'],
      query: body => ({
        body,
        method: 'POST',
        url: '/card-sentiment'
      })
    })
  }),
  reducerPath: 'cardsApi'
})

export const {
  useFavoriteMutation,
  useFavoriteResourceMutation,
  useGetCardQuery,
  useGetCardSentimentQuery,
  useGetCardsQuery,
  useGetFeaturedCardsQuery,
  useGetPickupCardsQuery,
  useSetCardSentimentMutation
} = cardsApi
