import { useEffect } from 'react'
import { Container } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'

const Destress = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/destress/introduction')
  }, [navigate])

  return (
    <Container sx={{ mt: 4, pb: 12 }}>
      <Outlet />
    </Container>
  )
}

export default Destress
