/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  prop,
  not,
  isEmpty,
  map,
  path,
  evolve,
  always,
  equals,
  dec,
  inc,
  reject,
  isNil,
  assoc,
  length,
  all
} from 'ramda'
import { useCreateGoalMutation } from 'api/goalsApi'
import { useGetCardQuery } from 'api/cardsApi'
import { Button, LinearProgress, Stack, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import StepsForm from '../components/StepsForm'
import DueDate from '../components/DueDate'
import Icon from 'components/icon/Icon'
import FlowHeader from 'components/header/FlowHeader'

const PremadeGoal = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const cardId = search.get('cardId')

  const [currentIndex, setCurrentIndex] = useState(0)

  const [createGoal, { isLoading, isSuccess }] = useCreateGoalMutation()

  useEffect(() => {
    if (isSuccess) {
      navigate('/goals?active=my-goals')
    }
  }, [isSuccess, navigate])

  // Updating Goal
  const [goal, setGoal] = useState({
    campus_goal_id: null,
    card_id: null,
    domain_id: null,
    due_date: null,
    steps: [
      {
        completed: false,
        id: null,
        name: ''
      }
    ],
    subdomain_id: null,
    title: ''
  })

  const creationSteps = [
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Save Steps',
        id: 'fKkniH'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Effective goals include smaller, actionable steps. Write out your steps here.',
        id: 'pQx8Am'
      })
    },
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Complete',
        id: 'U78NhE'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          "Setting deadlines is helpful for some, but not all. If it's not your style, skip this step and your goal will appear as ongoing with no due date.",
        id: 'EeDvix'
      })
    }
  ]

  const currentStep = prop(currentIndex, creationSteps)

  const { data = {}, isSuccess: isCardSuccess } = useGetCardQuery(cardId)

  useEffect(() => {
    if (
      all(equals(true))([
        not(isEmpty(data)),
        isCardSuccess,
        isEmpty(prop('title', goal))
      ])
    ) {
      // Since res from campus goals is not correct shape
      // map steps to be correct shape
      // update the goal to the new found data
      const updatedSteps = map(
        (str, idx) => ({
          completed: false,
          id: idx,
          name: str
        }),
        path(['campus_goal', 'steps'], data)
      )
      setGoal(
        evolve(
          {
            campus_goal_id: always(path(['campus_goal', 'id'], data)),
            card_id: always(parseInt(cardId)),
            domain_id: always(prop('section_id', data)),
            steps: always(updatedSteps),
            subdomain_id: always(path(['campus_goal', 'subdomain_id'], data)),
            title: always(prop('title', data))
          },
          goal
        )
      )
    }
  }, [cardId, data, goal, isCardSuccess])

  const handleBack = () => {
    if (equals(currentIndex, 0)) {
      navigate('/goals?active=my-goals')
    } else {
      setCurrentIndex(dec(currentIndex))
      window.scrollTo(0, 0)
    }
  }

  const onSubmit = () => {
    // final step we send info to the BE and route off create
    // otherwise we move to the next step
    if (equals(inc(currentIndex), length(creationSteps))) {
      const body = new FormData()
      const transformedGoal = reject(
        isNil,
        assoc(
          'due_date',
          prop('due_date', goal) ? prop('due_date', goal).toISOString() : '',
          goal
        )
      )
      for (const [key, value] of Object.entries(transformedGoal)) {
        if (equals(key, 'steps')) {
          goal.steps.forEach((step, index) => {
            body.append('steps[' + index + '][name]', step.name)
          })
        } else {
          body.append(key, value)
        }
      }
      createGoal(body)
      navigate('/goals?active=my-goals')
    } else {
      window.scrollTo(0, 0)
      setCurrentIndex(inc(currentIndex))
    }
  }

  return (
    <Stack>
      <FlowHeader />
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center',
          maxWidth: 985,
          m: '0 auto 96px',
          pt: 4,
          px: { mobile: 2, tablet: 3 }
        }}
      >
        <Typography variant="overline">
          <FormattedMessage
            defaultMessage="Step {currentStep}/{totalSteps}"
            id="BrVAMy"
            values={{
              currentStep: currentIndex + 1,
              totalSteps: 2
            }}
          />
        </Typography>
        <LinearProgress
          sx={{
            maxWidth: 572,
            width: 1
          }}
          value={((currentIndex + 1) / 2) * 100}
          variant="determinate"
        />
        <Typography
          component="h1"
          sx={{
            maxWidth: 572,
            textAlign: 'center'
          }}
          variant="h2"
        >
          {prop('title', goal)}
        </Typography>
        <Typography
          sx={{
            maxWidth: 572,
            textAlign: 'center'
          }}
          variant="subtitle1"
        >
          {prop('subtitle', currentStep)}
        </Typography>
        {equals(currentIndex, 0) && <StepsForm goal={goal} setGoal={setGoal} />}
        {equals(currentIndex, 1) && <DueDate goal={goal} setGoal={setGoal} />}
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            bgcolor: 'surface.container',
            bottom: 0,
            justifyContent: 'space-between',
            position: 'fixed',
            px: { mobile: 2, laptop: 4 },
            py: 1.5,
            right: 0,
            width: 1
          }}
        >
          <Button
            onClick={() => handleBack()}
            startIcon={<Icon>arrow_back</Icon>}
            variant="text"
          >
            Back
          </Button>
          <Button disabled={isLoading} onClick={() => onSubmit()}>
            {isLoading
              ? intl.formatMessage({
                  defaultMessage: 'Creating your goal...',
                  id: 'Hkphf5'
                })
              : currentStep.buttonText}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default PremadeGoal
