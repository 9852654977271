export const MuiChip = palette => ({
  styleOverrides: {
    avatar: {
      color: palette.surface.onSurface
    },
    filled: {
      backgroundColor: palette.surface.highest
    },
    label: {
      color: palette.surface.onSurface,
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: 0.25,
      lineHeight: 1.4
    },
    outlined: {
      backgroundColor: 'white',
      borderColor: palette.primary.light
    },
    root: {
      '& > .material-symbols-outlined': {
        fontSize: '1rem',
        marginLeft: 5,
        marginRight: -6
      },
      height: 24
    }
  }
})
