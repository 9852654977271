import { FormattedMessage, useIntl } from 'react-intl'
import { getTakeActionLinkInfo, getTakeActionSubtitle } from './const'
import { or, isNil, reduce, propEq, prop, not, find } from 'ramda'
import { useGetPickupCardsQuery } from 'api/cardsApi'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PickUpWhereYouLeftOffItem from './PickUpWhereYouLeftOffItem'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import Icon from 'components/icon/Icon'

const PickUpWhereYouLeftOff = () => {
  const { hide_resources, app_type } = useSelector(prop('school'))
  const { is_secondary_user, userDomains } = useSelector(prop('user'))
  const intl = useIntl()

  const { data: cardData } = useGetPickupCardsQuery()

  const subdomains = reduce(
    (subdomains, domain) => subdomains.concat(domain.subdomains),
    [],
    userDomains
  )
  const getAdviceSubdomain = find(
    propEq('id', prop('domain_id', prop('get_advice', cardData))),
    subdomains
  )
  const takeActionSubdomain = find(
    propEq('id', prop('domain_id', prop('take_action', cardData))),
    subdomains
  )

  const items = [
    {
      card: prop('get_advice', cardData),
      href: prop('link', getAdviceSubdomain),
      show: true,
      subtitle: not(isNil(app_type)) && (
        <FormattedMessage
          defaultMessage="{subtitle}"
          id="7pSzje"
          values={{
            subtitle: is_secondary_user
              ? 'Expert perspectives & best practices'
              : 'Pro-tips, student posts, & hacks'
          }}
        />
      )
    },
    {
      card: prop('take_action', cardData),
      href: prop(
        'to',
        getTakeActionLinkInfo(
          takeActionSubdomain,
          prop('take_action', cardData),
          intl
        )
      ),
      show: true,
      subtitle:
        not(isNil(prop('take_action', cardData))) &&
        getTakeActionSubtitle(prop('take_action', cardData), intl)
    },
    {
      card: prop('campus_resource', cardData),
      show: not(hide_resources),
      subtitle: intl.formatMessage({
        defaultMessage: 'Explore Campus Resources',
        id: 'zG41iB'
      })
    }
  ]

  return (
    <Stack alignItems="center">
      <CardGridWrapper isLoading={or(isNil(userDomains), isNil(cardData))}>
        {items.map(
          ({ show, subtitle, ...rest }) =>
            show && (
              <PickUpWhereYouLeftOffItem
                key={subtitle}
                subtitle={subtitle}
                {...rest}
              />
            )
        )}
      </CardGridWrapper>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        sx={{ alignSelf: 'center' }}
        to="/explore"
        variant="text"
      >
        <FormattedMessage defaultMessage="Explore More" id="yxJpxX" />
      </Button>
    </Stack>
  )
}

export default PickUpWhereYouLeftOff
