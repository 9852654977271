import { keyframes } from '@emotion/react'
import { Box, useTheme } from '@mui/material'
import { path } from 'ramda'

const PriorityHeroImage = () => {
  const { palette } = useTheme()

  const imageScroll = keyframes`
    from, 0%, 40%, to {
      transform: translate3d(10px,0,0);
    }

    40%, 80% {
      transform: translate3d(30px,0, 0);
    }

    80%, 100% {
      transform: translate3d(50px,0,0);
    }
`

  return (
    <Box
      sx={{
        height: '30vh',
        overflow: 'hidden',
        position: 'relative',
        width: 1,
        zIndex: 3
      }}
    >
      <Box
        sx={{
          backgroundImage: `linear-gradient(270deg, ${path(['surface', 'low'], palette)} 0%, rgba(255, 255, 255, 0) 64.79%, rgba(251, 251, 251, 0) 64.79%)`,
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          transform: 'rotate(-180deg)',
          width: '65%',
          zIndex: 5
        }}
      />
      <Box
        sx={{
          // eslint-disable-next-line sort-keys
          animation: { mobile: 'none', tablet: `${imageScroll} 6s linear` },
          animationFillMode: 'forwards',
          backgroundImage: {
            mobile: 'url(/images/dashboard-priorities/mobile/school.png)',
            // eslint-disable-next-line sort-keys
            tablet: 'url(/images/dashboard-priorities/desktop/school.png)'
          },
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // eslint-disable-next-line sort-keys
          backgroundSize: { mobile: 'contain', tablet: 'cover' },
          height: 250
        }}
      />
      <Box
        sx={{
          backgroundImage: `linear-gradient(270deg, ${path(['surface', 'low'], palette)} 0%, rgba(255, 255, 255, 0) 64.79%, rgba(251, 251, 251, 0) 64.79%)`,
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          width: '65%',
          zIndex: 5
        }}
      />
    </Box>
  )
}

export default PriorityHeroImage
