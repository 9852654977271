export const MuiCardActions = () => ({
  variants: [
    {
      props: { variant: 'flex-end' },
      style: {
        '@media(max-width: 500px)': {
          justifyContent: 'center'
        },
        justifyContent: 'flex-end'
      }
    },
    {
      props: { variant: 'center' },
      style: {
        justifyContent: 'center'
      }
    }
  ]
})
