export const MuiPagination = palette => ({
  defaultProps: {
    color: 'primary'
  },
  styleOverrides: {
    colorPrimary: {
      color: palette.secondary.tonal
    }
  }
})
