import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getSubdomainInfoFromId } from 'utils/subdomain-functions'
import { getSelfCheckQuestionFromInterests } from 'utils/self-check-functions'
import { and, isNil, not, prop, or } from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { Avatar, Container, Skeleton, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setRealityCheckQuestion } from './onboardingSlice'
import PollCard from './PollCard'

const SelfCheck = () => {
  const dispach = useDispatch()
  const { userDomains } = useSelector(prop('user'))
  const { realityCheckQuestion, selectedInterests } = useSelector(
    prop('onboarding')
  )

  const [question, setQuestion] = useState(realityCheckQuestion)

  const { data, isFetching = true } = useGetSelfCheckQuizDataQuery()

  const subdomain = getSubdomainInfoFromId(
    prop('subdomain', question),
    userDomains
  )

  useEffect(() => {
    // only calculate new question if onboarding.realityCheckQuestion
    // gets re-initialized to {} by adding/removing interests
    if (and(isNil(prop('subdomain', realityCheckQuestion)), not(isNil(data)))) {
      const question = getSelfCheckQuestionFromInterests(
        selectedInterests,
        data
      )
      setQuestion(question)
      dispach(setRealityCheckQuestion(question))
    }
  }, [data, dispach, realityCheckQuestion, selectedInterests])

  return (
    <Container maxWidth="tablet" sx={{ pt: 2 }}>
      {subdomain && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{
            mb: 3,
            width: 1
          }}
        >
          <Avatar
            src={prop('display_image', subdomain)}
            sx={{
              height: 64,
              width: 64
            }}
          />
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2">
              <FormattedMessage
                defaultMessage="Since you’re interested in"
                id="kp5KCE"
              />
            </Typography>
            <Typography fontWeight={800} variant="body2">
              {prop('onboarding_title', subdomain)}
            </Typography>
          </Stack>
        </Stack>
      )}
      {or(isFetching, isNil(prop('id', question))) ? (
        <Skeleton
          data-testid="skeleton"
          height={400}
          sx={{ borderRadius: 2 }}
          variant="rectangular"
          width="100%"
        />
      ) : (
        <PollCard pollingQuestion={question} />
      )}
    </Container>
  )
}

export default SelfCheck
