/* eslint-disable sort-keys */
import { useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TYPE_CAMPUS_GOAL } from 'utils/card-functions'
import { prop, isNil, path, compose, nthArg } from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import { Button, Pagination, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import Icon from 'components/icon/Icon'
import FilterMenu from 'components/filter/FilterMenu'
import useBuildFilters from 'components/filter/useBuildFilters'

const Explore = () => {
  const { domain } = useSelector(prop('school'))
  const { domainId, sortValue } = useBuildFilters()
  const scrollRef = useRef(null)

  const [page, setPage] = useState(1)

  const params = {
    'filter[domains]': domainId(),
    'filter[types]': TYPE_CAMPUS_GOAL,
    org_domain: domain,
    page,
    perPage: 6,
    'sort[method]': sortValue()
  }

  const { data } = useGetCardsQuery(params, {
    skip: isNil(domain)
  })

  const goals = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack spacing={5} sx={{ px: { mobile: 2, tablet: 3 } }}>
      <Stack alignItems="center" spacing={3}>
        <FilterMenu hasSortMenu hasTopicFilter />
        <CardGridWrapper>
          {goals.map(card => (
            <CardHandler card={card} key={prop('id', card)} />
          ))}
        </CardGridWrapper>
        <Pagination
          count={path(['meta', 'lastPage'], data)}
          onChange={compose(handleNav, setPage, nthArg(1))}
          page={page}
        />
      </Stack>

      <Stack
        alignItems="center"
        spacing={2}
        sx={{ mt: { mobile: 4, tablet: 8 }, px: 2 }}
      >
        <Typography sx={{ ml: 2 }} variant="h4">
          <FormattedMessage
            defaultMessage="Don't see the goal for you?"
            id="mEaq8w"
          />
        </Typography>
        <Button
          endIcon={<Icon>arrow_forward</Icon>}
          LinkComponent={Link}
          to="/goals/create/new"
          variant="text"
        >
          <FormattedMessage defaultMessage="create my own goal" id="wn1nUL" />
        </Button>
      </Stack>
    </Stack>
  )
}

export default Explore
