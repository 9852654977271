import { FormControl, ListItemText, MenuItem, Select } from '@mui/material'
import { compose, equals, path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { setRole } from 'store/authenticationSlice'
import Icon from 'components/icon/Icon'

const StudentOrStaff = () => {
  const dispatch = useDispatch()
  const role = useSelector(path(['authentication', 'role']))

  const handleChange = val => dispatch(setRole(val))

  return (
    <FormControl>
      <Select
        onChange={compose(handleChange, path(['target', 'value']))}
        renderValue={opt => opt}
        value={role}
      >
        <MenuItem value="student">
          <ListItemText primary="Student" />
          {equals(role, 'student') && <Icon>check</Icon>}
        </MenuItem>
        <MenuItem value="staff">
          <ListItemText primary="Staff" />
          {equals(role, 'stustaffdent') && <Icon>check</Icon>}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default StudentOrStaff
