/* eslint-disable quotes */
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { equals, prop, propEq } from 'ramda'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const RecommendedSubdomains = ({ subdomains = [] }) => {
  const { is_secondary_user, userDomains } = useSelector(prop('user'))

  const allSubdomains = subdomainsFromUserDomains(userDomains)
  // find base domains (sliced to show only 8)
  // for some cards in the fact/staff app type, they have both primary and secondary
  // subdomains attached to them. so if we are in secondary, we need to slice from the back not the front
  // NOTE: this only works since the ids for the secondary subdomains are higher (since they
  // were created more recently). If we add new subdomains this may fall apart....
  // Stan 1/19/2023
  const baseSubdomains = is_secondary_user
    ? subdomains.slice(subdomains.length - 8, subdomains.length)
    : subdomains.slice(0, 8)

  // filter all subdomains and return list that contains base domains
  const mappedDomains = allSubdomains.filter(sub =>
    baseSubdomains.some(propEq(prop('id', sub), 'id'))
  )

  const iconType = domainId => {
    if (equals(1, domainId)) return <Icon fill>potted_plant</Icon>
    if (equals(2, domainId)) return <Icon fill>work</Icon>
    return <Icon fill>diversity_1</Icon>
  }

  return (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{
        py: 6,
        width: 1
      }}
    >
      <Container maxWidth="tablet">
        <Stack alignItems="center" spacing={3}>
          <Stack>
            <Typography textAlign="center" variant="h4">
              <FormattedMessage
                defaultMessage="It's all connected..."
                id="mxJvGX"
              />
            </Typography>
            <Typography textAlign="center" variant="subtitle1">
              <FormattedMessage
                defaultMessage="Sometimes it's helpful to zoom out and look at the big picture. Explore other topics below."
                id="d7JSSE"
              />
            </Typography>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'center'
            }}
          >
            {mappedDomains.map(({ id, link, title }) => (
              <Button
                key={title}
                LinkComponent={Link}
                startIcon={iconType(id)}
                to={link}
              >
                {title}
              </Button>
            ))}
          </Box>
        </Stack>
      </Container>
    </Stack>
  )
}

RecommendedSubdomains.propTypes = {
  subdomains: PropTypes.arrayOf(PropTypes.shape({}))
}

export default RecommendedSubdomains
