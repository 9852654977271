import { useEffect, useState } from 'react'
import Question from './Question'
import DomainSummary from './DomainSummary'
import SelfCheckFooter from './SelfCheckFooter'
import {
  filter,
  prop,
  propEq,
  gt,
  path,
  assocPath,
  inc,
  assoc,
  or,
  isNil,
  not,
  length,
  flip,
  all,
  equals,
  isEmpty
} from 'ramda'
import { Box, Skeleton, Stack, Tab } from '@mui/material'
import getSelfCheckData from './useGetSelfCheckData'
import {
  useGetRealityScoresQuery,
  useGetSelfCheckQuizDataQuery
} from 'api/statsApi'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const getActiveTab = ({ succeed, thrive, matter }) => {
  const tabList = [succeed, thrive, matter]
  const inProgress = filter(propEq('inProgress', true), tabList)
  // in progress has 1 or more
  // return the first in progress tab
  if (gt(length(inProgress), 0)) {
    return prop('domain', inProgress[0])
  }
  // else all not in progress return succeed
  return prop('domain', succeed)
}

const SelfChecksTabList = () => {
  const { pathname } = useLocation()
  const blockTitle = 'Self Checks Block'
  const { sendEvent } = useAnalytics()
  const { interested_subdomains = [] } = useSelector(prop('user'))

  const [currentTab, setCurrentTab] = useState('succeed')

  const [selfCheckData, setSelfCheckData] = useState({})

  const { data, isFetching = true } = useGetSelfCheckQuizDataQuery()

  useEffect(() => {
    if (
      all(equals(true))([
        not(isNil(data)),
        not(isFetching),
        not(isEmpty(interested_subdomains))
      ])
    ) {
      const succeed = data.find(({ title }) => title.includes('Succeed'))
      const thrive = data.find(({ title }) => title.includes('Thrive'))
      const matter = data.find(({ title }) => title.includes('Matter'))
      setSelfCheckData({
        matter: getSelfCheckData(
          matter,
          'matter',
          'connections & purpose',
          interested_subdomains
        ),
        succeed: getSelfCheckData(
          succeed,
          'succeed',
          'career success & education',
          interested_subdomains
        ),
        thrive: getSelfCheckData(
          thrive,
          'thrive',
          'mental & physical health',
          interested_subdomains
        )
      })
    }
  }, [data, interested_subdomains, isFetching])

  useEffect(() => {
    // On initial fetch we want to show the next self check
    // that has not been completed so we run a quick check
    // then set the current tab to that uncompleted self check
    if (not(isNil(getActiveTab(selfCheckData)))) {
      setCurrentTab(getActiveTab(selfCheckData))
    }
  }, [selfCheckData])

  const handleTabChange = tab => {
    sendEvent('block_interactions', {
      block_title: blockTitle,
      click_element: 'Button',
      element_title: tab.charAt(0).toUpperCase() + tab.slice(1),
      path: window.location.pathname
    })
    setCurrentTab(tab)
  }

  const { data: stats } = useGetRealityScoresQuery(
    path([currentTab, 'question', 'id'], selfCheckData),
    {
      skip: isNil(path([currentTab, 'question', 'id'], selfCheckData))
    }
  )

  const submitQuestion = value => {
    const currentData = prop(currentTab, selfCheckData)
    // fetch stats for question now that we have answered it
    // need to add 1 here because the value is 0 indexed, but the stats are 1 indexed
    // update the context so ui updates
    const data = assocPath(
      ['userAnswer', 'score', 'score'],
      inc(value),
      currentData
    )
    // need to update the question object with the stats so the Question component knows what to
    // display
    const question = assoc('stats', stats, prop('question', currentData))
    // combine our new data with the old and set it to currentData
    const newCurrentData = assoc('question', question, data)
    // need to update the context data as well so that the state maintains between tabs
    setSelfCheckData(assoc(currentTab, newCurrentData, selfCheckData))
    // currentTab holds the domain we are on
    sendEvent('block_interactions', {
      block_title: blockTitle,
      click_element: 'Button',
      element_answer: prop(value, path(['question', 'answers'], currentData)),
      element_title: currentTab,
      path: pathname
    })
  }

  const tabs = [
    {
      keenTitle: 'Succeed',
      label: <FormattedMessage defaultMessage="Succeed" id="EzJaJ+" />,
      name: 'succeed'
    },
    {
      keenTitle: 'Thrive',
      label: <FormattedMessage defaultMessage="Thrive" id="VylkOa" />,
      name: 'thrive'
    },
    {
      keenTitle: 'Matter',
      label: <FormattedMessage defaultMessage="Matter" id="vMncJL" />,
      name: 'matter'
    }
  ]

  if (or(isNil(prop('succeed', selfCheckData)), prop('loading', selfCheckData)))
    return (
      <Stack spacing={1} sx={{ height: 385 }}>
        <Skeleton variant="text" width={64} />
        <Skeleton height={35} variant="rectangular" />
        <Skeleton variant="text" width={183} />
        <Skeleton height={48} variant="rectangular" width={250} />
        <Skeleton height={48} variant="rectangular" width={250} />
        <Skeleton height={48} variant="rectangular" width={250} />
        <Skeleton height={48} variant="rectangular" width={250} />
      </Stack>
    )

  return (
    <Box sx={{ width: 1 }}>
      <TabContext value={currentTab}>
        <Box>
          <TabList onChange={flip(handleTabChange)} selectionFollowsFocus>
            {tabs.map(({ label, name }) => (
              <Tab key={name} label={label} value={name} />
            ))}
          </TabList>
        </Box>
        {tabs.map(({ name }) => (
          <TabPanel
            key={name}
            sx={{
              px: { mobile: 0, tablet: 3 },
              py: 3
            }}
            value={name}
          >
            {path([name, 'domainSummary'], selfCheckData) ? (
              <DomainSummary
                domain={currentTab}
                key={currentTab}
                name={name}
                title={path([name, 'domainSummary', 'title'], selfCheckData)}
              />
            ) : (
              <Question
                key={currentTab + ' question'}
                question={path([name, 'question'], selfCheckData)}
                updateQuestion={submitQuestion}
                userAnswer={path([name, 'userAnswer'], selfCheckData)}
              />
            )}
            <SelfCheckFooter
              blockTitle={blockTitle}
              completed={not(
                isNil(path([name, 'domainSummary'], selfCheckData))
              )}
              domain={currentTab}
              questionAnswered={not(
                isNil(path([name, 'userAnswer', 'score'], selfCheckData))
              )}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

export default SelfChecksTabList
