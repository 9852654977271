import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  previousSearches: [],
  searchTerm: '',
  tempSearchTerm: ''
}

const searchSlice = createSlice({
  initialState,
  name: 'search',
  reducers: {
    setPreviousSearches: (state, { payload }) =>
      assoc('previousSearches', payload, state),
    setSearchTerm: (state, { payload }) => assoc('searchTerm', payload, state),
    setTempSearchTerm: (state, { payload }) =>
      assoc('tempSearchTerm', payload, state)
  }
})

export const { setPreviousSearches, setSearchTerm, setTempSearchTerm } =
  searchSlice.actions

export default searchSlice.reducer
