import { FormattedMessage } from 'react-intl'
import { Box, Slide, Typography } from '@mui/material'
import PriorityHeroImage from './PriorityHeroImage'

const EmptyPrioritiesHero = () => (
  <Slide direction="left" in>
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={2}
    >
      <PriorityHeroImage />

      <Typography mb="16px" textAlign="center" variant="body1">
        <FormattedMessage
          defaultMessage="You currently have <b>0</b> priorities set"
          id="j/TDh/"
          values={{
            b: chunks => <b>{chunks}</b>
          }}
        />
      </Typography>
    </Box>
  </Slide>
)

export default EmptyPrioritiesHero
