export const MuiTooltip = palette => ({
  styleOverrides: {
    tooltip: {
      '& > .MuiTypography-root': {
        color: palette.surface.onSurface
      },
      backgroundColor: palette.surface.high
    }
  }
})
