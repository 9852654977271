import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  showProfile: false,
  showStaff: false
}

const alertSlice = createSlice({
  initialState,
  name: 'alert',
  reducers: {
    setShowProfile: (state, { payload }) =>
      assoc('showProfile', payload, state),
    setShowStaff: (state, { payload }) => assoc('showStaff', payload, state)
  }
})

export const { setShowProfile, setShowStaff } = alertSlice.actions

export default alertSlice.reducer
