/* eslint-disable sort-keys */
import { Divider, Stack } from '@mui/material'
import SearchCard from './SearchCard'

const quickPrompts = [
  'Study Tips',
  'Making Friends',
  'Stress',
  'Identity',
  'Finances',
  'Procrastination',
  'Clubs',
  'Help'
]

const EmptyState = () => (
  <Stack spacing={3.5}>
    <Divider />
    <Stack
      direction="row"
      sx={{
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
        maxWidth: 1036,
        px: 2,
        width: 1
      }}
    >
      {quickPrompts.map((title, idx) => (
        <SearchCard index={idx} key={idx} title={title} />
      ))}
    </Stack>
  </Stack>
)

export default EmptyState
