/* eslint-disable react/jsx-key */
import { FormattedMessage } from 'react-intl'

const Thoughts = [
  <FormattedMessage defaultMessage="Racing thoughts" id="AeNl74" />,
  <FormattedMessage defaultMessage="Difficulty concentrating" id="zhul+O" />,
  <FormattedMessage defaultMessage="Mind goes blank" id="4AoZnv" />,
  <FormattedMessage defaultMessage="Low motivation" id="6BsZHK" />,
  <FormattedMessage defaultMessage="Jumping to conclusions" id="iyu/NV" />,
  <FormattedMessage defaultMessage="Anger or frustration" id="/AwcMT" />,
  <FormattedMessage
    defaultMessage="Fixate on drinking or other coping mechanisms"
    id="T049TB"
  />,
  <FormattedMessage defaultMessage="Sadness" id="YK9+qA" />,
  <FormattedMessage defaultMessage="Feeling overwhelmed" id="qmjbCf" />,
  <FormattedMessage defaultMessage="Other (What else?)" id="/dKV3I" />,
]

export default Thoughts
