import {
  Container,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { path, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { setGratitude } from './destressSlice'
import { useDispatch, useSelector } from 'react-redux'

const adornmentStyle = {
  '& > .MuiTypography-root': {
    color: 'surface.onSurface'
  }
}

const Gratitude = () => {
  const dispatch = useDispatch()
  const { gratitudes } = useSelector(prop('destress'))

  return (
    // eslint-disable-next-line sort-keys
    <Container maxWidth="tablet">
      <Stack alignItems="center" spacing={3}>
        <Slide direction="left" in>
          <Stack spacing={3} sx={{ maxWidth: 808, textAlign: 'center' }}>
            <Typography component="h1" variant="h2">
              <FormattedMessage
                defaultMessage="When stressed, our thoughts can get more twisted than a pretzel. Noticing the good can help."
                id="3rY1Uw"
              />
            </Typography>
            <Typography variant="subitle1">
              <FormattedMessage
                defaultMessage="List out three good things in your world right now. They can be big or small. Heck, they can even just be things you like."
                id="a9RzQr"
              />
            </Typography>
          </Stack>
        </Slide>
        <Slide direction="left" in timeout={500}>
          <Stack spacing={2} sx={{ maxWidth: 600, width: 1 }}>
            <TextField
              inputProps={{ 'aria-label': 'Gratitude 1', maxLength: 100 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={adornmentStyle}>
                    1.
                  </InputAdornment>
                )
              }}
              maxRows={2}
              multiline
              onChange={e =>
                dispatch(
                  setGratitude({ id: 1, val: path(['target', 'value'], e) })
                )
              }
              value={prop(1, gratitudes)}
            />
            <TextField
              inputProps={{ 'aria-label': 'Gratitude 2', maxLength: 100 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={adornmentStyle}>
                    2.
                  </InputAdornment>
                )
              }}
              maxRows={2}
              multiline
              onChange={e =>
                dispatch(
                  setGratitude({ id: 2, val: path(['target', 'value'], e) })
                )
              }
              value={prop(2, gratitudes)}
            />
            <TextField
              inputProps={{ 'aria-label': 'Gratitude 3', maxLength: 100 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={adornmentStyle}>
                    3.
                  </InputAdornment>
                )
              }}
              maxRows={2}
              multiline
              onChange={e =>
                dispatch(
                  setGratitude({ id: 3, val: path(['target', 'value'], e) })
                )
              }
              value={prop(3, gratitudes)}
            />
          </Stack>
        </Slide>
      </Stack>
    </Container>
  )
}

export default Gratitude
