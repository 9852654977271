import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

// API
import { api } from 'api/baseQuery'

// Slices
import alert from 'components/alert/alertSlice'
import authentication from 'store/authenticationSlice'
import destress from 'pages/destress/components/destressSlice'
import filter from 'components/filter/filterSlice'
import history from './historySlice'
import modal from 'components/modals/modalSlice'
import onboarding from 'pages/onboarding/components/onboardingSlice'
import school from 'components/school/schoolSlice'
import search from 'pages/search/searchSlice'
import sidebar from 'components/sidebar/sidebarSlice'
import theme from './themeSlice'
import user from './userSlice'

const reducer = {
  alert,
  authentication,
  destress,
  filter,
  history,
  modal,
  onboarding,
  school,
  search,
  sidebar,
  theme,
  user,
  [api.reducerPath]: api.reducer
}

export const middleware = getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false
  }).concat(api.middleware)

export const store = configureStore({
  middleware,
  reducer
})

setupListeners(store.dispatch)
