/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { Box, Button, Stack, Typography } from '@mui/material'
import { any, equals } from 'ramda'
import { Link, useLocation } from 'react-router-dom'

const links = [
  {
    href: '/accessibility',
    show: process.env.REACT_APP_PUBLIC_ACCESSIBILITY_DOC,
    text: <FormattedMessage defaultMessage="Accessibility" id="fgCBG8" />
  },
  {
    href: '/privacy-policy',
    text: <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
  },
  {
    href: '/terms-and-conditions',
    text: <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
  },
  {
    href: '/feedback',
    text: <FormattedMessage defaultMessage="Website Feedback" id="1678y9" />
  }
]

const Footer = () => {
  const thedate = new Date()
  const year = thedate.getFullYear()
  const { pathname } = useLocation()

  const anyCheck = [
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('feedback'),
    pathname.includes('public'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('referral'),
    pathname.includes('redirect'),
    pathname.includes('logout'),
    pathname.includes('profile'),
    pathname.includes('goals/create'),
    pathname.includes('goals/edit'),
    pathname.includes('public'),
    pathname.includes('about'),
    pathname.includes('destress'),
    pathname.includes('404'),
    pathname.includes('sso'),
    pathname.includes('email'),
    pathname.includes('unsubscribe'),
    pathname.includes('self-checks/succeed'),
    pathname.includes('self-checks/thrive'),
    pathname.includes('self-checks/matter')
  ]

  if (any(equals(true))(anyCheck)) return null

  return (
    <Stack
      component="footer"
      sx={{
        alignItems: 'center',
        bgcolor: 'surface.base',
        gap: 2,
        position: 'relative',
        px: 2,
        py: 3
      }}
    >
      <Typography component="span" textAlign="center" variant="subtitle2">
        <FormattedMessage
          defaultMessage="© {year} by Grit Digital Health, LLC. Grit does not claim copyright in the third party materials included on this site."
          id="cOrKqx"
          values={{
            c: chunks => <span>{chunks}</span>,
            year: year
          }}
        />
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            mobile: '1fr',
            tablet: 'repeat(2, 1fr)',
            laptop: 'repeat(4, 1fr)'
          },
          justifyItems: 'center',
          width: { mobile: 1, tablet: 'unset' }
        }}
      >
        {links.map(
          ({ href, show = true, text }) =>
            show && (
              <Button
                key={href}
                LinkComponent={Link}
                sx={{ px: 2 }}
                to={href}
                variant="text"
              >
                {text}
              </Button>
            )
        )}
      </Box>
    </Stack>
  )
}

export default Footer
