export const MuiCheckbox = palette => ({
  styleOverrides: {
    colorPrimary: {
      color: palette.surface.onSurface
    },
    root: {
      ':hover': {
        backgroundColor: palette.card.hover
      }
    }
  }
})
