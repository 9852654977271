import { useGetCardQuery } from 'api/cardsApi'
import { useParams } from 'react-router-dom'
import { Container, Skeleton, Stack } from '@mui/material'
import Article from './components/Article'

const ArticlePage = () => {
  const { id } = useParams()

  const { data, isFetching } = useGetCardQuery(id)

  if (isFetching)
    return (
      <Container
        sx={{
          // eslint-disable-next-line sort-keys
          pt: { mobile: 4, tablet: 8 },
          width: 1
        }}
      >
        <Stack sx={{ width: 1 }}>
          <Skeleton variant="text" width={135} />
          <Skeleton
            height={63}
            sx={{ alignSelf: 'center' }}
            variant="text"
            width="50%"
          />
          <Stack spacing={2}>
            <Skeleton
              height={300}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
            <Skeleton
              height={150}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
            <Skeleton
              height={150}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
            <Skeleton
              height={150}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
          </Stack>
        </Stack>
      </Container>
    )

  return <Article card={data} />
}

export default ArticlePage
