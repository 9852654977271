import {
  assoc,
  prop,
  defaultTo,
  isNil,
  not,
  propEq,
  equals,
  all,
  path,
  and,
  isEmpty
} from 'ramda'
import { sanitizeUrl } from './helpers'

// NOTE: This needs to be manually kept up to date
// with the card types on the BE :(
export const TYPE_ASSESSMENT = 1
export const TYPE_RESOURCE = 2
export const TYPE_FACT_TIP = 3
export const TYPE_LIFE_LESSON = 4
export const TYPE_TOOL = 5
export const TYPE_EVENT = 6
export const TYPE_STORY = 7
export const TYPE_CAMPUS_GOAL = 8
export const TYPE_NOD_CHALLENGE = 9
export const TYPE_GEO_RESOURCE = 11
export const TYPE_TIP = 12
export const TYPE_SKILL = 13
export const TYPE_POLL = 99

export function getSectionCategorySlugFromId(
  category,
  isStudentContent,
  domains,
  userDomains
) {
  const domain = isStudentContent
    ? domains.find(propEq(category, 'id'))
    : userDomains.find(propEq(category, 'id'))
  return prop('frontend_url_slug', domain)
}

export function getSubdomainFromSection(
  id,
  category,
  isStudentContent,
  domains,
  userDomains
) {
  const domain = isStudentContent
    ? domains.find(propEq(category, 'id'))
    : userDomains.find(propEq(category, 'id'))
  const subdomains = defaultTo([], prop('subdomains', domain)) //Because of the Secondary AppType issue mentioned below in getVariablesForCardPreparation();
  return subdomains.find(propEq(id, 'id'))
}

function getCleanDescription(desc, length) {
  if (isNil(desc)) return
  const split = desc.substring(0, length).split(' ')
  const splitpop = split.splice(0, split.length - 1)
  return splitpop.join(' ') + '...'
}

export function getVariablesForCardPreparation(
  card,
  isStudentContent,
  isPublic = false
) {
  //Note: There is a bigger problem here related to Secondary AppTypes. If there is a card that is shared on both Primary and Secondary appTypes,
  //their section_id might be set to the Primary App's section (ie 1) but we don't know that for the secondary user so it looks in the userDomains
  //which doesn't have that section in it.

  //Because sometimes card objects don't have all the data in them, like recommendedCards
  const copy = isNil(prop('section_id', card))
    ? assoc('section_id', 2, card)
    : card

  const type = prop('type', copy)
  const photoDescription = prop('photo_description', copy)
  const videoDescription = prop('video_description', copy)

  const videoImage = defaultTo(
    prop('video_thumbnail_url', copy),
    prop('video_thumb_url', copy)
  )
  const title = prop('title', copy)
  const url = not(isNil(title)) ? sanitizeUrl(title) : title

  //This is terrible but it's currently how we get the correct domain/subdomain from the id since they change for different apptypes.
  const internalUrl = () => {
    if (equals(type, TYPE_CAMPUS_GOAL)) return `/goals/new/${prop('id', copy)}`
    if (equals(type, TYPE_NOD_CHALLENGE))
      return `${isPublic ? '/public' : ''}/article/${prop(
        'id',
        copy
      )}/${url}/challenge`
    if (
      all(equals(true))([
        isStudentContent,
        not(equals(type, TYPE_CAMPUS_GOAL)),
        not(equals(type, TYPE_NOD_CHALLENGE))
      ])
    )
      return `/students${internalUrl}`
    return `${isPublic ? '/public' : ''}/article/${prop('id', copy)}/${url}`
  }

  const articleDescription = () => {
    if (and(not(isNil(videoDescription)), not(isEmpty(videoDescription))))
      return videoDescription
    if (and(not(isNil(photoDescription)), not(isEmpty(photoDescription))))
      return photoDescription
    return getCleanDescription(copy.facts_tips_copy, 100)
  }

  return {
    articleDescription: articleDescription(),
    internalUrl: internalUrl(),
    photo: defaultTo(videoImage, path(['photo', 'large_url'], copy))
  }
}
