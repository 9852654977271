import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { and, not, isNil, gte, assoc, append, prop, inc, length } from 'ramda'
import { useUpdateGoalMutation } from 'api/goalsApi'
import {
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography
} from '@mui/material'

// we pass the state and the setter down so that we can update the steps in the ui properly
const AddGoalStepDisplay = ({ goal }) => {
  const intl = useIntl()
  const [showEdit, setShowEdit] = useState(false)
  const [stepInfo, setStepInfo] = useState(null)

  const [updateGoal] = useUpdateGoalMutation()

  const handleSubmit = () => {
    if (and(not(isNil(stepInfo)), gte(length(stepInfo), 1))) {
      const updatedGoal = assoc(
        'steps',
        append(
          {
            completed: false,
            id: null,
            name: stepInfo,
            // if the step doesnt have an order it's a new step so we
            // give it the order of the end of the array
            order: stepInfo.order ? stepInfo.order : goal.steps.length
          },
          prop('steps', goal)
        ),
        goal
      )
      updateGoal({ body: updatedGoal, id: prop('id', goal) })
    }
    // reset state here because the component does not rerender on submit.
    // This means it will have "hidden" state from previous
    // submit until a new input is given.
    setStepInfo(null)
    setShowEdit(false)
  }
  return (
    <Stack alignItems="flex-start" p={2} width="100%">
      {showEdit ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'center',
            width: 1
          }}
        >
          <FormControl fullWidth>
            <Typography htmlFor="stepInput" variant="subtitle1">
              <FormattedMessage
                defaultMessage="Step {length}"
                id="9uwNm+"
                values={{ length: inc(length(prop('steps', goal))) }}
              />
            </Typography>
            <TextField
              fullWidth
              onChange={e => setStepInfo(e.target.value)}
              placeholder={intl.formatMessage({
                defaultMessage: 'Add your step',
                id: 'G+Nl9Q'
              })}
              value={stepInfo}
            />
          </FormControl>
          <Button onClick={() => handleSubmit()}>
            <FormattedMessage defaultMessage="Save" id="jvo0vs" />
          </Button>
        </Box>
      ) : (
        <Button onClick={() => setShowEdit(true)} variant="outlined">
          <FormattedMessage defaultMessage="Add your own step" id="Sphc64" />
        </Button>
      )}
    </Stack>
  )
}

AddGoalStepDisplay.propTypes = {
  goal: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
}

export default AddGoalStepDisplay
