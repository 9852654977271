import { Card, CardContent, Skeleton, Stack } from '@mui/material'

const SmallLoadingCard = () => (
  <Card>
    <CardContent
      sx={{
        ':last-child': {
          pb: 1
        },
        p: 1
      }}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        <Skeleton
          height={78}
          sx={{ borderRadius: 1.5 }}
          variant="rectangular"
          width={78}
        />
        <Stack spacing={1}>
          <Skeleton
            height={23}
            sx={{ borderRadius: 7.5 }}
            variant="rounded"
            width={110}
          />
          <Skeleton height={20} variant="rectangular" width="100%" />
          <Skeleton height={20} variant="rectangular" width="100%" />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
)

export default SmallLoadingCard
