const lightPalette = {
  brand: {
    bedFrame: '#272521',
    cheeto: '#F88621',
    cleanSocks: '#FAF4EA',
    electricLavendar: '#C1A5E5',
    iceBlue: '#8BD3DF',
    monsterra: '#00886C',
    puke: '#BFB63B',
    rage: '#F5432A'
  },
  button: {
    outlined: {
      background: '#C4CED0',
      disabled: 'rgba(60, 57, 52, 0.12)',
      dragged: '#D7DDD8',
      focused: '#E0E4DE',
      hover: '#EAEBE4',
      onSurface: '#2B6772',
      textDisabled: 'rgba(60, 57, 52, 0.38))'
    },
    primary: {
      background: '#2B6772',
      disabled: 'rgba(60, 57, 52, 0.12)',
      dragged: '#2F6269',
      focused: '#275B62',
      hover: '#1E545B',
      onSurface: '#FFF',
      textDisabled: 'rgba(60, 57, 52, 0.38)'
    },
    text: {
      background: '#E0EAEC',
      disabled: 'rgba(60, 57, 52, 0.38)',
      dragged: 'rgba(43, 103, 114, 0.16)',
      focused: 'rgba(43, 103, 114, 0.12)',
      hover: 'rgba(43, 103, 114, 0.08)',
      onSurface: '#2B6772',
      textDisabled: 'rgba(60, 57, 52, 0.38))'
    },
    tonal: {
      background: '#E0EAEC',
      disabled: 'rgba(60, 57, 52, 0.12)',
      dragged: '#BFD3D7',
      focused: '#C7D9DC',
      hover: '#CFDFE1',
      onSurface: '#081E23',
      textDisabled: 'rgba(60, 57, 52, 0.38)'
    }
  },
  card: {
    background: '#FAF4EA',
    dragged: '#EDE5D9',
    focused: '#F0E9DD',
    hover: '#F4EDE1'
  },
  error: {
    hover: '#FDAC9E1F',
    main: '#B11D13',
    onSurface: '#FAF4EA'
  },
  primary: {
    container: '#BFE2E9',
    main: '#211F1C',
    onPrimary: '#FFF',
    swatch: '#2B6772',
    tonal: '#5D6566',
    tonalContainer: '#E0EAEC'
  },
  secondary: {
    container: '#EADDF7',
    main: '#211F1C',
    onSecondary: '#FFF',
    swatch: '#644491',
    tonal: '#F6EAFE',
    tonalContainer: '#E7DAF2'
  },
  surface: {
    base: '#FFFCF7',
    container: '#FAF4EA',
    high: '#F4EDE2',
    highest: '#EEE7DB',
    low: '#FCF8F0',
    lowest: '#FFF',
    onSurface: '#211F1C'
  }
}

export default lightPalette
