import { Card, CardContent, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { defaultTo, find, isNil, map, path, prop, propEq } from 'ramda'
import { useSelector } from 'react-redux'
import { subdomainsFromUserDomains } from 'utils/helpers'

import UnsetGoalContent from 'components/cards/UnsetGoalContent'

const ExploreGoalCard = ({ card = null }) => {
  const { userDomains } = useSelector(prop('user'))
  const isDashboard = isNil(prop('campus_goal', card))

  const subdomainId = path(['campus_goal', 'subdomain_id'], card)
  const title = prop('title', card)
  // If dashboard then the card data is way diff
  const id = isDashboard
    ? path(['active_goal', 'id'], card)
    : path(['campus_goal', 'card_id'], card)
  // on dashboard map the steps to just name
  const steps = isDashboard
    ? map(prop('name'), path(['active_goal', 'steps'], card))
    : path(['campus_goal', 'steps'], card)
  const img = defaultTo(
    '/vectors/completed-goal.svg',
    path(['campus_goal', 'card_image'], card)
  )

  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomain = find(propEq(subdomainId, 'id'))(subdomains)

  return (
    <Card>
      <CardContent
        component={Stack}
        spacing={1}
        sx={{
          height: 1,
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="overline">{prop('title', subdomain)}</Typography>
        <Typography variant="h4">{title}</Typography>
        <UnsetGoalContent
          id={id}
          img={img}
          steps={steps}
          title={title}
          useImagePath={isDashboard}
        />
      </CardContent>
    </Card>
  )
}

ExploreGoalCard.propTypes = {
  card: PropTypes.shape({}).isRequired
}

export default ExploreGoalCard
