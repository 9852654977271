import { useIntl } from 'react-intl'
import GetStartedItem from './GetStartedItem'
import {
  svg_get_started_assessment,
  svg_get_started_explore,
  svg_get_started_resource,
  svg_get_started_profile
} from './getStartedIcons'
import { __, isNil, cond, equals, always, T, prop, any } from 'ramda'
import { Stack } from '@mui/material'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { useSelector } from 'react-redux'

const GetStarted = () => {
  const intl = useIntl()
  const { app_type, hide_resources } = useSelector(prop('school'))
  const {
    has_viewed_campus_resources,
    has_viewed_a_priority,
    has_viewed_profile,
    is_secondary_user
  } = useSelector(prop('user'))

  const { data } = useGetSelfCheckQuizDataQuery(__, {
    refetchOnMountOrArgChange: true
  })

  if (isNil(data)) return null

  const isCompleted = prop('completed')

  const succeed = data.find(({ title }) => title.includes('Succeed'))
  const thrive = data.find(({ title }) => title.includes('Thrive'))
  const matter = data.find(({ title }) => title.includes('Matter'))

  const hasFinishedSelfCheck = any(equals(true))([
    isCompleted(succeed),
    isCompleted(thrive),
    isCompleted(matter)
  ])

  const localResourceCopy = cond([
    [
      equals('schoool'),
      always(
        intl.formatMessage(
          { defaultMessage: '{copy}', id: 'f7nt7p' },
          {
            copy: is_secondary_user
              ? 'Explore faculty/staff resources on campus'
              : 'Explore campus resources'
          }
        )
      )
    ],
    [
      equals('business'),
      always(
        intl.formatMessage({
          defaultMessage: 'Explore HR and EAP resources',
          id: 'Gkb5fj'
        })
      )
    ],
    [
      T,
      always(
        intl.formatMessage({
          defaultMessage: 'Explore community resources',
          id: '1nutn2'
        })
      )
    ]
  ])

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'stretch',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: {
          mobile: 'center',
          tablet: 'unset',
          // eslint-disable-next-line sort-keys
          monitor: 'center'
        },
        maxWidth: { mobile: 1008, monitor: '70rem' },
        width: 1
      }}
    >
      <GetStartedItem
        checked={has_viewed_a_priority}
        icon={svg_get_started_explore}
        link="/explore"
        text={intl.formatMessage({
          defaultMessage: 'Check out a priority',
          id: '5Gi/hY'
        })}
        time="2 min"
      />
      <GetStartedItem
        checked={hasFinishedSelfCheck}
        icon={svg_get_started_assessment}
        link="/self-checks"
        text={intl.formatMessage({
          defaultMessage: 'Take a self-check',
          id: '5epVIn'
        })}
        time="5 min"
      />
      {hide_resources ? (
        <GetStartedItem
          checked={has_viewed_profile}
          icon={svg_get_started_profile}
          link="/profile?active=about"
          text={intl.formatMessage({
            defaultMessage: 'Personalize your profile',
            id: 'P/guA/'
          })}
          time="2 min"
        />
      ) : (
        <GetStartedItem
          checked={has_viewed_campus_resources}
          icon={svg_get_started_resource}
          link="/resources"
          text={localResourceCopy(app_type)}
          time="3 min"
        />
      )}
    </Stack>
  )
}

export default GetStarted
