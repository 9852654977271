import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import { useGetCardsQuery } from 'api/cardsApi'
import { useMemo } from 'react'
import {
  always,
  and,
  assoc,
  equals,
  evolve,
  includes,
  isNil,
  path,
  prop,
  reject,
  toLower
} from 'ramda'
import { TYPE_SKILL, TYPE_TIP } from 'utils/card-functions'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardLoader from 'components/cards/CardLoader'

const NextStepCard = ({ title, subdomainId }) => {
  const { domain, tips_enabled, goals_enabled } = useSelector(prop('school'))

  const cardParams = useMemo(() => {
    const base = {
      'filter[campus_resource]': null,
      'filter[subdomains]': subdomainId,
      'filter[types]': null,
      org_domain: domain,
      perPage: 1
    }
    const coinFlip = Math.floor(Math.random() * 2)
    if (includes('action', toLower(title))) {
      return reject(
        isNil,
        assoc('filter[types]', goals_enabled ? coinFlip : TYPE_SKILL, base)
      )
    }
    if (includes('advice', toLower(title))) {
      // get advice will coin flip between resource and tips if tips are enabled, otherwise just a resource
      return reject(
        isNil,
        evolve(
          {
            'filter[campus_resource]': always(0),
            'filter[types]':
              and(tips_enabled, equals(0, coinFlip)) && always(TYPE_TIP)
          },
          base
        )
      )
    }
    if (includes('connect', toLower(title))) {
      return reject(isNil, assoc('filter[campus_resource]', 1, base))
    }
  }, [subdomainId, domain, title, goals_enabled, tips_enabled])

  const { data, isFetching = true } = useGetCardsQuery(cardParams, {
    skip: isNil(domain)
  })

  return (
    // eslint-disable-next-line sort-keys
    <Stack spacing={1}>
      <Typography variant="subtitle1">{title}</Typography>
      {isFetching ? (
        <CardLoader small />
      ) : (
        <CardHandler
          blockTitle="Self Check Results"
          card={path(['data', 0], data)}
          small
        />
      )}
    </Stack>
  )
}

NextStepCard.propTypes = {
  subdomainId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default NextStepCard
