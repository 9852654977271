import { Box, Button, Container, Slide, Stack, Typography } from '@mui/material'
import { useSendEmailMutation } from 'api/userApi'
import { not, prop } from 'ramda'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { setEmailOptIn } from './destressSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { useFavoriteMutation } from 'api/cardsApi'

const Email = () => {
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const dispatch = useDispatch()
  const { emailOptIn, resources } = useSelector(prop('destress'))

  const [sendEmail] = useSendEmailMutation()
  const [favorite] = useFavoriteMutation()

  useEffect(() => {
    resources.forEach(id =>
      favorite({
        card_id: id,
        isFavorite: true
      })
    )
  }, [resources, favorite])

  const handleAnalytics = val => {
    if (val) sendEmail({ key: 'Destress Email' })
    sendEvent('Interactive Content', {
      email_opt_in: val,
      module_name: 'Destress',
      path: pathname,
      step_name: 'Email Opt In',
      trigger: 'Click'
    })
  }

  return (
    <Container maxWidth="monitor">
      <Stack alignItems="center" spacing={3}>
        <Slide direction="down" in timeout={500}>
          <Typography component="h1" variant="h2">
            <FormattedMessage
              defaultMessage="De-Stressing in Progress!"
              id="p3Apzz"
            />
          </Typography>
        </Slide>
        <Slide direction="up" in timeout={500}>
          <Stack
            alignItems="center"
            spacing={2}
            sx={{ maxWidth: 582, textAlign: 'center' }}
          >
            <Box
              component="img"
              src="/vectors/interactive-content/sun.svg"
              sx={{ maxWidth: 334 }}
            />

            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage="We'll keep these resources handy on your Favorites page, and better yet, send you a personalized reminder email to keep you on track."
                id="4tQ9WC"
              />
            </Typography>
            <Button
              aria-label={
                emailOptIn ? 'selected, sign me up' : 'un-selected, sign me up'
              }
              onClick={() => {
                handleAnalytics(not(emailOptIn))
                dispatch(setEmailOptIn(not(emailOptIn)))
              }}
              startIcon={
                emailOptIn ? (
                  <Icon>check_box</Icon>
                ) : (
                  <Icon>check_box_outline_blank</Icon>
                )
              }
            >
              Yes, please!
            </Button>
          </Stack>
        </Slide>
      </Stack>
    </Container>
  )
}

export default Email
