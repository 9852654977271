import { useAnalytics } from 'hooks'
import Quicklinks from 'components/quick-links/QuickLinks'
import { isNil, or, prop } from 'ramda'
import { useEffect } from 'react'
import { useGetDocumentQuery } from 'api/legalApi'
import { useSelector } from 'react-redux'

const TermsAndConditions = () => {
  const { terms_and_conditions_id } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  useEffect(() => {
    sendEvent('tou_pp_viewed', {})
  }, [sendEvent])

  const { data, isFetching } = useGetDocumentQuery(terms_and_conditions_id, {
    skip: isNil(terms_and_conditions_id)
  })

  if (or(isFetching, isNil(data))) return null

  return <Quicklinks rawHtml={prop('statement', data)} />
}

export default TermsAndConditions
