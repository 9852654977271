import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Avatar, Button, Stack, Typography } from '@mui/material'
import { useUpdateProfilePhotoMutation } from 'api/profileApi'
import { any, equals, isNil, lt, not, path, prop } from 'ramda'
import { useSelector } from 'react-redux'
import Toast from 'components/toast/Toast'

const ProfileAvatar = () => {
  const intl = useIntl()
  const { avatar } = useSelector(prop('user'))

  const [avatarFile, setAvatarFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [message, setErrorMessage] = useState(null)
  const [showFileUpload, setShowFileUpload] = useState(false)

  const inputRef = useRef(null)

  const [updateUser, { isLoading, isSuccess }] = useUpdateProfilePhotoMutation()

  useEffect(() => {
    if (isSuccess) {
      setAvatarFile(null)
      setShowFileUpload(false)
      setPreviewImage(null)
    }
  }, [isSuccess])

  const handleChange = e => {
    // reset error message
    setErrorMessage(null)
    const files = path(['target', 'files'], e)
    const file = prop(0, files)
    // more than 5mb
    if (lt(prop('size', file), 5000)) {
      return setErrorMessage(
        <FormattedMessage
          defaultMessage="Sorry, image is too large (must be under 5mb)"
          id="Gs1L9/"
        />
      )
    }
    // less than 5mb
    setAvatarFile(file)
    setPreviewImage(URL.createObjectURL(file))
  }

  return (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{
        maxWidth: 432,
        my: 2
      }}
    >
      <Avatar
        alt={intl.formatMessage({
          defaultMessage: 'Your Profile image',
          id: 'X6Y8wH'
        })}
        src={previewImage ?? avatar ?? '/images/DefaultProfile.jpg'}
        variant="profile"
      />
      <Stack
        spacing={3}
        sx={{
          justifyContent: 'center'
        }}
      >
        {not(showFileUpload) ? (
          <Button onClick={() => setShowFileUpload(true)} variant="outlined">
            <FormattedMessage
              defaultMessage="upload profile image"
              id="D1VGX+"
            />
          </Button>
        ) : (
          <Stack alignItems="center" spacing={2}>
            <Stack alignItems="center" direction="row" spacing={1.5}>
              <Button onClick={() => inputRef.current.click()}>
                <FormattedMessage defaultMessage="Select" id="kQAf2d" />
              </Button>
              <input
                accept=".jpg, .png"
                aria-label={intl.formatMessage({
                  defaultMessage: 'Upload Profile Image input',
                  id: 't0HXnB'
                })}
                onChange={handleChange}
                ref={inputRef}
                style={{
                  display: 'none'
                }}
                type="file"
              />
              <Typography variant="subtitle1">
                <FormattedMessage defaultMessage=".jpg or .png" id="udur/K" />
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button
                disabled={any(equals(true))([
                  isLoading,
                  not(isNil(message)),
                  isNil(previewImage)
                ])}
                onClick={() => {
                  const body = new FormData()
                  body.append('file', avatarFile)
                  updateUser(body)
                }}
              >
                {isLoading ? (
                  <FormattedMessage
                    defaultMessage="Submitting Image..."
                    id="8Z3tch"
                  />
                ) : (
                  <FormattedMessage defaultMessage="Submit Image" id="ovZDQ/" />
                )}
              </Button>
              <Button
                onClick={() => {
                  setErrorMessage(null)
                  setAvatarFile(null)
                  setShowFileUpload(false)
                  setPreviewImage(null)
                }}
                variant="outlined"
              >
                <FormattedMessage defaultMessage="cancel" id="cqZqGK" />
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Toast isShown={not(isNil(message))} message={message} type="error" />
    </Stack>
  )
}

export default ProfileAvatar
