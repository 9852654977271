import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { equals, not } from 'ramda'
import { Box, LinearProgress, Stack, Typography } from '@mui/material'

const ProgressBar = ({ completed, total, label = null, noLabel = false }) => {
  const getPercentage = (step, totalSteps) =>
    Math.round((step / totalSteps) * 100)

  const percent = equals(0, total) ? 100 : getPercentage(completed, total)

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: 1
        }}
      >
        <LinearProgress value={percent} variant="determinate" />
      </Box>
      {not(noLabel) && (
        <Typography
          aria-label={label}
          ml="8px"
          variant="subtitle1"
          whiteSpace="nowrap"
        >
          <FormattedMessage
            defaultMessage="{label}"
            id="j8y+qc"
            values={{ label: label || `${percent}%` }}
          />
        </Typography>
      )}
    </Stack>
  )
}

ProgressBar.propTypes = {
  barColor: PropTypes.string,
  completed: PropTypes.number.isRequired,
  label: PropTypes.any,
  noLabel: PropTypes.bool,
  selected: PropTypes.bool,
  total: PropTypes.number.isRequired
}

export default ProgressBar
