import { useIntl } from 'react-intl'
import DashboardFooterItem from './DashboardFooterItem'
import { Stack } from '@mui/material'
import { and, any, equals, not, prop } from 'ramda'
import { useSelector } from 'react-redux'

const Footer = () => {
  const { enable_goals, enable_tips, hideResources } = useSelector(
    prop('school')
  )
  const { app_type, is_secondary_user } = useSelector(prop('user'))
  const enableTips = prop('enable_tips', app_type)
  const intl = useIntl()

  const showTips = and(enable_tips, enableTips)
  // the business logic here is that we show the original 3 if they are enabled
  // the original three being: goals, tips (stories), and campus resources
  // if either goals or hide resources isn't enabled, we show the explore page
  // if both goals and hide resources aren't enabled, we show the profile
  // if goals are enabled but we are secondary user (fac/staff doesn't have goals)
  const showExplore = any(equals(true))([
    not(enable_goals),
    hideResources,
    not(showTips)
  ])
  const showProfile = any(equals(true))([
    not(enable_goals) && hideResources,
    not(showTips) && hideResources,
    not(enable_goals) && not(showTips),
    enable_goals && is_secondary_user
  ])

  return (
    <Stack
      // eslint-disable-next-line sort-keys
      direction={{ mobile: 'column', tablet: 'row' }}
      spacing={2}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 1
      }}
    >
      {enable_goals && not(is_secondary_user) && (
        <DashboardFooterItem
          imgSrc="/images/goals_dashboard_footer.png"
          link="/goals?active=explore"
          linkText={intl.formatMessage({
            defaultMessage: 'Check Out Ready-made Goals',
            id: 'L+fJfL'
          })}
        />
      )}

      {!hideResources && (
        <DashboardFooterItem
          imgSrc="/images/student_dashboard_footer.png"
          link="/resources"
          linkText={intl.formatMessage({
            defaultMessage: 'See Resources',
            id: 'WyuSKb'
          })}
        />
      )}

      {showExplore && (
        <DashboardFooterItem
          imgSrc="/images/explore_dashboard_footer.png"
          link="/explore"
          linkText={intl.formatMessage({
            defaultMessage: 'Explore Topics',
            id: '71zhJ/'
          })}
        />
      )}

      {showTips && (
        <DashboardFooterItem
          imgSrc="/images/stories_dashboard_footer.png"
          link="/student-posts"
          linkText={intl.formatMessage({
            defaultMessage: 'Hear From Others',
            id: 'kPX6Dq'
          })}
        />
      )}

      {showProfile && (
        <DashboardFooterItem
          imgSrc="/images/profile_dashboard_footer.png"
          link="/profile?active=about"
          linkText={intl.formatMessage({
            defaultMessage: 'Complete Your Profile',
            id: 'kZoWfb'
          })}
        />
      )}
    </Stack>
  )
}

export default Footer
