export const MuiTab = palette => ({
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: '#BFE2E9',
        color: '#211F1C'
      },
      ':focus': {
        '& > div > .MuiTypography-root': {
          color: '#211F1C'
        },
        backgroundColor: '#F3EBE0',
        color: '#211F1C'
      },
      ':hover': {
        '& > div > .MuiTypography-root': {
          color: '#211F1C'
        },
        backgroundColor: '#F3EBE0',
        color: '#211F1C'
      },
      backgroundColor: 'transparent',
      borderRadius: 8,
      color: palette.surface.onSurface,
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      height: 40,
      letterSpacing: 0.5,
      lineHeight: 1.4,
      minHeight: 40,
      textTransform: 'none'
    },
    textColorPrimary: {
      color: palette.surface.onSurface
    }
  }
})
