import { prop } from 'ramda'
import { api as baseQuery } from './baseQuery'

export const statsApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    getBackendStats: query({
      providesTags: ['be stats'],
      query: params => `/stats/registration?${params}`
    }),
    getRealityScores: query({
      providesTags: ['reality score'],
      query: id => `/stats/reality-check-scores/${id}`
    }),
    getScore: query({
      providesTags: ['score'],
      query: id => `/scores/${id}`
    }),
    getScores: query({
      providesTags: ['scores'],
      query: () => '/scores'
    }),
    getSelfCheckQuizData: query({
      keepUnusedDataFor: 5,
      providesTags: ['self check'],
      query: () => '/self-check-quiz-data?use_new_method=true'
    }),
    updateScore: mutation({
      query: body => ({
        body: prop('data', body),
        method: 'POST',
        url: `/scores/${prop('id', body)}`
      })
    }),
    updateSocialCount: mutation({
      query: id => ({
        method: 'POST',
        url: `/self-check/social-count/${id}`
      })
    })
  })
})

export const {
  useGetBackendStatsQuery,
  useGetRealityScoresQuery,
  useGetScoresQuery,
  useGetSelfCheckQuizDataQuery,
  useLazyGetRealityScoresQuery,
  useLazyGetScoreQuery,
  useUpdateScoreMutation,
  useUpdateSocialCountMutation
} = statsApi
