import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from '@mui/material'
import { useIntl } from 'react-intl'
import { equals, not, path, prop } from 'ramda'
import { useAnalytics } from 'hooks'
function YesNoQuestionInput({ question, userAnswers, onUpdateAnswer }) {
  const intl = useIntl()
  const { sendEvent } = useAnalytics()

  const answerYes = path(['answers', 1], question)
  const answerNo = path(['answers', 0], question)
  const currentAnswer = question.answers.find(({ id }) =>
    equals(id, prop(0, userAnswers))
  )
  const [isSelected, setIsSelected] = useState(equals(currentAnswer, answerYes))

  // Pass True/False Bool
  // If True Yes
  // Else No
  const handleAnalytics = val => {
    const answer = val ? 'Yes' : 'No'
    sendEvent('profile_question', {
      answer,
      question: prop('analytics_property_name', question)
    })
  }

  return (
    <Button
      aria-label={
        isSelected
          ? intl.formatMessage(
              { defaultMessage: 'Selected {question}', id: 'L7ZsDd' },
              {
                question: prop('question', question)
              }
            )
          : intl.formatMessage(
              {
                defaultMessage: 'Not selected {question}',
                id: '73yDrq'
              },
              {
                question: prop('question', question)
              }
            )
      }
      onClick={() => {
        const newSelected = not(isSelected)
        setIsSelected(newSelected)
        const { id } = newSelected ? answerYes : answerNo
        handleAnalytics(newSelected)
        onUpdateAnswer({
          answers: [id] ?? null,
          profile_question_id: prop('id', question)
        })
      }}
      selected={isSelected}
      variant="select"
    >
      {prop('question', question)}
    </Button>
  )
}

YesNoQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    question: PropTypes.string
  }).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired
}

export default YesNoQuestionInput
