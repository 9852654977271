import PropTypes from 'prop-types'
import { all, equals } from 'ramda'

// TODO Figure out better handling
const FeatureFlagWrapper = ({ children, requirements = [] }) =>
  all(equals(true))(requirements) ? children : null

FeatureFlagWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.bool)
}

export default FeatureFlagWrapper
