import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { and, isNil, not, prop } from 'ramda'
import { useGetSchoolDataQuery } from 'api/schoolApi'
import { setSchool as setState } from './schoolSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'

import renderOrgDomain from './getOrgDomain'

const SchoolLoader = ({ children }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(prop('school'))
  const orgDomain = renderOrgDomain()

  const { data } = useGetSchoolDataQuery(orgDomain, {
    skip: and(isNil(orgDomain), not(isNil(id)))
  })

  useEffect(() => {
    if (and(not(isNil(data)), isNil(id))) {
      dispatch(setState(data))
      sessionStorage.setItem('org_domain', prop('domain', data))
    }
  }, [data, dispatch, id])

  // When there are high load times (Heroku can't keep up)
  // make sure there is a loader showing until the data is returned
  // and that the data is then in the redux store
  // prevents a 500 log to Datadog

  return isNil(id) ? (
    <Dialog
      aria-labelledby="dialog-title"
      open
      PaperProps={{
        sx: {
          height: 200,
          width: 375
        }
      }}
      role="alertdialog"
    >
      <DialogTitle
        component="h1"
        id="dialog-title"
        sx={{ textTransform: 'none' }}
        tabIndex={-1}
      >
        Loading YOU...
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          color: 'primary.swatch',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </DialogContent>
    </Dialog>
  ) : (
    children
  )
}

SchoolLoader.propTypes = {
  children: PropTypes.node.isRequired
}

export default SchoolLoader
