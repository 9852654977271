export const MuiInputBase = palette => ({
  styleOverrides: {
    colorPrimary: {
      backgroundColor: palette.surface.container
    },
    root: {
      backgroundColor: palette.surface.container,
      borderRadius: 16,
      fontSize: 16
    }
  }
})
