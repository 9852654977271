/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Box, Divider, Stack, Typography } from '@mui/material'
import CardHandler from 'components/cards/CardHandler'
import Icon from 'components/icon/Icon'

const LearnNewSkillsBlock = ({ skillCards, containsPolling = false }) => (
  <Box
    sx={{
      display: 'grid',
      mb: containsPolling ? { mobile: 3, tablet: 4 } : 0
    }}
  >
    <Box
      sx={{
        display: 'grid'
      }}
    >
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Icon
          fill
          sx={{
            transform: 'rotateY(180deg)'
          }}
        >
          build
        </Icon>
        <Typography variant="h2">
          <FormattedMessage defaultMessage="Learn New Skills" id="H2i23P" />
        </Typography>
      </Stack>
      <Divider />
    </Box>
    <Box
      sx={{
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: {
          mobile: '1fr',
          laptop: containsPolling ? '1fr' : 'repeat(2, 1fr)',
          monitor: containsPolling ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
        },
        mx: 'auto',
        my: 3
      }}
    >
      {skillCards
        .map((card, i) => (
          <CardHandler
            blockTitle="Explore Resources"
            card={card}
            key={i}
            small
          />
        ))
        .slice(0, containsPolling ? 4 : 6)}
    </Box>
  </Box>
)

LearnNewSkillsBlock.propTypes = {
  containsPolling: PropTypes.bool,
  skillCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default LearnNewSkillsBlock
