import { Box, Button, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import {
  setPreviousSearches,
  setSearchTerm,
  setTempSearchTerm
} from 'pages/search/searchSlice'
import PropTypes from 'prop-types'
import { append, path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

const SearchCard = ({ index, title }) => {
  const dispatch = useDispatch()
  const { sendEvent } = useAnalytics()
  const previousSearches = useSelector(path(['search', 'previousSearches']))

  const handleClick = () => {
    dispatch(setTempSearchTerm(title))
    dispatch(setSearchTerm(title))
    dispatch(setPreviousSearches(append(title, previousSearches)))
    sendEvent('search_query', {
      searchterm: title
    })
  }

  return (
    <Box
      component={Button}
      onClick={handleClick}
      sx={{
        alignItems: 'flex-end',
        backgroundImage: `url(/vectors/search-tile-${index}.png)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: 2,
        height: 134,
        justifyContent: 'flex-start',
        maxHeight: 'unset',
        minHeight: 'unset',
        px: 2.5,
        py: 1.5,
        width: 233
      }}
      variant=""
    >
      <Typography
        aria-label={`Search For ${title}`}
        sx={{ color: 'brand.cleanSocks' }}
      >
        {title}
      </Typography>
    </Box>
  )
}

SearchCard.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default SearchCard
