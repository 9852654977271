/* eslint-disable sort-keys */
import { Box, Button, Container, Slide, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { keyframes } from '@emotion/react'
import Icon from 'components/icon/Icon'

const Confirmation = () => {
  const { emailOptIn } = useSelector(prop('destress'))

  const slideIn = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
  })

  const fade = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  })

  return (
    <Container maxWidth="tablet">
      <Slide direction="up" in timeout={500}>
        <Stack alignItems="center" spacing={8} textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <Typography component="h1" sx={{ maxWidth: 487 }} variant="h2">
              <FormattedMessage
                defaultMessage="Give yourself a pat on the back for putting you first."
                id="4keOlW"
              />
            </Typography>
            {emailOptIn && (
              <Typography sx={{ mb: 3 }} variant="subtitle1">
                <FormattedMessage
                  defaultMessage="Check your email for a copy of all your saved resources"
                  id="WEfWHj"
                />
              </Typography>
            )}
            <Typography sx={{ maxWidth: 398 }} variant="body1">
              <FormattedMessage
                defaultMessage="Remember, you can find your saved resources and get support for anything, anytime, right here in YOU."
                id="PEfeP8"
              />
            </Typography>
          </Stack>
          <Button
            endIcon={<Icon>arrow_forward</Icon>}
            LinkComponent={Link}
            to="/home"
          >
            <FormattedMessage defaultMessage="Return to you" id="ddJCED" />
          </Button>
        </Stack>
      </Slide>
      <Box
        sx={{
          animation: `${fade} ease 1.5s`,
          backgroundImage: 'url(/vectors/interactive-content/palm.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          bottom: 0,
          display: { mobile: 'none', laptop: 'block' },
          height: 551,
          left: 0,
          maxWidth: 475,
          position: 'absolute',
          width: 1
        }}
      />
      <Box
        sx={{
          animation: `${slideIn} ease 1.5s`,
          backgroundImage: 'url(/vectors/interactive-content/cat.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          bottom: 0,
          height: 270,
          maxWidth: 313,
          position: 'absolute',
          right: 61,
          width: 1
        }}
      />
      <Box
        sx={{
          animation: `${slideIn} ease 1.5s`,
          backgroundImage: 'url(/vectors/interactive-content/sun-2.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          display: { mobile: 'none', laptop: 'block' },
          height: 99,
          maxWidth: 170,
          position: 'absolute',
          right: 0,
          top: 288,
          width: 1
        }}
      />
    </Container>
  )
}

export default Confirmation
