import { api as baseQuery } from './baseQuery'
import { prop } from 'ramda'

export const analyticsApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation }) => ({
    sendKeenEvent: mutation({
      query: data => {
        const body = new FormData()
        body.append('event', prop('name', data))
        body.append('data', JSON.stringify(data))
        return {
          body,
          method: 'POST',
          url: `/v2/analytics?org_domain=${prop('org_domain', data)}`
        }
      }
    })
  }),
  reducerPath: 'analyticsApi'
})

export const { useSendKeenEventMutation } = analyticsApi
