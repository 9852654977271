import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { always, equals, ifElse, prop } from 'ramda'
import { Button, Card, CardContent, Skeleton, Typography } from '@mui/material'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import { useDispatch, useSelector } from 'react-redux'

const CalloutBox = ({ topSectionCopy = null }) => {
  const dispatch = useDispatch()
  const { is_secondary_user, loading } = useSelector(prop('user'))

  const handleOpen = () => {
    dispatch(setIsCrisisModalOpen(true))
  }

  if (loading)
    return <Skeleton height={170} variant="rectangular" width="100%" />

  const lifeFacultyCheck = ifElse(
    equals(true),
    always(
      <FormattedMessage
        defaultMessage=" When life gets hard, the only mistake you can make is not reaching out for support. "
        id="vgwE7K"
      />
    ),
    always(
      <FormattedMessage
        defaultMessage=" When life gets hard, your campus community is here to help. "
        id="wup1qV"
      />
    )
  )

  const lifeIsHardCopy = lifeFacultyCheck(is_secondary_user)

  const immediateSupportCopy = (
    <FormattedMessage
      defaultMessage="If you are seeking immediate support, connect with"
      id="kwLFLu"
    />
  )

  const bottomCopy = (
    <>
      <Typography sx={{ display: 'inline' }} variant="body1">
        {lifeIsHardCopy}
      </Typography>
      <Typography component="span" id="help" variant="body1">
        {immediateSupportCopy}
      </Typography>
      <Button
        aria-describedby="help"
        onClick={() => handleOpen()}
        sx={{
          cursor: 'pointer',
          fontSize: 14,
          ml: 0.5,
          p: 0,
          pb: 0.5,
          textDecoration: 'underline',
          textTransform: 'none'
        }}
        variant="text"
      >
        <FormattedMessage defaultMessage="Crisis Resources." id="nIlxDw" />
      </Button>
    </>
  )

  return (
    <Card
      component="aside"
      sx={{ maxWidth: 1024, width: 1 }}
      variant="fullWidth"
    >
      <CardContent
        sx={{
          ':last-child': {
            pb: 2
          },
          p: 2
        }}
      >
        {topSectionCopy}
        {bottomCopy}
      </CardContent>
    </Card>
  )
}

CalloutBox.propTypes = {
  topSectionCopy: PropTypes.string
}

export default CalloutBox
