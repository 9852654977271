import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useIntl } from 'react-intl'
import Icon from 'components/icon/Icon'

const AccessibleModal = ({ children, onClose, open = false, title = null }) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog
      aria-labelledby="dialog-title"
      onClose={onClose}
      open={open}
      role="alertdialog"
    >
      <IconButton
        aria-label={formatMessage({
          defaultMessage: 'Close',
          id: 'rbrahO'
        })}
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <Icon>close</Icon>
      </IconButton>
      <DialogTitle
        component="h1"
        id="dialog-title"
        sx={{ mt: 2 }}
        tabIndex={-1}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          pb: 4.5,
          px: 10
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

AccessibleModal.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default AccessibleModal
