import { isEmpty, isNil, or, propEq } from 'ramda'

export const getSubdomainInfoFromId = (id = false, user_domains = false) => {
  if (id && user_domains) {
    const subdomain = user_domains
      .reduce((subdomains, d) => d.subdomains.concat(subdomains), [])
      .filter(propEq(id, 'id'))[0]

    return subdomain
  }
  return false
}

export const subdomainsFromUserDomains = domains => {
  if (or(isEmpty(domains), isNil(domains))) return []
  // domains needs to be converted to a JS object using toJS() for this to work
  return domains?.reduce(
    (subdomains, domain) => domain.subdomains.concat(subdomains),
    []
  )
}
