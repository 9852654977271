import { Button, Grow, LinearProgress, Stack, Typography } from '@mui/material'
import { useGetRealityScoresQuery } from 'api/statsApi'
import PropTypes from 'prop-types'
import {
  add,
  divide,
  equals,
  inc,
  isNil,
  multiply,
  not,
  prop,
  reduce
} from 'ramda'
import { Fragment, useMemo } from 'react'
import { useIntl } from 'react-intl'

const AnswerOptions = ({
  answers,
  id,
  isSubmitted,
  selected = null,
  setSelected
}) => {
  const { formatMessage } = useIntl()
  const { data: scores, isFetching } = useGetRealityScoresQuery(id)

  const totalAnswered = useMemo(() => {
    if (isFetching) return 0
    return reduce(add, 1, Object.values(scores))
  }, [scores, isFetching])

  const getPercentage = idx => {
    const answer = selected
      ? inc(prop(inc(idx), scores))
      : prop(inc(idx), scores)
    return Math.ceil(multiply(divide(answer, totalAnswered), 100))
  }

  const isSelected = idx => equals(selected, idx)

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 2
      }}
    >
      {answers.map((answer, i) => (
        <Fragment key={i}>
          <Stack
            aria-label={formatMessage(
              {
                defaultMessage: '{answer}, {selected}{percent}',
                id: 'fY9Tvh'
              },
              {
                answer,
                percent: not(isNil(selected))
                  ? `${getPercentage(i)}% of students answered ${
                      isSelected(i) ? 'similar to you' : 'differently than you'
                    }`
                  : '',
                selected: isSelected(i)
                  ? ''
                  : 'unselected, interact to see what others answered'
              }
            )}
            component={Button}
            fullWidth
            onClick={() => setSelected(i)}
            selected={isSelected(i)}
            spacing={1}
            sx={{
              maxHeight: 'unset'
            }}
            variant="poll"
          >
            {answer}
          </Stack>
          <Grow
            in={isSubmitted}
            style={{
              display: isSubmitted ? 'flex' : 'none',
              height: isSubmitted ? 'unset' : 0
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              sx={{ width: 1 }}
            >
              <LinearProgress
                aria-hidden="true"
                sx={{ width: 1 }}
                value={getPercentage(i)}
                variant="determinate"
              />
              <Typography variant="subtitle1" whiteSpace="nowrap">
                {getPercentage(i)}%
              </Typography>
            </Stack>
          </Grow>
        </Fragment>
      ))}
    </Stack>
  )
}

AnswerOptions.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired
}

export default AnswerOptions
