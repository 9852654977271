import { useGetFeaturedCardsQuery } from 'api/cardsApi'
import { any, equals, isNil, prop } from 'ramda'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'

const FeaturedCards = () => {
  const { domain } = useSelector(prop('school'))
  const {
    is_secondary_user: isSecondaryUser,
    location_id: location,
    isSuccess
  } = useSelector(prop('user'))

  const { data = [], isFetching = true } = useGetFeaturedCardsQuery(
    `?org_domain=${domain}&is_staff_faculty=${isSecondaryUser ? 1 : 0}&location_id=${location}`,
    {
      skip: any(equals(true))([
        equals(isSuccess, false),
        isNil(domain),
        isNil(location),
        isNil(isSecondaryUser)
      ])
    }
  )

  return (
    <CardGridWrapper isLoading={isFetching} small>
      {data.map(({ card }) => (
        <CardHandler card={card} isFeatured key={prop('id', card)} small />
      ))}
    </CardGridWrapper>
  )
}

export default FeaturedCards
