import { Button, Drawer, IconButton, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useWindowResize from 'hooks/useWindowResize'
import { lt, path } from 'ramda'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoggedOutOpen } from './sidebarSlice'

import LogInButton from 'components/buttons/LogInButton'
import CrisisButton from 'components/buttons/CrisisButton'
import Icon from 'components/icon/Icon'

const LoggedOutSidebar = () => {
  const dispatch = useDispatch()
  const { x } = useWindowResize()
  const isOpen = useSelector(path(['sidebar', 'isLoggedOutOpen']))

  useEffect(() => {
    if (lt(x, 1023)) dispatch(setIsLoggedOutOpen(false))
  }, [x, dispatch])

  return (
    <Drawer anchor="right" open={isOpen}>
      <IconButton
        aria-label="close sidebar"
        onClick={() => dispatch(setIsLoggedOutOpen(false))}
        sx={{
          alignSelf: 'flex-end',
          // eslint-disable-next-line sort-keys
          display: { mobile: 'flex', laptop: 'none' },
          mr: 1,
          mt: 1
        }}
      >
        <Icon>close</Icon>
      </IconButton>
      <Stack alignItems="center" spacing={2} sx={{ width: 1 }}>
        <CrisisButton color="primary" fill="primary" />
        <LogInButton icon={<Icon fill>home</Icon>}>
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        </LogInButton>
        <Button LinkComponent={Link} to="/sign-up">
          <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
        </Button>
      </Stack>
    </Drawer>
  )
}

export default LoggedOutSidebar
