/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { Typography, Stack, Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

import BasicSignUpForm from './components/BasicSignUpForm'

const SignUpDemo = () => {
  const { letters } = useSelector(prop('school'))

  return (
    <Stack
      alignItems="center"
      sx={{
        minHeight: {
          mobile: 'calc(100vh - 122px)',
          tablet: 'calc(100vh - 164px)',
          laptop: 'calc(100vh - 172px)'
        },
        mt: { mobile: 5, laptop: 9 },
        mx: 'auto',
        overflowX: 'hidden',
        pb: { mobile: 5, laptop: 9 },
        position: 'relative',
        width: 1
      }}
    >
      <Stack
        alignItems="center"
        spacing={3}
        sx={{
          maxWidth: 610,
          position: 'relative',
          px: 2,
          width: 1,
          zIndex: 1
        }}
      >
        <Typography textAlign="center" variant="h1">
          <FormattedMessage
            defaultMessage="Create your private account"
            id="g+gKIh"
          />
        </Typography>
        <Typography textAlign="center" variant="subtitle2">
          Get access to interactive tools & well-being resources available
          through YOU at {letters}.
        </Typography>
        <Stack
          direction={{ mobile: 'column', tablet: 'row' }}
          spacing={3}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            mt: 1,
            width: 1
          }}
        >
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Already have an account?"
              id="uCk8r+"
            />
          </Typography>
          <Button LinkComponent={Link} to="/sign-in" variant="text">
            <FormattedMessage defaultMessage="Log in." id="WZDtf5" />
          </Button>
        </Stack>
        <BasicSignUpForm />
      </Stack>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/cloud-lg.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          display: { mobile: 'none', tablet: 'block' },
          height: 137,
          left: 0,
          position: 'absolute',
          top: 161,
          width: 239
        }}
      />
      <Box
        sx={{
          backgroundImage: 'url(/vectors/cloud-sm.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          display: { mobile: 'none', tablet: 'block' },
          height: 85,
          position: 'absolute',
          right: 50,
          top: 0,
          width: 192
        }}
      />
      <Box
        sx={{
          backgroundImage: 'url(/vectors/branch-lg.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bottom: 0,
          display: { mobile: 'none', tablet: 'block' },
          height: 377,
          position: 'absolute',
          right: 0,
          width: 271
        }}
      />
      <Box
        sx={{
          backgroundImage: 'url(/vectors/branch-sm.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bottom: 0,
          display: { mobile: 'none', tablet: 'block' },
          height: 141,
          left: 0,
          position: 'absolute',
          width: 129
        }}
      />
    </Stack>
  )
}

export default SignUpDemo
