import { equals } from 'ramda'

const renderOrgDomain = () => {
  const isDev = equals(process.env.REACT_APP_PUBLIC_APP_ENV, 'development')
  const domain = isDev
    ? process.env.REACT_APP_PUBLIC_ORG_DOMAIN
    : window.location.host

  return domain
}

export default renderOrgDomain
