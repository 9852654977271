import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TYPE_RESOURCE } from 'utils/card-functions'
import {
  assoc,
  equals,
  gt,
  isEmpty,
  isNil,
  length,
  not,
  prop,
  propEq,
  sortBy
} from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import {
  useDeleteGoalMutation,
  useGetGoalQuery,
  useUpdateGoalMutation
} from 'api/goalsApi'
import { Button, Stack, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import GoalDateDisplay from '../components/GoalDateDisplay'
import GoalStepDisplay from './components/GoalStepDisplay'
import AddGoalStepDisplay from './components/AddGoalStepDisplay'
import AccessibleModal from 'components/modals/AccessibleModal'
import RecommendedCards from 'components/cards/RecommendedCards'
import Icon from 'components/icon/Icon'

const ViewGoal = () => {
  const { id } = useParams()
  const { domain } = useSelector(prop('school'))

  const [isOpen, setIsOpen] = useState(false)

  const { data: goal = {} } = useGetGoalQuery(id)

  const params = {
    'filter[subdomains]': [prop('subdomain_id', goal)],
    org_domain: domain,
    perPage: 3,
    type: TYPE_RESOURCE
  }

  const { data } = useGetCardsQuery(params, {
    skip: isNil(prop('subdomain_id', goal))
  })

  const [updateGoal] = useUpdateGoalMutation()
  const [deleteGoal] = useDeleteGoalMutation()

  const completeGoal = () => {
    updateGoal({
      body: assoc('completed', true, goal),
      id: prop('id', goal)
    })
  }

  useEffect(() => {
    if (not(isEmpty(goal))) {
      const stepsToComplete = prop('steps', goal).filter(
        propEq(false, 'completed')
      )
      const shouldShowPrompt = equals(length(stepsToComplete), 0)
      if (shouldShowPrompt) setIsOpen(true)
    }
  }, [goal])

  return (
    <Stack>
      <AccessibleModal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        title={
          <FormattedMessage defaultMessage="Congratulations!" id="lOzDTt" />
        }
      >
        <Typography variant="subtitle1">
          <FormattedMessage
            defaultMessage="Looks like you completed all your steps, would you like to mark this goal as completed?"
            id="kc1Y2T"
          />
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Button
            LinkComponent={Link}
            onClick={() => completeGoal()}
            to="/goals/complete"
          >
            <FormattedMessage defaultMessage="Yes please" id="VcwrfF" />
          </Button>
          <Button onClick={() => setIsOpen(false)} variant="outlined">
            <FormattedMessage defaultMessage="No thank you" id="hCnv0P" />
          </Button>
        </Stack>
      </AccessibleModal>
      <Stack
        spacing={3}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          px: 3,
          py: 6
        }}
      >
        <Button
          LinkComponent={Link}
          startIcon={<Icon>arrow_back</Icon>}
          sx={{
            left: { mobile: 'unset', tablet: 16 },
            position: { mobile: 'relative', tablet: 'absolute' },
            top: { mobile: 'unset', tablet: 80 }
          }}
          to="/goals?active=my-goals"
          variant="text"
        >
          <FormattedMessage defaultMessage="back" id="yLtLwT" />
        </Button>
        <Typography sx={{ maxWidth: 516, textAlign: 'center' }} variant="h1">
          {prop('title', goal)}
        </Typography>
        <GoalDateDisplay goal={goal} />
        <Button
          LinkComponent={Link}
          onClick={completeGoal}
          to="/goals/complete"
        >
          <FormattedMessage
            defaultMessage="Mark goal as complete"
            id="JvPa+I"
          />
        </Button>
        <Button
          endIcon={<Icon>edit</Icon>}
          LinkComponent={Link}
          to={`/goals/edit/${prop('id', goal)}`}
          variant="text"
        >
          <FormattedMessage defaultMessage="edit goal" id="yuDS3+" />
        </Button>
        {gt(length(prop('steps', goal)), 0) &&
          sortBy(prop('order'))(prop('steps', goal)).map(step => (
            <GoalStepDisplay goal={goal} key={prop('id', step)} step={step} />
          ))}

        <AddGoalStepDisplay goal={goal} />

        <Button
          LinkComponent={Link}
          onClick={completeGoal}
          to="/goals/complete"
        >
          <FormattedMessage
            defaultMessage="Mark goal as complete"
            id="JvPa+I"
          />
        </Button>
        <Button
          error
          LinkComponent={Link}
          onClick={() => deleteGoal(prop('id', goal))}
          startIcon={<Icon>delete</Icon>}
          to="/goals?active=my-goals"
          variant="text"
        >
          <FormattedMessage defaultMessage="Delete this goal" id="Vyw42+" />
        </Button>
      </Stack>
      {prop('data', data) && (
        <RecommendedCards
          blockTitle="goals recommended"
          recommendedCards={prop('data', data)}
          type="resource"
        />
      )}
    </Stack>
  )
}

export default ViewGoal
