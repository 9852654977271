import { Box, Container, Slide, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const Education = () => (
  <Container>
    <Stack alignItems="center">
      <Slide direction="down" in>
        <Stack alignItems="center" spacing={3} sx={{ textAlign: 'center' }}>
          <Typography component="h1" sx={{ maxWidth: 764 }} variant="h2">
            <FormattedMessage
              defaultMessage="While there are positive forms of stress, we tend to only focus on, or feel, the negative aspects of stress."
              id="33S5Lu"
            />
          </Typography>
          <Typography sx={{ maxWidth: 707 }} variant="subtitle1">
            Annoying, right? Lab coat-wearing researchers found that our brains
            have a negativity bias. Some studies say it takes 3 seconds for us
            to process negative things and a whopping 7 to remember the good.
          </Typography>
        </Stack>
      </Slide>
      <Slide direction="up" in timeout={500}>
        <Stack alignItems="center" spacing={2}>
          <Box
            component="img"
            src="/images/interactive-content/destress/scale.svg"
            sx={{ maxWidth: 204 }}
          />
          <Typography
            sx={{ maxWidth: 707, textAlign: 'center' }}
            variant="subtitle1"
          >
            <FormattedMessage
              defaultMessage="This module will focus on the negative side effects of stress on our bodies, thoughts, and relationships and how to alleviate them. In doing so, we can tip the scale in our favor. Let’s go! 🚦"
              id="3Yl+u3"
            />
          </Typography>
        </Stack>
      </Slide>
    </Stack>
  </Container>
)

export default Education
