import { createSlice } from '@reduxjs/toolkit'
import { assoc, assocPath, prop } from 'ramda'

const initialState = {
  allowed_to_view_stats_endpoint: 0,
  is_secondary_user: false,
  messages: [],
  userDomains: []
}

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    resetState: () => {
      return initialState
    },
    setMessages: (state, { payload }) => assoc('messages', payload, state),
    setUserData: (state, { payload }) => ({ ...state, ...payload }),
    setUserDomains: (state, { payload }) =>
      assoc('userDomains', payload, state),
    updatePublicUser: (state, { payload }) =>
      assocPath(
        ['public_user', prop('name', payload)],
        prop('value', payload),
        state
      ),
    updateUserData: (state, { payload }) =>
      assoc(prop('name', payload), prop('value', payload), state)
  }
})

export const {
  resetState,
  setMessages,
  setUserData,
  setUserDomains,
  updatePublicUser,
  updateUserData
} = userSlice.actions

export default userSlice.reducer
