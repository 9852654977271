import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import { useIntl } from 'react-intl'
import { not } from 'ramda'
import { useFavoriteMutation } from 'api/cardsApi'
import { useAnalytics } from 'hooks'
import Icon from 'components/icon/Icon'

const Favorite = ({ id, isBookmarked, params }) => {
  const { formatMessage } = useIntl()
  const { sendEvent } = useAnalytics()
  const [favorite] = useFavoriteMutation()

  const handleClick = () => {
    favorite({
      card_id: id,
      isFavorite: not(isBookmarked)
    })
    // GA tracking for our event
    if (not(isBookmarked)) {
      sendEvent('card_detail_view_favorite', { ...params })
    } else {
      sendEvent('card_detail_view_unfavorite', { ...params })
    }
  }

  return (
    <IconButton
      aria-label={
        isBookmarked
          ? formatMessage({
              defaultMessage: 'Favorite, Selected',
              id: 'E1dwhN'
            })
          : formatMessage({
              defaultMessage: 'Favorite, Not Selected',
              id: 'XDa+uB'
            })
      }
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex'
        }}
      >
        <Icon fill={isBookmarked}>favorite</Icon>
      </Box>
    </IconButton>
  )
}

Favorite.propTypes = {
  id: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  params: PropTypes.shape({}).isRequired
}

export default Favorite
