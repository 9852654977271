import { FormattedMessage } from 'react-intl'
import { equals, length, gt, prop } from 'ramda'
import { useGetGoalsQuery } from 'api/goalsApi'
import { Stack, Typography } from '@mui/material'

import EmptyState from './EmptyState'
import InProgressGoalCard from 'components/cards/InProgressGoalCard'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import Icon from 'components/icon/Icon'

const InProgress = () => {
  const { data: goals = [] } = useGetGoalsQuery()

  return (
    <Stack spacing={3} sx={{ mb: 3 }}>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center'
        }}
      >
        <Icon fill>mountain_flag</Icon>
        <Typography variant="h6">
          <FormattedMessage
            defaultMessage="{number} {goal} In Progress"
            id="IY1G7f"
            values={{
              goal: gt(length(goals), 1) ? 'Goals' : 'Goal',
              number: length(goals)
            }}
          />
        </Typography>
      </Stack>
      {equals(length(goals), 0) && <EmptyState />}
      {gt(length(goals), 0) && (
        <CardGridWrapper>
          {goals.map(goal => (
            <InProgressGoalCard card={goal} key={prop('id', goal)} />
          ))}
        </CardGridWrapper>
      )}
    </Stack>
  )
}

export default InProgress
