import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Box, Checkbox, Typography } from '@mui/material'
import { prop, findIndex, propEq, assoc, not, sortBy, inc } from 'ramda'
import { useUpdateGoalMutation } from 'api/goalsApi'

const GoalStepDisplay = ({ step, goal }) => {
  const isCompleted = prop('completed', step)

  const [updateGoal] = useUpdateGoalMutation()

  const handleUpdate = () => {
    const copy = [...prop('steps', goal)]
    const foundIndex = findIndex(propEq(prop('id', step), 'id'))(
      prop('steps', goal)
    )
    const updatedStep = assoc('completed', not(isCompleted), step)
    copy[foundIndex] = updatedStep
    // For some reason you have to sort by order first
    // otherwise the order gets rearranged on PUT
    const body = assoc('steps', sortBy(prop('order'))(copy), goal)

    updateGoal({
      body,
      id: prop('id', goal)
    })
  }

  return (
    <Box
      sx={{
        bgcolor: 'surface.container',
        borderRadius: 1,
        p: 2,
        width: 1
      }}
    >
      <Typography variant="subtitle1">
        <FormattedMessage
          defaultMessage="Step {step}"
          id="EJ8mg/"
          values={{ step: inc(prop('order', step)) }}
        />
      </Typography>
      <Box
        onClick={() => handleUpdate()}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex'
        }}
      >
        <Checkbox
          aria-label={`Step #${inc(prop('order', step))}, ${prop('name', step)}`}
          checked={isCompleted}
          color="default"
          sx={{ color: 'primary.tonal' }}
        />
        <Typography variant="subtitle1">{prop('name', step)}</Typography>
      </Box>
    </Box>
  )
}

GoalStepDisplay.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired
}
export default GoalStepDisplay
