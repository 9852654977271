import { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack
} from '@mui/material'
import {
  and,
  compose,
  equals,
  findIndex,
  isNil,
  map,
  not,
  path,
  prop
} from 'ramda'
import { useUpdateProfileMutation } from 'api/profileApi'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'
import Icon from 'components/icon/Icon'

const LocationSelector = () => {
  const dispatch = useDispatch()
  const { locations } = useSelector(prop('school'))
  const { location_id } = useSelector(prop('user'))

  const [selected, setSelected] = useState('')
  const [selectedIndex, setSetselectedIndex] = useState(null)

  const options = map(prop('name'), locations)
  const values = map(prop('id'), locations)

  useEffect(() => {
    const found = findIndex(equals(location_id), values)
    if (not(isNil(found))) {
      setSetselectedIndex(found)
      setSelected(prop(found, options))
    }
  }, [location_id, options, values])

  const [updateProfile] = useUpdateProfileMutation()

  const handleChange = value => {
    const idx = findIndex(equals(value), options)
    dispatch(
      updateUserData({ name: 'temp_location_id', value: prop(idx, values) })
    )
    setSetselectedIndex(idx)
    setSelected(value)
  }

  return (
    <Stack spacing={1} sx={{ mb: 2 }}>
      <FormControl fullWidth sx={{ maxWidth: 375 }}>
        <InputLabel shrink>Campus</InputLabel>
        <Select
          label="Campus"
          onChange={compose(handleChange, path(['target', 'value']))}
          renderValue={opt => opt}
          value={selected}
        >
          {options.map(opt => (
            <MenuItem key={opt} value={opt}>
              <ListItemText primary={opt} />
              {equals(selected, opt) && <Icon>check</Icon>}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {and(
        not(isNil(selected)),
        not(equals(prop(selectedIndex, values), location_id))
      ) && (
        <Button
          fullWidth
          onClick={() => {
            const body = new FormData()
            body.append('location_id', prop(selectedIndex, values))
            updateProfile(body)
            dispatch(updateUserData({ name: 'temp_location_id', value: null }))
          }}
          startIcon={<Icon>add_home</Icon>}
          sx={{ maxWidth: 275 }}
          variant="text"
        >
          Save as Campus Location
        </Button>
      )}
    </Stack>
  )
}

export default LocationSelector
