import { useCallback } from 'react'

const useLocalStorage = () => {
  const clear = useCallback(() => localStorage.removeItem('@you:sessionId'), [])

  const get = useCallback(() => {
    const key = localStorage.getItem('@you:sessionId')
    return JSON.parse(key)
  }, [])

  const save = useCallback(
    v => localStorage.setItem('@you:sessionId', JSON.stringify(v)),
    []
  )

  return {
    clear,
    get,
    save
  }
}

export default useLocalStorage
