import {
  Box,
  Card,
  CardContent,
  Container,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import Icon from 'components/icon/Icon'

const cardStyle = {
  ':hover, :focus': {
    '& > .MuiCardContent-root': {
      transform: 'rotateY(180deg)',
      transition: 'transform 0.5s'
    }
  },
  bgcolor: 'transparent',
  boxShadow: 'none',
  height: 275,
  perspective: '1000px',
  width: 275
}

const contentStyle = {
  height: '100%',
  p: 0,
  position: 'absolute',
  top: 0,
  transformStyle: 'preserve-3d',
  transition: 'transform 1s',
  width: 1
}

const childStyle = {
  backfaceVisibility: 'hidden',
  bgcolor: 'surface.container',
  borderRadius: 4,
  boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
  height: 'calc(100% - 24px)',
  left: 16,
  position: 'absolute',
  px: 3,
  py: 2,
  top: 16,
  width: 'calc(100% - 24px)'
}

const cards = [
  {
    content: (
      <FormattedMessage
        defaultMessage="Yes, it’s a real word. Eustress is a positive form of stress having a beneficial effect on health, motivation, performance, and emotional well-being."
        id="LqoagJ"
      />
    ),
    image: '/images/interactive-content/destress/happy-heart.svg',
    key: 0,
    title: <FormattedMessage defaultMessage="Eustress" id="svhIoO" />
  },
  {
    content: (
      <FormattedMessage
        defaultMessage="This is the one we normally hear. Distress is a negative form of stress that can have ill effects on our overall health and performance."
        id="yDTgIQ"
      />
    ),
    image: '/images/interactive-content/destress/ghost.svg',
    key: 1,
    title: <FormattedMessage defaultMessage="Distress" id="rSyXTl" />
  }
]

const Cards = () => (
  <Container>
    <Stack alignItems="center" spacing={4}>
      <Slide direction="left" in>
        <Stack spacing={3} sx={{ maxWidth: 730, textAlign: 'center' }}>
          <Typography component="h1" variant="h2">
            <FormattedMessage
              defaultMessage="Did you know there are positive and negative forms of stress?"
              id="nKSmrO"
            />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="Flip each form of stress below to learn more."
              id="4jLEQw"
            />
          </Typography>
        </Stack>
      </Slide>
      <Slide direction="left" in timeout={500}>
        <Stack
          direction={{
            mobile: 'column',
            // eslint-disable-next-line sort-keys
            tablet: 'row'
          }}
        >
          {cards.map(({ content, image, key, title }) => (
            <Card key={key} sx={cardStyle} tabIndex={0}>
              <CardContent sx={contentStyle}>
                <Box sx={childStyle}>
                  <Box sx={{ height: '100%', position: 'relative' }}>
                    <Icon
                      sx={{
                        color: 'surface.onSurface',
                        position: 'absolute',
                        right: 0,
                        top: 0
                      }}
                    >
                      autorenew
                    </Icon>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={0.5}
                      sx={{ height: '100%' }}
                    >
                      <Box aria-hidden component="img" src={image} />
                      <Typography variant="h5">{title}</Typography>
                    </Stack>
                  </Box>
                </Box>
                <Stack
                  id="content"
                  spacing={1}
                  sx={{
                    ...childStyle,
                    transform: 'rotateY(180deg)'
                  }}
                >
                  <Typography variant="h5">{title}</Typography>
                  <Typography fontSize="18px" textAlign="left" variant="body2">
                    {content}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Slide>
    </Stack>
  </Container>
)

export default Cards
