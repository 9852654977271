import { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import ResultsCard from './ResultsCard'
import { prop, filter, not, length, or, isNil, isEmpty, toLower } from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

const QuizResults = ({ currentQuiz, results }) => {
  const { pathname } = useLocation()
  const [search] = useSearchParams()
  const selected = search.get('selected')

  const title = prop('title', currentQuiz)
  // we filter out any results with displayAlert as they are too sensitive to offer a judgement on
  // in the results page. Ex: suicidal thoughts.
  const quizResults = useMemo(() => {
    if (or(isNil(results), isEmpty(currentQuiz))) return []
    // return []
    return filter(
      result => not(prop('displayAlert', result)),
      prop('results', currentQuiz)
    )
  }, [currentQuiz, results])

  // any time we click on one of the jump links we set category_results in the url shallowly
  // this use effect scrolls us to the correct element.
  useEffect(() => {
    if (selected) {
      const element = document.getElementById(selected)
      element && element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  return (
    <Stack
      spacing={3}
      sx={{
        alignItems: 'center',
        m: '0 auto',
        maxWidth: 1008,
        // eslint-disable-next-line sort-keys
        p: { mobile: 2, monitor: 0 },
        width: 1
      }}
    >
      <Box
        height={132}
        sx={{
          backgroundImage: `url(/vectors/${toLower(title)}.svg)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
        width={132}
      />
      <Typography variant="h1">Your {title} Results</Typography>
      <Typography
        component="span"
        sx={{ maxWidth: 531 }}
        textAlign="center"
        variant="subtitle1"
      >
        <FormattedMessage
          defaultMessage="Quick assessments are a starting point for a next step. Here's your snapshot across {topicAreas} topic areas."
          id="5WfE9y"
          values={{
            topicAreas: length(quizResults)
          }}
        />
      </Typography>
      <Typography component="span" variant="body1">
        Jump to your results
      </Typography>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'center',
          width: 1
        }}
      >
        {quizResults.map(({ id, title }) => (
          <Button
            key={id}
            LinkComponent={Link}
            to={`${pathname}?selected=${id}`}
            variant="select"
          >
            {title}
          </Button>
        ))}
      </Stack>
      <Box sx={{ display: 'grid', gap: 3, width: 1 }}>
        {quizResults.map(subdomain => (
          <ResultsCard
            key={subdomain.id}
            results={results}
            subdomain={subdomain}
          />
        ))}
      </Box>
    </Stack>
  )
}

QuizResults.propTypes = {
  currentQuiz: PropTypes.shape({}).isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default QuizResults
