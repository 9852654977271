import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Box, Card, CardActionArea, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import Icon from 'components/icon/Icon'

const CheckboxCard = ({ disabled, image, title, handleClick, selected }) => {
  const intl = useIntl()

  return (
    <Card
      sx={{
        maxWidth: 194
      }}
    >
      <CardActionArea
        aria-label={
          selected
            ? intl.formatMessage(
                { defaultMessage: 'selected {title}', id: 'GMW7io' },
                { title }
              )
            : intl.formatMessage(
                { defaultMessage: 'not selected {title}', id: 'O3KVhz' },
                { title }
              )
        }
        disabled={disabled}
        onClick={handleClick}
        sx={{
          position: 'relative'
        }}
      >
        <Box
          sx={{
            bgcolor: 'surface.container',
            borderRadius: 1,
            height: 24,
            left: 8,
            position: 'absolute',
            top: 8,
            width: 24,
            zIndex: 1
          }}
        >
          {selected ? (
            <Icon>check_box</Icon>
          ) : (
            <Icon fill>check_box_outline_blank</Icon>
          )}
        </Box>
        <Box
          sx={{
            background: `url(${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 160,
            width: 1
          }}
        />
        {isEmpty(image) && (
          <Box
            sx={{
              borderRadius: 1,
              height: 160,
              mb: 1,
              objectFit: 'cover',
              width: 1
            }}
          />
        )}
        <Box display="flex" justifyContent="center" p={1.5}>
          <Typography
            sx={{
              mb: '0',
              pb: '0',
              textAlign: 'center'
            }}
            variant="subtitle2"
          >
            {title}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

CheckboxCard.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}

export default CheckboxCard
