/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Divider, Stack, Typography } from '@mui/material'

const ExploreBlockWrapper = ({ title, icon, children }) => (
  <Stack
    sx={{
      width: 1
    }}
  >
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gridGap: 4,
        mb: 1.5
      }}
    >
      {icon}
      <Typography variant="h2">{title}</Typography>
    </Stack>
    <Divider sx={{ m: 0, mb: 3 }} />
    {children}
  </Stack>
)

ExploreBlockWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({}).isRequired,
  title: PropTypes.node.isRequired
}

export default ExploreBlockWrapper
