/* eslint-disable sort-keys */
import { useMemo, useRef, useState } from 'react'
import { useGetCardsQuery } from 'api/cardsApi'
import { TYPE_TIP } from 'utils/card-functions'
import { compose, isNil, nthArg, path, prop } from 'ramda'
import { Box, Divider, Pagination, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import useBuildFilters from 'components/filter/useBuildFilters'

import Banner from 'components/hero/Banner'
import CalloutBox from 'components/callout-box/CalloutBox'
import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import FilterMenu from 'components/filter/FilterMenu'

const StudentPosts = () => {
  // tips_data is a handful of images, text, etc which set at the school level to normalize the experience
  const { domain, tips_data } = useSelector(prop('school'))
  const { domainId, sortValue } = useBuildFilters()
  const scrollRef = useRef(null)

  const [page, setPage] = useState(1)

  const params = {
    'filter[domains]': domainId(),
    'filter[types]': TYPE_TIP,
    org_domain: domain,
    perPage: 12,
    page,
    'sort[method]': sortValue()
  }

  const { data } = useGetCardsQuery(params, {
    skip: isNil(domain)
  })

  const tips = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <>
      <Banner imageUrl="/images/student-posts.png" />
      <Stack sx={{ alignItems: 'center', px: 2, width: 1 }}>
        <Stack
          spacing={4}
          sx={{ alignItems: 'center', maxWidth: 1068, mt: 4.5, width: 1 }}
        >
          <Typography
            sx={{
              alignSelf: 'flex-start',
              pl: { mobile: 1, tablet: 0 }
            }}
            variant="h1"
          >
            {prop('tips_category_title', tips_data)}
          </Typography>
          <Box
            sx={{
              px: { mobile: 1, tablet: 0 },
              width: 1
            }}
          >
            <CalloutBox topSectionCopy={prop('tips_context_copy', tips_data)} />
          </Box>
          <Divider />
          <Stack ref={scrollRef} spacing={3}>
            <FilterMenu hasSortMenu hasTopicFilter />
            <CardGridWrapper>
              {tips.map((card, i) => (
                <CardHandler card={card} key={i} />
              ))}
            </CardGridWrapper>
          </Stack>
          <Pagination
            count={path(['meta', 'lastPage'], data)}
            onChange={compose(handleNav, setPage, nthArg(1))}
            page={page}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default StudentPosts
