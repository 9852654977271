import { Button, Stack, Typography } from '@mui/material'
import { useUpdateProfileMutation } from 'api/profileApi'
import { and, not, path } from 'ramda'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'hooks'
import { Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const ECRM = () => {
  const { sendEvent } = useAnalytics()
  const isOptedIn = useSelector(path(['user', 'email_opt_in']))

  const [hasInteracted, setHasInteracted] = useState(false)

  const [updateProfile, { isSuccess }] = useUpdateProfileMutation()

  const handleClick = () => {
    sendEvent('email_opt_in', {
      email_opt_in: true,
      opt_in_element: 'Article banner'
    })
    const body = new FormData()
    body.append('email_opt_in', 1)
    updateProfile(body)
  }

  useEffect(() => {
    if (isSuccess) setHasInteracted(true)
  }, [isSuccess])

  return (
    <Stack
      // eslint-disable-next-line sort-keys
      direction={{ mobile: 'column', tablet: 'row' }}
      spacing={2}
      sx={{
        alignItems: 'center',
        backgroundColor: 'surface.container',
        borderRadius: 4,
        display: and(isOptedIn, not(hasInteracted)) ? 'none' : 'flex',
        justifyContent: 'center',
        pl: 2,
        pr: 1,
        py: 0.5,
        width: 'fit-content'
      }}
    >
      {not(hasInteracted) ? (
        <>
          <Typography sx={{ color: 'secondary.dark' }} variant="body2">
            <FormattedMessage
              defaultMessage="Want well-being tips & more, straight to your inbox?"
              id="OyfYam"
            />
          </Typography>
          <Button onClick={handleClick}>
            <FormattedMessage defaultMessage="Subscribe" id="gczcC5" />
          </Button>
        </>
      ) : (
        <>
          <Typography sx={{ color: 'secondary.dark' }} variant="body2">
            <FormattedMessage
              defaultMessage="Wellness is coming your way!"
              id="WhHBVX"
            />
          </Typography>
          <Button
            endIcon={<Icon>arrow_forward</Icon>}
            LinkComponent={Link}
            sx={{
              fontWeight: 900,
              px: 0
            }}
            to="/profile?active=settings"
            variant="text"
          >
            <FormattedMessage
              defaultMessage="Update settings Any time"
              id="YHi7FW"
            />
          </Button>
        </>
      )}
    </Stack>
  )
}

export default ECRM
