import { TYPE_FACT_TIP, TYPE_SKILL } from 'utils/card-functions'
import PropTypes from 'prop-types'
import BackgroundImage from './BackgroundImage'
import parse from 'html-react-parser'
import { isEmpty, equals, prop, defaultTo, any, not, isNil } from 'ramda'
import { Box, Stack } from '@mui/material'

const TextArticle = ({
  article = {
    campus_resource: false,
    facts_tips_copy: '',
    page_content: '',
    photo: {
      large_url: ''
    },
    photo_description: '',
    type: ''
  }
}) => {
  const { photo, type, campus_resource, photo_description } = article

  const articleToRender = equals(prop('type', article), TYPE_FACT_TIP)
    ? prop('facts_tips_copy', article)
    : prop('page_content', article)

  const articleHtml = defaultTo('', articleToRender)

  return (
    <Stack
      sx={{
        maxWidth: 600,
        width: 1
      }}
    >
      <BackgroundImage
        imageUrl={prop('large_url', photo)}
        isCampusResource={campus_resource}
        isFactTip={equals(type, TYPE_FACT_TIP)}
        isSkill={equals(type, TYPE_SKILL)}
      />
      {not(isNil(photo_description)) && (
        <Box
          data-testid="photo-description"
          sx={{
            fontSize: 22
          }}
        >
          {parse(photo_description)}
        </Box>
      )}
      {any(equals(true))([
        not(isNil(prop('facts_tips_copy', article))),
        not(isEmpty(prop('page_content', article))),
        not(isNil(prop('type', article)))
      ]) && <Box>{parse(articleHtml)}</Box>}
    </Stack>
  )
}

TextArticle.propTypes = {
  article: PropTypes.shape({
    campus_resource: PropTypes.bool,
    facts_tips_copy: PropTypes.string,
    page_content: PropTypes.string,
    photo: PropTypes.shape({
      large_url: PropTypes.string
    }),
    photo_description: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

export default TextArticle
