import { useEffect } from 'react'
import Subdomain from './components/Subdomain'
import { useUpdateProfileMutation } from 'api/profileApi'
import { updateUserData } from 'store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { and, isNil, not, prop } from 'ramda'

const SubdomainPage = () => {
  const dispatch = useDispatch()
  const { has_viewed_a_priority } = useSelector(prop('user'))
  const [updateProfile] = useUpdateProfileMutation()

  useEffect(() => {
    if (and(not(has_viewed_a_priority), not(isNil(has_viewed_a_priority)))) {
      dispatch(updateUserData({ name: 'has_viewed_a_priority', value: 1 }))

      // post user has viewed priority to backend
      const body = new FormData()
      body.append('has_viewed_a_priority', 1)
      updateProfile(body)
    }
  }, [dispatch, has_viewed_a_priority, updateProfile])

  return <Subdomain />
}

export default SubdomainPage
