/* eslint-disable react/jsx-key */
import { FormattedMessage } from 'react-intl'

const Relationships = [
  <FormattedMessage defaultMessage="I shut down" id="Oo1Ort" />,
  <FormattedMessage defaultMessage="I distance myself" id="eSd3l1" />,
  <FormattedMessage
    defaultMessage="I seek support or affirmation"
    id="+OZ89b"
  />,
  <FormattedMessage
    defaultMessage="I assume the worst in people"
    id="jMpIcY"
  />,
  <FormattedMessage defaultMessage="I bottle up my emotions" id="yVTRPy" />,
  <FormattedMessage defaultMessage="I struggle listening" id="aXekrM" />,
  <FormattedMessage defaultMessage="I turn to social media" id="n7NAHV" />,
  <FormattedMessage defaultMessage="I am more irritable" id="lNI2Vj" />,
  <FormattedMessage defaultMessage="I am more argumentative" id="AGg4+v" />,
  <FormattedMessage
    defaultMessage="Other (Dig deep and reflect!)"
    id="3EGaBu"
  />,
]

export default Relationships
