import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { assoc, prop } from 'ramda'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

const DueDate = ({ goal, setGoal }) => (
  <DatePicker
    label={<FormattedMessage defaultMessage="Due Date" id="8XUukm" />}
    minDate={new Date()}
    onChange={date => setGoal(assoc('due_date', date))}
    renderInput={params => <TextField {...params} />}
    // eslint-disable-next-line sort-keys
    sx={{ width: { mobile: 1, laptop: 320 } }}
    value={prop('due_date', goal)}
  />
)

DueDate.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired
}

export default DueDate
