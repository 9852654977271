import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { isNil, not, path, prop } from 'ramda'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography
} from '@mui/material'
import useAnalytics from 'hooks/useAnalytics'

import AnswerOptions from './AnswerOptions'
import Icon from 'components/icon/Icon'

const PollOfMonth = ({ pollingQuestion }) => {
  const { sendEvent } = useAnalytics()

  const [selected, setSelected] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = () => {
    sendEvent('YOU_Subdomain_Poll', {
      poll_question_answer: path(['answers', selected], pollingQuestion),
      poll_question_title: prop('prompt', pollingQuestion),
      subdomain_name: prop('group', pollingQuestion)
    })
    setIsSubmitted(true)
  }

  return (
    <Card
      sx={{
        alignSelf: 'center',
        bgcolor: 'surface.low',
        maxWidth: 375,
        width: 1
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'flex-end',
          pt: 2,
          px: 2
        }}
      >
        <Icon sx={{ color: 'secondary.swatch' }}>bar_chart</Icon>
        <Typography variant="overline">
          <FormattedMessage defaultMessage="Poll" id="h1gtUi" />
        </Typography>
      </Stack>
      <Stack
        spacing={0.5}
        sx={{
          px: 2
        }}
      >
        <Typography
          aria-label={`Poll: ${prop('prompt', pollingQuestion)}`}
          tabIndex={0}
          variant="h4"
        >
          {prop('prompt', pollingQuestion)}
        </Typography>
        {not(isSubmitted) && (
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="Select an answer to see what others said."
              id="fkOonE"
            />
          </Typography>
        )}
      </Stack>
      <CardContent sx={{ pb: 1 }}>
        <AnswerOptions
          answers={prop('answers', pollingQuestion)}
          id={prop('id', pollingQuestion)}
          isSubmitted={isSubmitted}
          selected={selected}
          setSelected={setSelected}
        />
      </CardContent>
      <CardActions sx={{ pb: 2, pt: 0 }} variant="center">
        {isSubmitted ? (
          <Button
            fullWidth
            onClick={() => {
              setIsSubmitted(false)
              setSelected(null)
            }}
            startIcon={<Icon>restart_alt</Icon>}
            variant="text"
          >
            <FormattedMessage defaultMessage="Reset my Answer" id="41P9py" />
          </Button>
        ) : (
          <Button disabled={isNil(selected)} fullWidth onClick={handleSubmit}>
            <FormattedMessage defaultMessage="Submit my Answer" id="nlkQVA" />
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

PollOfMonth.propTypes = {
  pollingQuestion: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    group: PropTypes.string,
    id: PropTypes.number,
    prompt: PropTypes.string,
    questionSummary: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
}

export default PollOfMonth
