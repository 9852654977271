import PropTypes from 'prop-types'
import { Button, Stack } from '@mui/material'
import { dec, equals } from 'ramda'
import { useNavigate } from 'react-router'
import Icon from 'components/icon/Icon'

const Navigation = ({ question, questions, setQuestion }) => {
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        alignItems: 'center',
        bgcolor: 'surface.container',
        bottom: 0,
        boxShadow: 3,
        left: 0,
        position: 'fixed',
        py: 2,
        right: 0,
        zIndex: 1
      }}
    >
      <Button
        onClick={() => {
          // if we are at the start of the currentQuiz, we just go back to the self checks select page
          if (equals(questions.indexOf(question), 0))
            return navigate('/self-checks')
          setQuestion(questions[dec(questions.indexOf(question))])
        }}
        startIcon={<Icon>arrow_back</Icon>}
        variant="text"
      >
        Back
      </Button>
    </Stack>
  )
}

Navigation.propTypes = {
  question: PropTypes.shape({}).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestion: PropTypes.func.isRequired
}

export default Navigation
