import LoadingCard from 'components/cards/LoadingCard'
import SmallLoadingCard from 'components/cards/SmallLoadingCard'
import PropTypes from 'prop-types'

const CardLoader = ({ small = false }) => {
  const renderCards = () => {
    const cards = []
    for (let i = 0; i < 3; i++) {
      small
        ? cards.push(<SmallLoadingCard key={i} />)
        : cards.push(<LoadingCard key={i} />)
    }
    return cards
  }

  return renderCards()
}

CardLoader.propTypes = {
  small: PropTypes.bool
}

export default CardLoader
