/* eslint-disable */
import { any, equals, isEmpty, isNil, prop } from 'ramda'
import { useSelector } from 'react-redux'

const AnalyticsComponent = () => {
  const { app_type, google_analytics_id } = useSelector(prop('school'))
  const isVeteran = equals('veteran', app_type)

  if (
    any(equals(true))([
      isNil(google_analytics_id),
      isEmpty(google_analytics_id),
      equals(typeof window, 'undefined')
    ])
  )
    return

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_id}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: ` window.dataLayer = window.dataLayer || []; function gtag()
        {dataLayer.push(arguments)}
        gtag('js', new Date()); gtag('config', 'TAG_ID');`
        }}
      />
      {isVeteran && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PSTRQZK');`
            }}
          />
          <noscript>
            <iframe
              height="0"
              src="https://www.googletagmanager.com/ns.html?id=GTM-PSTRQZK"
              style={{ display: 'none', visibility: 'hidden' }}
              width="0"
            ></iframe>
          </noscript>
        </>
      )}
    </>
  )
}

export default AnalyticsComponent
