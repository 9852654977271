import PropTypes from 'prop-types'
import { Button, CardMedia, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import Icon from 'components/icon/Icon'

const DashboardFooterItem = ({ imgSrc, link, linkText }) => {
  const { sendEvent } = useAnalytics()

  const handleClick = () =>
    sendEvent('block_interactions', {
      block_title: 'Dashboard Footer Block',
      click_element: 'Link',
      element_title: linkText,
      path: window.location.pathname
    })

  return (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{
        bgcolor: 'transparent',
        boxShadow: 0,
        maxWidth: 375,
        width: 1
      }}
    >
      <CardMedia
        alt=""
        component="img"
        height={200}
        src={imgSrc}
        sx={{ borderRadius: 4 }}
      />
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        LinkComponent={Link}
        onClick={handleClick}
        to={link}
        variant="text"
      >
        {linkText}
      </Button>
    </Stack>
  )
}

DashboardFooterItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.any.isRequired
}

export default DashboardFooterItem
