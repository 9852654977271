import { api as baseQuery } from './baseQuery'

export const legalApi = baseQuery.injectEndpoints({
  endpoints: ({ query }) => ({
    getDocument: query({
      providesTags: ['legalDoc'],
      query: id => `/legal-statements/${id}`
    })
  }),
  reducerPath: 'legalApi'
})

export const { useGetDocumentQuery } = legalApi
