import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { FormattedMessage } from 'react-intl'
import { Box, Slide, Stack, Typography } from '@mui/material'
import AddPrioritiesCard from './AddPrioritiesCard'
import PrioritiesImageCard from './PrioritiesImageCard'

const NavArrow = ({ ariaLabel, className, ...props }) => (
  <button aria-label={ariaLabel} className={className} {...props} />
)

NavArrow.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
}

const MyPrioritiesCarousel = ({ priorities, toggleMenu }) => {
  const settings = {
    accessibility: true,
    arrows: true,
    className: 'my-priorities-carousel',
    infinite: false,
    nextArrow: <NavArrow ariaLabel="Next Option" className="slick-next" />,
    prevArrow: <NavArrow ariaLabel="Previous Option" className="slick-prev" />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1625,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 5
        }
      }
    ],
    slide: 'ul',
    slidesToScroll: 2,
    slidesToShow: 7,
    speed: 500,
    swipeToSlide: true
  }

  return (
    <Stack alignItems="center">
      <Typography id="info" textAlign="center" variant="body2">
        <FormattedMessage
          defaultMessage="Click a priority to dive in."
          id="GdbiST"
        />
      </Typography>
      <Slide direction="left" in>
        <Box
          sx={{
            maxWidth: 1640,
            width: {
              // This is to calc the width of the window minus the padding + sidebar size
              mobile: `calc(${window.innerWidth}px - 32px)`,
              // eslint-disable-next-line sort-keys
              laptop: `calc(${window.innerWidth}px - 332px)`
            }
          }}
        >
          <Slider {...settings}>
            {priorities.map(
              (
                { display_image, id, onboarding_title, link, social_count },
                idx
              ) => (
                <PrioritiesImageCard
                  imgURL={display_image}
                  index={idx}
                  key={id}
                  priority={onboarding_title}
                  priorityURL={link}
                  socialCount={social_count}
                />
              )
            )}
            <AddPrioritiesCard toggleMenu={toggleMenu} />
          </Slider>
        </Box>
      </Slide>
    </Stack>
  )
}

MyPrioritiesCarousel.propTypes = {
  priorities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleMenu: PropTypes.func.isRequired
}

export default MyPrioritiesCarousel
