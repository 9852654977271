import { useEffect, useMemo, useState } from 'react'
import {
  not,
  isNil,
  prop,
  find,
  or,
  gte,
  length,
  isEmpty,
  path,
  map,
  equals
} from 'ramda'
import {
  Box,
  Button,
  Container,
  Divider,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ArticleContentSource from './ArticleContentSource'
import SocialBadge from 'components/social-badge/SocialBadge'
import ShareButton from './ShareButton'
import Favorite from './Favorite'
import RecommendedCards from 'components/cards/RecommendedCards'
import RecommendedSubdomains from './RecommendedSubdomains'
import PreparedArticle from './PreparedArticle'
import UserFeedback from './UserFeedback'
import ECRM from './ECRM'
import Icon from 'components/icon/Icon'

const Article = ({ card = {} }) => {
  const navigate = useNavigate()
  const { userDomains } = useSelector(prop('user'))
  const { sendEvent } = useAnalytics()

  const [eventFired, setEventFired] = useState(false)

  const params = useMemo(
    () => ({
      campus_resource: prop('campus_resource', card),
      card_domain: prop('domain_name', card),
      card_groups: prop('groups', card)
        ? map(prop('name'), prop('groups', card))
        : [],
      card_id: prop('id', card),
      card_name: prop('title', card).replace('"', ''),
      card_type: convertCardTypeToString(prop('type', card))
    }),
    [card]
  )

  useEffect(() => {
    if (not(isNil(card))) {
      // makes sure we only fire the card_detail_view event once per render.
      // since favoriting a card causes a mutation and a reload, we were getting
      // false card detail view event triggers any time we favorited the card
      if (not(eventFired)) {
        sendEvent('card_detail_view', {
          ...params
        })
        setEventFired(true)
      }
    }
  }, [card, eventFired, params, sendEvent])

  if (or(isNil(userDomains), isEmpty(card)))
    return (
      <Stack
        alignItems="center"
        data-testid="skeleton"
        spacing={2}
        sx={{ height: 'calc(100vh - 220px)', mt: 2, px: 2, width: 1 }}
      >
        <Skeleton height={16} variant="text" width={100} />
        <Skeleton height={64} variant="text" width="100%" />
        <Skeleton height={40} variant="text" width={88} />
        <Skeleton height={500} variant="rectangular" width="100%" />
      </Stack>
    )

  const subdomain = find(
    ({ id }) => equals(prop('section_id', card), id),
    userDomains
  )

  return (
    <>
      <Container sx={{ position: 'relative' }}>
        <Button
          color="inherit"
          onClick={() => navigate(-1)}
          startIcon={<Icon>arrow_back</Icon>}
          sx={{
            left: 0,
            position: { mobile: 'relative', tablet: 'absolute' },
            top: { mobile: 0, tablet: 80 }
          }}
          variant="text"
        >
          Back
        </Button>
        <Stack
          spacing={3}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 700,
            pt: { mobile: 1, tablet: 12.5 },
            width: 1
          }}
        >
          <Stack alignItems="center" spacing={1} sx={{ width: 1 }}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="overline">
                {prop('title', subdomain)}
              </Typography>
              <Typography textAlign="center" variant="h1">
                {prop('title', card)}
              </Typography>
            </Stack>

            {prop('content_source', card) && (
              <ArticleContentSource
                articleImage={path(['content_source', 'article_image'], card)}
                articleText={path(['content_source', 'article_text'], card)}
                link={path(['content_source', 'link'], card)}
              />
            )}

            <Stack alignItems="center" direction="row" spacing={0.5}>
              <Favorite
                id={prop('id', card)}
                isBookmarked={prop('is_favorite', card)}
                params={params}
              />
              <ShareButton params={params} title={prop('title', card)} />
            </Stack>

            {gte(prop('card_viewed_counter', card), 50) && (
              <SocialBadge socialCount={prop('card_viewed_counter', card)} />
            )}
          </Stack>

          <ECRM />

          <PreparedArticle article={card} />

          <Box>
            <UserFeedback {...card} />
          </Box>
        </Stack>
        {gte(length(prop('recommended_cards', card)), 1) && (
          <>
            <Divider />
            <RecommendedCards
              blockTitle="Article Recommended"
              recommendedCards={prop('recommended_cards', card)}
              type={prop('type', card)}
            />
          </>
        )}
      </Container>
      <RecommendedSubdomains subdomains={prop('all_card_domains', card)} />
    </>
  )
}

Article.propTypes = {
  card: PropTypes.shape({})
}

export default Article
