import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { isEmpty, not, prop } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const PrioritizedIcon = ({ title = null }) => {
  const { interested_subdomains } = useSelector(prop('user'))

  const isUserPriority = useMemo(() => {
    if (isEmpty(interested_subdomains)) return false
    return interested_subdomains.some(({ onboarding_title }) =>
      onboarding_title.includes(title)
    )
  }, [interested_subdomains, title])

  if (not(isUserPriority)) return null

  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
      <Icon sx={{ fontSize: '14px !important' }}>arrow_upward</Icon>
      <Typography variant="overline">
        <FormattedMessage defaultMessage="My Priority" id="Sg7kXe" />
      </Typography>
      <Typography sx={{ pb: 0.5 }}>&#8226;</Typography>
    </Stack>
  )
}

PrioritizedIcon.propTypes = {
  title: PropTypes.string
}

export default PrioritizedIcon
