import PropTypes from 'prop-types'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { propEq, prop, length, sortBy, find } from 'ramda'
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'
import { useSelector } from 'react-redux'

const CompletedGoalCard = ({ card }) => {
  const { userDomains } = useSelector(prop('user'))

  const subdomainId = prop('subdomain_id', card)
  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomain = find(propEq(subdomainId, 'id'))(subdomains)

  const title = prop('title', card)
  const steps = sortBy(prop('order'))(prop('steps', card))
  const stepLength = length(steps)

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="overline">{prop('title', subdomain)}</Typography>
          <Typography variant="h4">{title}</Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                width: 1
              }}
            >
              <LinearProgress value={100} variant="determinate" />
            </Box>
            <Typography
              aria-label={`${stepLength} / ${stepLength} steps`}
              ml="8px"
              variant="caption"
              whiteSpace="nowrap"
            >
              {stepLength} / {stepLength} steps
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

CompletedGoalCard.propTypes = {
  card: PropTypes.shape({}).isRequired
}

export default CompletedGoalCard
