import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  completedSelfCheck: false,
  createdGoals: [],
  emailOptIn: false,
  location: null,
  realityCheckAnswers: [],
  realityCheckQuestion: {
    answers: []
  },
  selectedInterests: []
}

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    resetState: () => {
      return initialState
    },
    setCompletedSelfCheck: (state, { payload }) =>
      assoc('completedSelfCheck', payload, state),
    setCreatedGoals: (state, { payload }) =>
      assoc('createdGoals', payload, state),
    setEmailOptIn: (state, { payload }) => assoc('emailOptIn', payload, state),
    setLocation: (state, { payload }) => assoc('location', payload, state),
    setRealityCheckAnswers: (state, { payload }) =>
      assoc('realityCheckAnswers', payload, state),
    setRealityCheckQuestion: (state, { payload }) =>
      assoc('realityCheckQuestion', payload, state),
    setSelectedInterests: (state, { payload }) =>
      assoc('selectedInterests', payload, state)
  }
})

export const {
  resetState,
  setCompletedSelfCheck,
  setCreatedGoals,
  setEmailOptIn,
  setLocation,
  setRealityCheckAnswers,
  setRealityCheckQuestion,
  setSelectedInterests
} = onboardingSlice.actions

export default onboardingSlice.reducer
