import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { keyframes } from '@emotion/react'
import { equals, prop } from 'ramda'
import { LinearProgress, Stack, Typography } from '@mui/material'

const QuestionSummary = ({ question, selectedAnswerIndex }) => {
  const [results, setResults] = useState([])

  useEffect(() => {
    // grab stats from the question (as of 3/16/2022, this should only be populated if question has been answered in this session)
    const stats = question.stats
    // we have to use the keys here so we can access the individual values
    const total = Object.keys(stats).reduce(
      (sum, key) => sum + parseFloat(prop(key, stats) || 0),
      0
    )
    // loop through question's answers to generate the below values
    // selectedAnswerIndex here is the index value of the answer in the list
    // we add 1 to the index here because the stats object looks like this:
    // {1 : 123, 2: 345, 3: 456, 4: 567}
    const summaryResults = question.answers.map((answer, index) => {
      return {
        answer: answer,
        percentage: Math.round((stats[index + 1] / total) * 100),
        selected: selectedAnswerIndex === index + 1
      }
    })
    setResults(summaryResults)
  }, [question.answers, question.stats, selectedAnswerIndex])

  const fade = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  })

  return (
    <Stack
      aria-live="assertive"
      role="status"
      spacing={1}
      sx={{
        animation: `${fade} ease 1s`,
        flexWrap: 'wrap'
      }}
    >
      {results.map(({ percentage, answer }, idx) => (
        <Stack key={idx} sx={{ width: 1 }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ width: 1 }}
          >
            <LinearProgress
              aria-hidden="true"
              isSelected={equals(idx, selectedAnswerIndex)}
              sx={{ width: 1 }}
              value={percentage}
              variant="determinate"
            />
            <Typography variant="subtitle1">{percentage}%</Typography>
          </Stack>
          <Typography variant="subtitle2">{prop('answer', answer)}</Typography>
        </Stack>
      ))}
    </Stack>
  )
}

QuestionSummary.propTypes = {
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    stats: PropTypes.shape({})
  }).isRequired,
  selectedAnswerIndex: PropTypes.number.isRequired
}

export default QuestionSummary
