export const MuiCssBaseline = palette => ({
  styleOverrides: {
    '*': {
      fontSize: 'clamp(100%, 1vw, 300%)',
      scrollBehavior: 'smooth'
    },
    '.no-scroll': {
      height: '100vh',
      overflow: 'hidden',
      position: 'fixed',
      width: '100vw'
    },
    // Styling for scrollbar
    '::-webkit-scrollbar': {
      height: '4px',
      paddingBottom: '2px',
      paddingTop: '2px',
      width: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      background: palette.surface.highest,
      border: `2px solid ${palette.surface.highest}`,
      borderRadius: 8
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    a: {
      color: palette.primary.swatch
    },
    article: {
      backgroundColor: palette.surface.base
    },
    body: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      WebkitOverflowScrolling: 'touch',
      backgroundColor: palette.surface.base,
      margin: 0,
      msOverflowStyle: 'none',
      padding: 0,
      scrollBehavior: 'smooth'
    },
    html: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      backgroundColor: palette.surface.base,
      fontSize: 'clamp(100%, 1vw, 300%)',
      margin: 0,
      msOverflowStyle: 'none',
      padding: 0,
      scrollBehavior: 'smooth'
    }
  }
})
