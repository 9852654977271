import { css, Global, useTheme } from '@emotion/react'
import { path } from 'ramda'

const HeadComponent = () => {
  const { palette } = useTheme()

  return (
    <Global
      styles={css`
        // Calendar styling
        .MuiPickersCalendarHeader-labelContainer {
          white-space: nowrap;
        }

        // Safari only
        @media not all and (min-resolution: 0.001dpcm) {
          -webkit-font-smoothing: subpixel-antialiased;
        }

        @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .input--focused {
          animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
        }

        // Carousel
        .slick-list {
          padding: 0;
          overflow: hidden;
        }

        .slick-slider {
          position: relative;
        }

        .slick-arrow {
          padding: 0;
          z-index: 1;
        }

        .slick-next,
        .slick-prev {
          border-radius: 50%;
          background-size: cover;
          box-shadow: 0 2px 4px rgba(79, 79, 79, 0.25);
          height: 32px;
          width: 32px;
          position: absolute;
          // Best way to center from top
          // 50% of the parent height minus the height of the component
          top: calc(50% - 35px);
          cursor: pointer;
          border: none;
          z-index: 2;
        }
        .slick-next {
          &:after {
            content: '';
            position: absolute;
            z-index: 1;
            width: 60px;
            top: -107px;
            height: 225px;
            pointer-events: none;
            background: linear-gradient(
              270deg,
              ${path(['surface', 'base'], palette)} 0%,
              ${path(['surface', 'lowest'], palette)}50 64.79%,
              ${path(['surface', 'lowest'], palette)}10 100%
            );
            right: -20px;
          }
          background-image: url('/images/icons/arrow-carot-right-black.svg');
          right: 20px;
        }
        .slick-prev {
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 60px;
            top: -107px;
            height: 225px;
            pointer-events: none;
            background: linear-gradient(
              90deg,
              ${path(['surface', 'base'], palette)} 0%,
              ${path(['surface', 'lowest'], palette)}50 64.79%,
              ${path(['surface', 'lowest'], palette)}10 100%
            );
            left: -20px;
          }
          background-image: url('/images/icons/arrow-carot-left-black.svg');
          left: 20px;
        }

        .slick-disabled {
          visibility: hidden;
        }

        .slick-track {
          align-items: stretch;
          display: flex;
          flex-direction: row;
          margin: 0 auto 8px;
        }

        .slick-list {
          @media screen and (min-width: 768px) {
            margin-top: 40px;
          }
          margin-top: 20px;
        }

        .fill {
          font-variation-settings: 'FILL' 1;
        }
      `}
    />
  )
}

export default HeadComponent
