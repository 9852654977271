/* eslint-disable sort-keys */
import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { and, equals, not, prop } from 'ramda'
import { useSelector } from 'react-redux'

import StepHeading from './components/StepHeading'
import StepNavigation from './components/StepNavigation'

import Intro from 'pages/onboarding/components/Intro'
import Interest from 'pages/onboarding/components/Interest'
import SelfCheck from 'pages/onboarding/components/SelfCheck'
import Goals from 'pages/onboarding/components/Goals'
import EmailOptIn from 'pages/onboarding/components/EmailOptIn'
import FlowHeader from 'components/header/FlowHeader'

const OnboardingLayout = () => {
  const { step } = useParams()
  const { is_secondary_user } = useSelector(prop('user'))

  const renderChild = () => {
    if (equals('introduction', step)) return <Intro />
    if (equals('interests', step)) return <Interest />
    if (equals('self-check', step)) return <SelfCheck />
    if (and(equals('goals', step), not(is_secondary_user))) return <Goals />
    if (equals('email', step)) return <EmailOptIn />
  }

  return (
    <>
      <Stack sx={{ pb: { mobile: 6, laptop: 12 } }}>
        <FlowHeader />
        <StepHeading />
        {renderChild()}
      </Stack>
      {not(equals('introduction', step)) && <StepNavigation step={step} />}
    </>
  )
}

export default OnboardingLayout
