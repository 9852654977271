import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import {
  assoc,
  dec,
  equals,
  filter,
  find,
  findIndex,
  inc,
  isNil,
  length,
  not,
  prop,
  propEq,
  sortBy
} from 'ramda'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { subdomainsFromUserDomains } from 'utils/helpers'
import { useUpdateGoalMutation } from 'api/goalsApi'
import Icon from 'components/icon/Icon'
import { format } from 'date-fns'

const InProgressGoalCard = ({ card = null }) => {
  const navigate = useNavigate()
  const { userDomains } = useSelector(prop('user'))
  const [updateGoal] = useUpdateGoalMutation()

  const subdomainId = prop('subdomain_id', card)
  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomain = find(propEq(subdomainId, 'id'))(subdomains)

  const title = prop('title', card)
  const id = prop('id', card)
  const steps = sortBy(prop('order'))(prop('steps', card))
  const stepLength = length(steps)
  const totalCompleted = filter(propEq(true, 'completed'), steps)
  const stepToDisplay = find(propEq(false, 'completed'))(steps)
  const dueDate = prop('due_date', card)

  // If goal is completed then return step length
  // If goal is not completed but all steps are completed return the last step, but increase the order prop
  // Else return the first non completed step
  const currentStep = () => {
    if (prop('isCompleted', card)) return stepLength
    if (isNil(stepToDisplay))
      return assoc(
        'order',
        inc(prop('order', steps[dec(stepLength)])),
        steps[dec(stepLength)]
      )
    return stepToDisplay
  }

  // Complete Goal
  const completeGoal = () => {
    updateGoal({
      body: assoc('completed', true, card),
      id
    })
    navigate('/goals/complete')
  }

  const handleUpdate = () => {
    const copy = [...steps]
    const foundIndex = findIndex(propEq(prop('id', currentStep()), 'id'))(steps)
    const updatedStep = assoc('completed', true, currentStep())
    copy[foundIndex] = updatedStep
    // // For some reason you have to sort by order first
    // // otherwise the order gets rearranged on PUT
    const body = assoc('steps', sortBy(prop('order'))(copy), card)

    updateGoal({
      body,
      id
    })
  }

  return (
    <Stack
      component={Card}
      sx={{
        justifyContent: 'space-between',
        minHeight: 368
      }}
    >
      <CardContent
        sx={{
          height: 'auto'
        }}
      >
        <Stack spacing={2}>
          <Typography variant="overline">{prop('title', subdomain)}</Typography>
          <Typography variant="h4">{title}</Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                width: 1
              }}
            >
              <LinearProgress
                value={(length(totalCompleted) / stepLength) * 100}
                variant="determinate"
              />
            </Box>
            <Typography
              aria-label={`${length(totalCompleted)} / ${stepLength} steps`}
              ml="8px"
              variant="caption"
              whiteSpace="nowrap"
            >
              {length(totalCompleted)} / {stepLength} steps
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2">Current Step</Typography>
              {not(isNil(dueDate)) && (
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <Icon fill>schedule</Icon>
                  <Typography variant="subtitle1">
                    Due {format(dueDate, 'MM/dd/u')}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Typography variant="body2">{currentStep()?.name}</Typography>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'space-between',
          pb: 3,
          px: 2.5
        }}
      >
        <Button
          LinkComponent={Link}
          sx={{ px: 2 }}
          to={`/goals/view/${id}`}
          variant="text"
        >
          View Goal
        </Button>
        {equals(length(totalCompleted), stepLength) ? (
          <Button onClick={completeGoal}>Complete Goal</Button>
        ) : (
          <Button onClick={handleUpdate}>Mark Step Complete</Button>
        )}
      </CardActions>
    </Stack>
  )
}

InProgressGoalCard.propTypes = {
  card: PropTypes.shape({}).isRequired
}

export default InProgressGoalCard
