import { Stack } from '@mui/material'
import { or, prop } from 'ramda'
import { useSelector } from 'react-redux'

import ProfileAlert from './ProfileAlert'
import StaffFacultyAlert from './StaffFacultyAlert'

const AlertWrapper = () => {
  const { showProfile, showStaff } = useSelector(prop('alert'))

  return (
    <Stack
      sx={{
        bottom: 12,
        maxWidth: 675,
        position: 'fixed',
        right: 8,
        width: 1,
        zIndex: or(showProfile, showStaff) ? 10 : -1
      }}
    >
      <ProfileAlert />
      <StaffFacultyAlert />
    </Stack>
  )
}

export default AlertWrapper
