export const MuiAvatar = () => ({
  variants: [
    {
      props: { variant: 'subdomain' },
      style: {
        height: 91,
        width: 91
      }
    },
    {
      props: { variant: 'profile' },
      style: {
        ':hover': {
          boxShadow: '0px 2px 4px rgba(79, 79, 79, 0.25)',
          transition: 'all .3s ease-in-out'
        },
        '@media(max-width: 600px)': {
          height: 95,
          width: 95
        },
        height: 137,
        width: 137
      }
    },
    {
      props: { variant: 'topic' },
      style: {
        height: 158,
        width: 158
      }
    }
  ]
})
