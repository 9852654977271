import PropTypes from 'prop-types'
import { defaultTo, equals, gte, length, prop } from 'ramda'
import ProfileQuestion from './ProfileQuestion'

const getUserAnswersForQuestion = (question, userAnswers = []) => {
  const answer = userAnswers.find(({ profile_question_id }) =>
    equals(profile_question_id, prop('id', question))
  )
  // user's answer can be 'multi answer'.. so a list is returned
  return defaultTo([], prop('answers', answer))
}

function ProfileQuestions({ userAnswers = [], questions = [] }) {
  const formattedQuestions = questions.map(question => [
    question,
    getUserAnswersForQuestion(question, userAnswers)
  ])

  return (
    gte(length(formattedQuestions), 0) &&
    formattedQuestions.map(([question, userAnswers]) => (
      <ProfileQuestion
        key={question.id}
        question={question}
        userAnswers={userAnswers}
      />
    ))
  )
}

ProfileQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  userAnswers: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ProfileQuestions
