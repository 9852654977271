import PropTypes from 'prop-types'
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import {
  and,
  any,
  append,
  equals,
  findIndex,
  gt,
  isEmpty,
  not,
  or,
  path,
  without
} from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  setContentType,
  setIsMenuOpen,
  setTempContent,
  setTempTopic,
  setTopic
} from './filterSlice'
import { useEffect, useMemo } from 'react'
import useBuildFilters from './useBuildFilters'
import { useLocation } from 'react-router'
import { useAnalytics } from 'hooks'
import Icon from 'components/icon/Icon'

const MobileFilter = ({ hasContentFilter = false, hasTopicFilter = false }) => {
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('laptop'))
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()
  const { contentOptions, topicOptions } = useBuildFilters()

  const isMenuOpen = useSelector(path(['filter', 'isMenuOpen']))
  const contentType = useSelector(path(['filter', 'contentType']))
  const topic = useSelector(path(['filter', 'topic']))
  const tempTopic = useSelector(path(['filter', 'tempTopic']))
  const tempContentType = useSelector(path(['filter', 'tempContentType']))

  const isBtnDisabled = and(isEmpty(topic), isEmpty(contentType))

  // On Screen Resize
  // close drawer
  useEffect(() => {
    if (and(isDesktop, isMenuOpen)) dispatch(setIsMenuOpen(false))
  }, [dispatch, isDesktop, isMenuOpen])

  const isApplyDisabled = useMemo(() => {
    if (
      or(
        not(equals(tempContentType, contentType)),
        not(equals(topic, tempTopic))
      )
    )
      return false
    return true
  }, [contentType, topic, tempTopic, tempContentType])

  const handleApply = () => {
    dispatch(setTopic(tempTopic))
    dispatch(setContentType(tempContentType))
    dispatch(setIsMenuOpen(false))
  }

  const handleClear = () => {
    dispatch(setTopic([]))
    dispatch(setContentType([]))
    dispatch(setTempTopic([]))
    dispatch(setTempContent([]))
  }

  const eventName = useMemo(() => {
    if (
      any(equals(true))([
        pathname.includes('succeed'),
        pathname.includes('thrive'),
        pathname.includes('matter')
      ])
    )
      return 'you_subomain_filter'
    return 'filter_selection'
  }, [pathname])

  const handleAnalytics = val => {
    sendEvent(eventName, {
      filter_selection: val,
      page_title: pathname
    })
  }

  const handleContentSelect = val => {
    handleAnalytics(val)
    const found = findIndex(equals(val), tempContentType)
    if (gt(found, -1)) {
      dispatch(setTempContent(without([val], tempContentType)))
      return
    }
    dispatch(setTempContent(append(val, tempContentType)))
  }

  const handleTopicChange = val => {
    handleAnalytics(val)
    const found = findIndex(equals(val), tempTopic)
    if (gt(found, -1)) {
      dispatch(setTempTopic(without([val], tempTopic)))
      return
    }
    dispatch(setTempTopic(append(val, tempTopic)))
  }

  return (
    <Drawer
      anchor="left"
      onClose={() => dispatch(setIsMenuOpen(false))}
      open={isMenuOpen}
      PaperProps={{
        sx: {
          borderBottomRightRadius: 16,
          borderTopRightRadius: 16,
          p: 1
        }
      }}
    >
      <Stack spacing={2}>
        <IconButton
          aria-label="close filter sidebar"
          onClick={() => dispatch(setIsMenuOpen(false))}
          sx={{
            alignSelf: 'flex-end',
            // eslint-disable-next-line sort-keys
            display: { mobile: 'flex', laptop: 'none' },
            mr: 1,
            mt: 1
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <Stack spacing={3}>
          {hasTopicFilter && (
            <Stack spacing={1.5}>
              <Typography variant="caption">Select a Category</Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {topicOptions.map(opt => (
                  <Button
                    key={opt}
                    onClick={() => handleTopicChange(opt)}
                    selected={tempTopic.includes(opt)}
                    variant="select"
                  >
                    {opt}
                  </Button>
                ))}
              </Stack>
            </Stack>
          )}
          {hasContentFilter && (
            <Stack spacing={1.5}>
              <Typography variant="caption">Select content types</Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {contentOptions.map(opt => (
                  <Button
                    key={opt}
                    onClick={() => handleContentSelect(opt)}
                    selected={tempContentType.includes(opt)}
                    variant="select"
                  >
                    {opt}
                  </Button>
                ))}
              </Stack>
            </Stack>
          )}
          <Divider />
          <Button disabled={isApplyDisabled} fullWidth onClick={handleApply}>
            Apply Changes
          </Button>
          <Button
            disabled={isBtnDisabled}
            fullWidth
            onClick={handleClear}
            variant="text"
          >
            Clear Filters
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  )
}

MobileFilter.propTypes = {
  hasContentFilter: PropTypes.bool,
  hasTopicFilter: PropTypes.bool
}

export default MobileFilter
