/* eslint-disable sort-keys */
import { Container, LinearProgress, Stack, Typography } from '@mui/material'
import { find, findIndex, inc, length, prop, propEq } from 'ramda'
import { useParams } from 'react-router-dom'
import useGetSteps from './useGetSteps'

// Expects all text props to be pre-formatted with react-intl
const StepHeading = () => {
  const { step } = useParams()
  const stepsToRender = useGetSteps()

  const currStep = inc(findIndex(propEq(step, 'url'), stepsToRender))
  const foundStep = find(propEq(step, 'url'), stepsToRender)
  const subtitle = prop('subtitle', foundStep)

  return (
    <Container maxWidth="tablet" sx={{ my: { mobile: 2.5, laptop: 5 } }}>
      <Stack alignItems="center" spacing={3} sx={{ width: 1 }}>
        <Typography textAlign="center" variant="overline">
          Step {currStep}: {prop('title', foundStep)}
        </Typography>
        <LinearProgress
          color="inherit"
          sx={{
            maxWidth: 418,
            width: 1
          }}
          value={(currStep / length(stepsToRender)) * 100}
          variant="determinate"
        />
        <Typography textAlign="center" variant="h1">
          {prop('heading', foundStep)}
        </Typography>
        {subtitle && (
          <Typography textAlign="center" variant="subtitle1">
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Container>
  )
}

export default StepHeading
