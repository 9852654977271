import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import { useGetPanicCardQuery } from 'api/schoolApi'
import { isNil, prop } from 'ramda'
import { Button } from '@mui/material'
import useAnalytics from 'hooks/useAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import Icon from 'components/icon/Icon'

const CrisisButton = () => {
  const dispatch = useDispatch()
  const { domain } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const { data } = useGetPanicCardQuery(domain, {
    skip: isNil(domain)
  })

  const toggleCrisis = () => {
    GA.click('Crisis-Header-Button', 'Click')
    sendEvent('block_interactions', {
      block_title: 'Logged Out Header',
      click_element: 'Link',
      element_title: 'Help in Crisis',
      path: window.location.pathname
    })
    if (data) dispatch(setIsCrisisModalOpen(true))
  }

  return (
    <Button
      aria-haspopup="dialog"
      onClick={toggleCrisis}
      startIcon={<Icon>support</Icon>}
      variant="tonal"
    >
      <FormattedMessage defaultMessage="Help in Crisis" id="PRqLkL" />
    </Button>
  )
}

export default CrisisButton
