const authenticationRoutes = ['sign-up', 'sign-in', 'password-reset', 'logout']

const publicRoutes = [
  'accessibility',
  'privacy',
  'terms',
  'public',
  'verify',
  'referral'
]

export const privateRoutes = [
  'home',
  'explore',
  'self-checks',
  'goals',
  'stats-dashboard',
  'resources',
  'student',
  'favorites',
  'article',
  'onboarding',
  'profile',
  'resources-near-me',
  'stories',
  'succeed',
  'matter',
  'thrive',
  'search'
]

export const onPublicRoute = pathname =>
  publicRoutes.some(route => pathname.includes(route))
export const onAuthRoute = pathname =>
  authenticationRoutes.some(route => pathname.includes(route))
