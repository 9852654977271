import PropTypes from 'prop-types'
import { not, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import NextStepCard from './NextStepCard'

const NextSteps = ({ subdomainId }) => {
  const { hide_resources } = useSelector(prop('school'))

  return (
    <Stack
      direction="row"
      sx={{
        flexWrap: 'wrap',
        gap: 2,
        width: 1
      }}
    >
      <NextStepCard subdomainId={subdomainId} title="Take Action" />
      <NextStepCard subdomainId={subdomainId} title="Get Advice" />
      {not(hide_resources) && (
        <NextStepCard subdomainId={subdomainId} title="Connect" />
      )}
    </Stack>
  )
}

NextSteps.propTypes = {
  subdomainId: PropTypes.number.isRequired
}

export default NextSteps
