import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import {
  and,
  assoc,
  compose,
  defaultTo,
  equals,
  gt,
  isNil,
  length,
  lt,
  not,
  or,
  path,
  prop,
  tap
} from 'ramda'
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  setEmail,
  setPassword,
  setRevalidation
} from 'store/authenticationSlice'
import Icon from 'components/icon/Icon'

const EmailAndPassword = ({ revalidate = false }) => {
  const dispatch = useDispatch()
  const { email, password, revalidation } = useSelector(prop('authentication'))

  const [showPassword, setShowPassword] = useState(false)
  const [showRevalidation, setShowRevalidation] = useState(false)
  const [errors, setErrors] = useState({
    email: null,
    password: null
  })

  const validateEmail = email => {
    const regex =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setErrors(assoc('email', not(regex.test(email)), errors))
  }

  const validatePassword = pass => {
    if (or(lt(length(pass), 8), gt(length(pass), 13))) {
      setErrors(assoc('password', true, errors))
      return
    }
    setErrors(assoc('password', false, errors))
  }

  const handleEmail = val => dispatch(setEmail(val))
  const handlePassword = val => dispatch(setPassword(val))
  const handleRevalidation = val => dispatch(setRevalidation(val))

  return (
    <Stack alignItems="center" spacing={4} sx={{ mb: 3, width: 1 }}>
      <TextField
        defaultValue={defaultTo('', email)}
        fullWidth
        helperText={
          prop('email', errors) ? (
            <FormattedMessage
              defaultMessage="Hmm, this email address isn't valid."
              id="/g9wJ/"
            />
          ) : (
            <Tooltip
              title={
                <Stack spacing={1}>
                  <Typography component="span" variant="caption">
                    <FormattedMessage
                      defaultMessage="YOU is a confidential platform. Your email is used only to establish an account so that you can save resources, assessments, and personalized interests."
                      id="dxLav5"
                    />
                  </Typography>
                  <Typography component="span" variant="caption">
                    <FormattedMessage
                      defaultMessage="We may send informational and reminder emails from time to time, but will never sell your email address or share any personal information about your usage of this tool."
                      id="f7YyLz"
                    />
                  </Typography>
                </Stack>
              }
            >
              <Button
                role="tooltip"
                startIcon={<Icon fill>info</Icon>}
                sx={{ fontSize: 12, p: 0, textTransform: 'initial' }}
                variant="text"
              >
                <FormattedMessage
                  defaultMessage="Why do you need my email?"
                  id="C6vxyl"
                />
              </Button>
            </Tooltip>
          )
        }
        id="email"
        inputProps={{ 'aria-label': 'Email' }}
        InputProps={{
          autoComplete: 'off'
        }}
        label={<FormattedMessage defaultMessage="Campus Email" id="AqSFPr" />}
        onChange={compose(
          validateEmail,
          tap(handleEmail),
          path(['target', 'value'])
        )}
        required
        type="email"
      />

      <TextField
        defaultValue={defaultTo('', password)}
        fullWidth
        helperText={
          <FormattedMessage defaultMessage="8-13 characters" id="lco3nU" />
        }
        id="password"
        inputProps={{
          'aria-label': 'Password'
        }}
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="Show Password"
                onClick={() => setShowPassword(not(showPassword))}
              >
                {showPassword ? (
                  <Icon>visibility_off</Icon>
                ) : (
                  <Icon>visibility</Icon>
                )}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            backgroundColor: 'surface.container'
          }
        }}
        label={<FormattedMessage defaultMessage="Password" id="5sg7KC" />}
        onChange={compose(
          validatePassword,
          tap(handlePassword),
          path(['target', 'value'])
        )}
        required
        type={showPassword ? 'text' : 'password'}
      />

      {revalidate && (
        <TextField
          defaultValue={defaultTo('', revalidation)}
          error={and(
            not(isNil(revalidation)),
            not(equals(password, revalidation))
          )}
          fullWidth
          helperText={
            and(
              not(isNil(revalidation)),
              not(equals(password, revalidation))
            ) && (
              <FormattedMessage
                defaultMessage="Make sure your passwords match"
                id="2iOMhc"
              />
            )
          }
          id="re-validate"
          inputProps={{ 'aria-label': 'Revalidation' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="Show Revalidation"
                  onClick={() => setShowRevalidation(not(showRevalidation))}
                >
                  {showRevalidation ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          label={
            <FormattedMessage defaultMessage="Re-enter Password" id="qxYRGU" />
          }
          onChange={compose(handleRevalidation, path(['target', 'value']))}
          required
          type={showRevalidation ? 'text' : 'password'}
        />
      )}

      {not(revalidate) && (
        <Button LinkComponent={Link} to="/password-reset" variant="text">
          <FormattedMessage defaultMessage="Reset my password" id="OXLLjP" />
        </Button>
      )}
    </Stack>
  )
}

EmailAndPassword.propTypes = {
  revalidate: PropTypes.bool
}

export default EmailAndPassword
