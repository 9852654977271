import { or, isNil, sortBy, prop, propEq, find } from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import TakeQuizCard from './components/TakeQuizCard'
import CardGridWrapper from 'components/cards/CardGridWrapper'

const SelfChecks = () => {
  const { userDomains } = useSelector(prop('user'))

  const { data: selfChecks = [], isFetching = true } =
    useGetSelfCheckQuizDataQuery()

  const findDomain = domain =>
    find(propEq(prop('id', domain), 'id'), selfChecks)

  return (
    <Stack
      spacing={4}
      sx={{
        alignItems: 'center',
        mb: 6,
        px: 2
      }}
    >
      <Stack spacing={3}>
        <Typography textAlign="center" variant="h1">
          Check-in with yourself
        </Typography>
        <Typography component="span" textAlign="center" variant="subtitle1">
          Self Checks provide a quick snapshot of your well-being.
        </Typography>
      </Stack>
      <CardGridWrapper isLoading={or(isFetching, isNil(selfChecks))}>
        {sortBy(prop('sort'), userDomains).map(domain => (
          <TakeQuizCard
            domainUrlSlug={prop('frontend_url_slug', domain)}
            isCompleted={prop('completed', findDomain(domain))}
            key={prop('id', domain)}
            socialCount={prop(
              'reality_check_quiz_social_count',
              findDomain(domain)
            )}
            subdomains={prop('subdomains', domain)}
            title={prop('frontend_name', domain)}
          />
        ))}
      </CardGridWrapper>
    </Stack>
  )
}

export default SelfChecks
