import { useMemo, useRef, useState } from 'react'
import { Pagination, Stack } from '@mui/material'
import { useGetCardsQuery } from 'api/cardsApi'
import { compose, isNil, nthArg, path, prop } from 'ramda'
import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import { useSelector } from 'react-redux'

const TrendingBlock = () => {
  const { domain } = useSelector(prop('school'))
  const scrollRef = useRef(null)

  const [page, setPage] = useState(1)

  const { data } = useGetCardsQuery(
    {
      org_domain: domain,
      page,
      perPage: 12,
      'sort[method]': 'recency'
    },
    { skip: isNil(domain) }
  )

  const cards = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack
      alignItems="center"
      ref={scrollRef}
      spacing={3}
      sx={{ maxWidth: 1068, width: 1 }}
    >
      <CardGridWrapper>
        {cards.map((card, i) => (
          <CardHandler blockTitle="New & Trending" card={card} key={i} />
        ))}
      </CardGridWrapper>
      <Pagination
        count={path(['meta', 'lastPage'], data)}
        onChange={compose(handleNav, setPage, nthArg(1))}
        page={page}
      />
    </Stack>
  )
}

export default TrendingBlock
