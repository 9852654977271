import { Box, Container, Slide, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ResourceCard from './ResourceCard'

const cards = [
  6270, 3980, 2308, 17782, 2487, 15305, 13829, 13945, 14919, 13174, 11264,
  11598, 13088, 3598, 13172, 17513, 13166, 6166
]

const Resources = () => (
  // eslint-disable-next-line sort-keys
  <Container maxWidth="monitor">
    <Stack alignItems="center" spacing={3}>
      <Slide direction="left" in>
        <Stack spacing={2} sx={{ maxWidth: 654, textAlign: 'center' }}>
          <Typography component="h1" variant="h2">
            <FormattedMessage
              defaultMessage="You've done the hard part, now let's save some resources you can use later."
              id="OucOZY"
            />
          </Typography>
        </Stack>
      </Slide>
      <Slide direction="left" in timeout={500}>
        <Stack
          alignItems="center"
          sx={{
            maxHeight: 600,
            width: 1
          }}
        >
          <Box
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              },
              display: 'grid',
              gap: 3,
              // must set row height for FF
              // as of 2023 you must set height
              // as it won't auto height like other chromium browswers
              gridAutoRows: 93,
              gridTemplateColumns: {
                mobile: '320px',
                // eslint-disable-next-line sort-keys
                laptop: 'repeat(3, minmax(320px, 1fr))'
              },
              maxWidth: 1086,
              overflowY: 'scroll',
              pb: 10.5,
              px: 2,
              width: 1
            }}
          >
            {cards.map(id => (
              <ResourceCard id={id} key={id} />
            ))}
          </Box>
        </Stack>
      </Slide>
    </Stack>
  </Container>
)

export default Resources
