import { useEffect } from 'react'
import { all, dec, equals, isNil, length, not, path, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from 'hooks'

const AppFlowHandler = () => {
  const domain = useSelector(path(['school', 'domain']))
  const history = useSelector(path(['history', 'history']))
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()

  useEffect(() => {
    const priorPage = prop(dec(dec(length(history))), history)
    if (
      all(equals(true))([not(isNil(domain)), not(equals(priorPage, pathname))])
    ) {
      sendEvent('YOU_App_Flow', {
        page_title: pathname,
        path: pathname,
        prior_page_title: priorPage,
        prior_path: priorPage
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, domain])

  return
}

export default AppFlowHandler
