/* eslint-disable sort-keys */
import { useEffect } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { prop, propEq, defaultTo, isNil, not } from 'ramda'
import { useUpdateProfileMutation } from 'api/profileApi'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'

import Banner from 'components/hero/Banner'
import CardGrid from './components/CardGrid'
import CalloutBox from './components/CalloutBox'
import LocationSelector from './components/LocationSelector'
import FeaturedCards from './components/FeaturedCards'

const ResourcesPage = () => {
  const { banner, locations, show_locations_in_profile, letters } = useSelector(
    prop('school')
  )
  const { location_id, org_resource_context_copy } = useSelector(prop('user'))
  const dispatch = useDispatch()

  const [updateProfile] = useUpdateProfileMutation()

  useEffect(() => {
    dispatch(
      updateUserData({ name: 'has_viewed_campus_resources', value: true })
    )
    // postUserViewedACampusResource
    const body = new FormData()
    body.append('has_viewed_campus_resources', 1)
    updateProfile(body)
  }, [dispatch, updateProfile])

  const locationBannerImage = () => {
    const location = locations?.find(propEq('id', location_id))
    const locationBannerImage = prop('banner', location)
    return defaultTo(banner, locationBannerImage)
  }

  return (
    <Stack>
      {not(isNil(locationBannerImage())) && (
        <Box
          className="banner"
          sx={{
            pt: locationBannerImage ? null : '2%'
          }}
        >
          <Banner imageUrl={locationBannerImage()} isResource />
        </Box>
      )}
      <Stack
        spacing={4}
        sx={{
          alignSelf: 'center',
          maxWidth: { mobile: 1008, monitor: '70rem' },
          mt: 4,
          px: 3,
          width: 1
        }}
      >
        <Stack spacing={4} sx={{ pl: { mobile: 0, monitor: 3 } }}>
          {show_locations_in_profile && (
            <Typography variant="h1">{letters} Resources</Typography>
          )}
          {show_locations_in_profile && <LocationSelector />}
          {not(show_locations_in_profile) && (
            <Typography variant="h1">{letters} Resources</Typography>
          )}
          <CalloutBox topSectionCopy={org_resource_context_copy} />
        </Stack>
        <Divider />
        <FeaturedCards />
        <CardGrid />
      </Stack>
    </Stack>
  )
}

export default ResourcesPage
