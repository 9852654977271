/* eslint-disable sort-keys */
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import { Link, useLocation } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import Icon from 'components/icon/Icon'

const tools = [
  <FormattedMessage
    defaultMessage="Differentiate between positive and negative stress"
    id="WuVWxU"
    key={0}
  />,
  <FormattedMessage
    defaultMessage="Reflect on how stress impacts you"
    id="nMCMWy"
    key={1}
  />,
  <FormattedMessage
    defaultMessage="Identify common stress management techniques"
    id="zgXn6O"
    key={2}
  />
]

const EntryCard = () => {
  const isMobile = useMediaQuery(({ breakpoints }) =>
    breakpoints.down('tablet')
  )
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  return (
    <Card
      sx={{
        gridArea: 'destress',
        maxWidth: '75rem'
      }}
      variant="fullWidth"
    >
      <CardContent
        sx={{
          ':last-child': {
            pb: 3
          },
          alignItems: 'center',
          display: 'grid',
          gridGap: { mobile: '16px 0', tablet: 24 },
          gridTemplateColumns: {
            mobile: '.75fr 1fr',
            tablet: '.5fr 1fr',
            laptop: '224px 1fr'
          },
          justifyItems: { mobile: 'center', tablet: 'unset' },
          p: 3
        }}
      >
        <Box
          sx={{
            alignSelf: { mobile: 'flex-start', tablet: 'unset' },
            backgroundImage: 'url(/vectors/palm.svg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            gridColumn: { mobile: '2', tablet: '1' },
            gridRow: { mobile: '1', tablet: '1 / span 2' },
            height: { mobile: 88, tablet: '100%' },
            width: { mobile: 100, tablet: 1 }
          }}
        />
        <Stack
          spacing={2}
          sx={{
            gridColumn: { mobile: '1', tablet: '2' }
          }}
        >
          <Stack spacing={0.5}>
            <Typography variant="h2">
              <FormattedMessage defaultMessage="De-Stress 101" id="g4qc8N" />
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage="Learn how to manage stress and be your best."
                id="e0BVti"
              />
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Chip
              icon={<Icon fill>flag</Icon>}
              label={<FormattedMessage defaultMessage="9 steps" id="8j/q8t" />}
            />
            <Chip
              icon={<Icon fill>schedule</Icon>}
              label={
                <FormattedMessage defaultMessage="5 minutes" id="++FVOV" />
              }
            />
          </Stack>
        </Stack>
        <Stack
          alignItems={{ mobile: 'unset', tablet: 'flex-end' }}
          direction={{ mobile: 'column', tablet: 'row' }}
          justifyContent="space-between"
          spacing={3}
          sx={{
            gridColumn: { mobile: '1 / span 2', tablet: '2' }
          }}
        >
          <Box sx={{ maxWidth: 629 }}>
            <Stack alignItems="center" direction="row">
              <Typography display="block" sx={{ mr: 0.5 }} variant="subtitle2">
                <FormattedMessage
                  defaultMessage="What you’ll gain:"
                  id="BL5KeV"
                />
              </Typography>
              <Icon sx={{ fontSize: '14px !important' }}>south</Icon>
            </Stack>
            <Stack
              component="ul"
              sx={{
                m: 0,
                mt: 0.5,
                pl: 2
              }}
            >
              {tools.map(tool => (
                <Typography
                  component="li"
                  key={prop('key', tool)}
                  variant="body1"
                >
                  {tool}
                </Typography>
              ))}
            </Stack>
          </Box>
          <Button
            fullWidth={isMobile}
            LinkComponent={Link}
            onClick={() =>
              sendEvent('Interactive Content', {
                path: pathname,
                module_name: 'Destress',
                step_name: 'Get Started',
                trigger: 'Click start button'
              })
            }
            to="/destress/poll"
          >
            <FormattedMessage defaultMessage="start" id="zTylMM" />
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default EntryCard
