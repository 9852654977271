export const MuiCard = palette => ({
  styleOverrides: {
    root: {
      // '@media(min-width: 1440px)': {
      //   width: '20rem'
      // },
      backgroundColor: palette.card.background,
      boxShadow: 'none',
      width: 320
    }
  },
  variants: [
    {
      props: { variant: 'fullWidth' },
      style: {
        '@media(min-width: 1440px)': {
          width: '100%'
        },
        width: '100%'
      }
    },
    {
      props: { variant: 'completed goal' },
      style: {
        borderTop: '2px solid',
        borderTopColor: palette.primary
      }
    },
    {
      props: { variant: 'interactive' },
      style: {
        ':hover': {
          backgroundColor: palette.card.hover
        },
        backgroundColor: palette.surface.container,
        borderRadius: 12,
        transition: 'all .5s'
      }
    },
    {
      props: { selected: true, variant: 'interactive' },
      style: {
        backgroundColor: palette.button.tonal.background
      }
    }
  ]
})
