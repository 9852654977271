/* eslint-disable sort-keys */
import { useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Divider, Pagination, Stack } from '@mui/material'
import {
  compose,
  defaultTo,
  isNil,
  not,
  nthArg,
  path,
  prop,
  reject
} from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import { useSelector } from 'react-redux'
import useBuildFilters from 'components/filter/useBuildFilters'

import UserFeedback from 'components/user-feedback/UserFeedback'
import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'
import Toast from 'components/toast/Toast'
import FilterMenu from 'components/filter/FilterMenu'

const CardGrid = () => {
  const { is_secondary_user, location_id, temp_location_id } = useSelector(
    prop('user')
  )
  const { domain } = useSelector(prop('school'))
  const { domainId, sortValue } = useBuildFilters()
  const scrollRef = useRef(null)

  const [page, setPage] = useState(1)

  const locations = reject(isNil, [location_id, temp_location_id])

  const params = {
    'filter[campus_resource]': 1,
    'filter[domains]': domainId(),
    locations,
    org_domain: domain,
    page,
    perPage: 12,
    'sort[method]': sortValue()
  }

  const { data, error } = useGetCardsQuery(params, { skip: isNil(domain) })

  const getTitleType = () => {
    if (is_secondary_user) return ''
    return <FormattedMessage defaultMessage="campus " id="cA+t+/" />
  }

  const getType = () => {
    if (is_secondary_user) return ''
    return <FormattedMessage defaultMessage="campus " id="cA+t+/" />
  }

  const cards = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack spacing={{ mobile: 2, tablet: 4 }}>
      <Stack mb={3}>
        <Stack alignItems="center" ref={scrollRef} spacing={3}>
          <FilterMenu hasSortMenu hasTopicFilter />
          <CardGridWrapper>
            {cards.map(card => (
              <CardHandler card={card} key={prop('id', card)} />
            ))}
          </CardGridWrapper>
          <Pagination
            count={path(['meta', 'lastPage'], data)}
            onChange={compose(handleNav, setPage, nthArg(1))}
            page={page}
          />
        </Stack>

        <Divider sx={{ mt: 4 }} />
        <UserFeedback
          hasNeutral
          negativeTitle={
            <FormattedMessage
              defaultMessage="Seems like you know your {type}really well."
              id="Yzq0i5"
              values={{
                type: getTitleType()
              }}
            />
          }
          positiveTitle={
            <FormattedMessage
              defaultMessage="Awesome! These resources are here whenever you need them."
              id="RHgDKZ"
            />
          }
          subtitle={
            <FormattedMessage
              defaultMessage="Has this information increased your awareness of {type} resources?"
              id="xMSlqd"
              values={{
                type: getType()
              }}
            />
          }
        />

        <Toast
          isShown={not(isNil(error))}
          message={defaultTo('', prop('message', error))}
          type="error"
        />
      </Stack>
    </Stack>
  )
}

export default CardGrid
