import { append, prop } from 'ramda'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setHistory, setVisitTime } from 'store/historySlice'

const HistoryHandler = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { history } = useSelector(prop('history'))

  useEffect(() => {
    dispatch(setVisitTime(Math.floor(Date.now() / 1000)))
  }, [dispatch])

  useEffect(() => {
    dispatch(setHistory(append(pathname, history)))
    // On history change scroll the user back to the top of the page
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch])

  return
}

export default HistoryHandler
