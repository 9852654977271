import Quicklinks from 'components/quick-links/QuickLinks'
import { useGetDocumentQuery } from 'api/legalApi'
import { isNil, or, prop } from 'ramda'
import { useSelector } from 'react-redux'

const Accessibility = () => {
  const { accessibility_id } = useSelector(prop('school'))

  const { data, isFetching } = useGetDocumentQuery(accessibility_id, {
    skip: isNil(accessibility_id)
  })

  if (or(isFetching, isNil(data))) return null

  return <Quicklinks rawHtml={prop('statement', data)} />
}

export default Accessibility
