import { Outlet, useNavigate } from 'react-router-dom'
import { path } from 'ramda'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import Heading from './components/Heading'
import Navigation from './components/Navigation'
import FlowHeader from 'components/header/FlowHeader'

const DestressLayout = () => {
  const navigate = useNavigate()
  const isSecondaryUser = useSelector(path(['user', 'is_secondary_user']))

  if (isSecondaryUser) navigate('/')

  return (
    <Stack
      sx={{
        alignItems: 'center',
        // eslint-disable-next-line sort-keys
        height: { mobile: 'calc(100vh - 24px)', laptop: 'calc(100vh - 48px)' }
      }}
    >
      <FlowHeader />
      <Heading />
      <Stack
        sx={{
          pb: 15,
          width: 1
        }}
      >
        <Outlet />
      </Stack>
      <Navigation />
    </Stack>
  )
}

export default DestressLayout
