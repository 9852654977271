/* eslint-disable sort-keys */
import { useState } from 'react'
import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { isNil, not, prop } from 'ramda'
import SignUpModal from './SignUpModal'
import { Link, useLocation } from 'react-router-dom'
import { keyframes } from '@emotion/react'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const Introduction = () => {
  const { id } = useSelector(prop('user'))
  const isLoggedIn = not(isNil(id))

  const [isOpen, setIsOpen] = useState(false)
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const slideIn = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
  })

  const fade = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  })

  const handleClick = () => {
    if (not(isLoggedIn)) return setIsOpen(true)
    sendEvent('Interactive Content', {
      module_name: 'Destress',
      path: pathname,
      step_name: 'Get Started',
      trigger: 'Click start button'
    })
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: {
            mobile: '1fr',
            tablet: '.7fr 1fr',
            monitor: '.8fr 1fr'
          },
          width: 1
        }}
      >
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <Stack
          spacing={3}
          sx={{
            animation: `${fade} ease 1.2s`,
            justifySelf: { mobile: 'unset', tablet: 'flex-end' },
            pl: 4
          }}
        >
          <Typography variant="h1">De-Stress 101</Typography>
          <Typography variant="body1">
            Stress is a reality of life. Learn and make a plan to be your best.
          </Typography>
          <Stack direction="row" spacing={3}>
            <Chip
              icon={<Icon fill>flag</Icon>}
              label={<FormattedMessage defaultMessage="9 steps" id="8j/q8t" />}
            />
            <Chip
              icon={<Icon fill>schedule</Icon>}
              label={
                <FormattedMessage defaultMessage="5 minutes" id="++FVOV" />
              }
            />
          </Stack>
          <Stack>
            <Stack alignItems="center" direction="row">
              <Typography component="h2" variant="body1">
                <FormattedMessage
                  defaultMessage="What you'll gain"
                  id="/B1hPK"
                />
              </Typography>
              <Icon sx={{ fontSize: '14px !important' }}>south</Icon>
            </Stack>
            <Box component="ul">
              <Typography component="li">
                <FormattedMessage
                  defaultMessage="An understanding of positive and unhealthy stress"
                  id="wLcTH9"
                />
              </Typography>
              <Typography component="li">
                <FormattedMessage
                  defaultMessage="The impact of stress on your body, thoughts, and relationships"
                  id="bl+7IN"
                />
              </Typography>
              <Typography component="li">
                <FormattedMessage
                  defaultMessage="Practice strategies to harness stress in the moment AND in the
                long-term"
                  id="z2YRLg"
                />
              </Typography>
            </Box>
          </Stack>
          <Button
            LinkComponent={Link}
            onClick={handleClick}
            to={not(isLoggedIn) ? '' : '/destress/poll'}
          >
            <FormattedMessage defaultMessage="Get Started" id="9QXm1E" />
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/interactive-content/background.svg)',
          backgroundPosition: 'right bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          bottom: 0,
          display: { mobile: 'none', tablet: 'block' },
          height: { tablet: 500, laptop: 672 },
          maxWidth: 586,
          position: 'absolute',
          right: 0,
          width: 1
        }}
      />
      <Box
        sx={{
          animation: `${slideIn} ease 1.5s`,
          backgroundImage: 'url(/vectors/interactive-content/cat.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          bottom: 0,
          display: { mobile: 'none', laptop: 'block' },
          height: 361,
          maxWidth: 311,
          position: 'absolute',
          right: 375,
          width: 1
        }}
      />
    </>
  )
}

export default Introduction
