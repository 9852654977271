import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  isLoggedOutOpen: false,
  isOpen: false
}

const sidebarSlice = createSlice({
  initialState,
  name: 'sidebar',
  reducers: {
    setIsLoggedOutOpen: (state, { payload }) =>
      assoc('isLoggedOutOpen', payload, state),
    setIsOpen: (state, { payload }) => assoc('isOpen', payload, state)
  }
})

export const { setIsLoggedOutOpen, setIsOpen } = sidebarSlice.actions

export default sidebarSlice.reducer
