import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useAnalytics } from 'hooks'
import { useLocation } from 'react-router'
import { and, any, equals, isEmpty, isNil, map, not, path, prop } from 'ramda'
import { truncateLines } from 'utils/helpers'
import parse from 'html-react-parser'
import { useFavoriteMutation } from 'api/cardsApi'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'
import { Link } from 'react-router-dom'
import { internalUrl } from 'components/cards/helpers'
import { TYPE_FACT_TIP } from 'utils/card-functions'

import ResourceMask from './ResourceMask'
import CardType from './CardType'
import ImageIcon from './ImageIcon'
import Icon from 'components/icon/Icon'
import { useMemo } from 'react'

const ContentCard = ({ blockTitle = null, card = null }) => {
  // Utils
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const [favorite] = useFavoriteMutation()

  // Card Variables
  const title = prop('title', card)
  const photoDescription = prop('photo_description', card)
  const videoDescription = prop('video_description', card)
  const campusResource = prop('campus_resource', card)
  const isFavorite = prop('is_favorite', card)
  const id = prop('id', card)
  const type = prop('type', card)

  const photo = useMemo(() => {
    if (equals(TYPE_FACT_TIP, type)) return '/vectors/fact-tip-card-bg.svg'
    return path(['photo', 'large_url'], card) ?? prop('video_thumb_url', card)
  }, [card, type])

  if (isNil(card)) return null

  // Helper functions
  const getCleanDescription = (desc, length) => {
    if (isNil(desc)) return
    const split = desc.substring(0, length).split(' ')
    const splitpop = split.splice(0, split.length - 1)
    return splitpop.join(' ')
  }

  const cleanLinkTag = str => {
    if (isNil(str)) return
    return str.replace(/<(.|\n)*?>/g, '')
  }

  const articleDescription = () => {
    if (equals(type, TYPE_FACT_TIP))
      return getCleanDescription(prop('facts_tips_copy', card), 100)
    if (and(not(isNil(videoDescription)), not(isEmpty(videoDescription))))
      return cleanLinkTag(videoDescription)
    if (and(not(isNil(photoDescription)), not(isEmpty(photoDescription))))
      return cleanLinkTag(photoDescription)
    if (
      any(equals(true))([
        isNil(videoDescription),
        isEmpty(videoDescription),
        isNil(photoDescription),
        isEmpty(photoDescription)
      ])
    )
      return ''
  }

  const handleClick = () => {
    if (isNil(blockTitle)) return
    sendEvent('block_interactions', {
      block_title: blockTitle,
      click_element: 'Link',
      element_title: title,
      path: pathname
    })
  }

  const params = {
    campus_resource: prop('campus_resource', card),
    card_domain: prop('domain_name', card),
    card_groups: prop('groups', card)
      ? map(prop('name'), prop('groups', card))
      : [],
    card_id: prop('id', card),
    card_name: title,
    card_type: convertCardTypeToString(prop('type', card))
  }

  const handleFavorite = () => {
    favorite({
      card_id: id,
      isFavorite: not(isFavorite)
    })
    if (not(isFavorite)) {
      sendEvent('card_detail_view_favorite', { ...params })
    } else {
      sendEvent('card_detail_view_unfavorite', { ...params })
    }
  }

  return (
    <Card>
      <CardActionArea
        LinkComponent={Link}
        onClick={handleClick}
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }}
        to={internalUrl(card)}
      >
        <Box
          sx={{
            backgroundImage: `url(${photo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            height: 180,
            position: 'relative',
            width: 1
          }}
        >
          {campusResource ? <ResourceMask /> : <ImageIcon type={type} />}
        </Box>
        <CardContent sx={{ pb: 0 }}>
          <Stack spacing={1}>
            <CardType
              campusResource={campusResource}
              type={type}
              videoDescription={videoDescription}
            />
            <Typography variant="h4">{title}</Typography>
            <Typography
              component="div"
              sx={{
                ...truncateLines(3),
                '& > p': {
                  margin: 0,
                  padding: 0
                }
              }}
              variant="body2"
            >
              {parse(articleDescription())}
            </Typography>
          </Stack>
        </CardContent>
        {/* Hide Favoriting on Home Page (TEMP UNITL NEW DASH) */}
        {equals(pathname, '/home') && (
          <Box
            sx={{
              height: 48,
              pb: 1,
              px: 1,
              width: 1
            }}
          />
        )}
      </CardActionArea>
      {not(equals(pathname, '/home')) && (
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            pb: 1,
            pt: 0,
            px: 1
          }}
        >
          <IconButton
            aria-label={`Bookmark ${isFavorite ? 'selected' : 'unselected'}`}
            onClick={handleFavorite}
          >
            <Icon fill={isFavorite}>bookmark</Icon>
          </IconButton>
        </CardActions>
      )}
    </Card>
  )
}

ContentCard.propTypes = {
  blockTitle: PropTypes.string,
  card: PropTypes.shape({}).isRequired
}

export default ContentCard
